import zukeeper from 'zukeeper';
import { create } from 'zustand';
import {
  TSFiltersState,
  TSOpportunitySeed,
} from '../queries/allProjectsPostNtp/types';
import { getDefaultFiltersWithOpportunityLimits } from '../queries/allProjectsPostNtp/utils';

interface FilterStore {
  filters: TSFiltersState;
  selectedSeeds: Record<string, boolean>;
  setFilters: (filters: TSFiltersState) => void;
  resetFilters: (seeds?: TSOpportunitySeed[]) => void;
  setSelectedSeeds: (seeds: Record<string, boolean>) => void;
}

const initialFilters: TSFiltersState = {
  selectedAddresses: { values: [], isActive: false },
  selectedEcmTypes: { values: [], isActive: false },
  selectedProjectStages: { values: [], isActive: false },
  selectedLabels: { values: [], isActive: false },
  costSavings: { values: [0, 10000000], isActive: false },
  co2Savings: { values: [0, 10000000], isActive: false },
  simplePaybackPeriod: { values: [0, 10000000], isActive: false },
  selectedTaskFilter: { value: '', isActive: false },
  bookmarkedOnly: false,
  selectedOnly: false,
  searchKey: '',
};

export const useFilterStore = create<FilterStore>(
  zukeeper((set) => ({
    filters: initialFilters,
    selectedSeeds: {},
    setFilters: (incomingValue) => set({ filters: incomingValue }),
    resetFilters: (seeds) =>
      set(() => ({
        filters: seeds
          ? getDefaultFiltersWithOpportunityLimits(seeds)
          : initialFilters,
        selectedSeeds: {},
      })),
    setSelectedSeeds: (seeds) => set({ selectedSeeds: seeds }),
  }))
);
export const useAllProjectStores = () => ({
  filtersStore: useFilterStore(),
});
