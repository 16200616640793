import _, { isEmpty, isNil, sortBy, toLower, toPairs } from 'lodash';

// Collection
export const sortByCaseInsensitive = <T>(arr, prop) =>
  sortBy<T>(arr, (i) => toLower(i[prop]));

export const updateById = <T>(
  previous: (T & { id: string })[],
  id: string,
  update: T
): T[] =>
  previous.map((item) => (item.id === id ? { ...item, ...update } : item));

export const rejectNilEmpty = <T>(arr: (T | undefined | null)[]) =>
  _(arr).reject(isNil).reject(isEmpty).value() as T[];

export const toOptions = <T = Record<string, string>>(
  collection: T[],
  valueKey: keyof T,
  labelKey: keyof T
): {
  value: string;
  label: string;
}[] =>
  collection.map((obj) => ({
    value: (obj[valueKey] as string) ?? '',
    label: (obj[labelKey] as string) ?? '',
  }));

// Object
export const omitNilEmpty = (obj: Record<string, any>) =>
  _(obj).omitBy(isNil).omitBy(isEmpty).value();

export const objToOptions = (obj: Record<string, any>) =>
  toPairs(obj).map(([k, v]) => ({
    value: k,
    label: v,
  }));

// Member
export function memberWithMax(members, key) {
  if (!members || members.length === 0) {
    return null;
  }
  return members.reduce((prev, current) => {
    return prev && prev[key] > current[key] ? prev : current;
  });
}
