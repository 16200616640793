/* eslint-disable @typescript-eslint/no-unused-vars */
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { UilBars, UilPlusCircle, UilTimes } from '@iconscout/react-unicons';
import InfoCircle from '@iconscout/react-unicons/icons/uil-info-circle';
import {
  Checkbox,
  Flex,
  Group,
  Button as MantineButton,
  Table,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { RefObject, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import Spinner from '../Spinner';
import { CustomFieldCreator } from './CustomFieldCreator';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../../ducks/customers';
import { useFetchMLOV } from '../../queries/allProjects';
import {
  TSOpportunitySeed,
  TSCustomOpportunityField,
} from '../../queries/allProjects/types';
import { ecmTypeToMlovMapping } from '../../queries/allProjects/utils';

export interface MLOVFieldType {
  title: string;
  type: string;
  fieldType: string;
  unit: string;
  helperText?: string;
  apiFieldName: string;
  sequenceNumber: number;
  isPrimary?: boolean;
  formula?: string;
  numberOfDecimalPlaces?: number;
}
export type SortableDataType = Omit<
  MLOVFieldType,
  'apiFieldName' | 'type' | 'sequenceNumber' | 'fieldType'
> & {
  id: string;
  isCustomField: boolean;
  newOrExistingCustomField?: 'new' | 'existing' | null;
  value: string;
  isEcmSpecificField?: boolean;
  isGenericField?: boolean;
  isSecondaryGenericField?: boolean;
};

interface SortableTableType {
  onDataChange: any;
  opportunitySeed?: TSOpportunitySeed;
  isEditingDisabled: boolean;
  selectedEcmType: string;
}

const tableHeaderStyles = { fontSize: '14px', fontWeight: '600' };

export function SortableTable({
  onDataChange,
  opportunitySeed,
  isEditingDisabled,
  selectedEcmType,
}: SortableTableType) {
  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );
  const { data: masterListOfValues, isFetching: isMasterListOfValuesLoading } =
    useFetchMLOV(currentCustomerId);

  const selectedType =
    ecmTypeToMlovMapping[
      selectedEcmType.replace(/\s+/g, '').replace(/-/g, '').toLowerCase()
    ];
  const isBatch = useMemo(
    () => opportunitySeed?.entityType == 'batch',
    [opportunitySeed?.entityType]
  );

  // Filter masterListOfValues based on the selectedType
  const mlovFieldsRawData = masterListOfValues?.filter(
    (item) => item.type === selectedType
  )[0]?.value;
  const units = masterListOfValues
    ?.filter((item) => item.type == 'UNIT')
    ?.map((item) => {
      return {
        value: item.value,
        label: item.value,
      };
    });

  const sortableData: SortableDataType[] = [];
  const customFields: TSCustomOpportunityField[] = useMemo(() => {
    return masterListOfValues
      ?.filter((item) => item.type === 'CUSTOM_FIELD_TITLE')
      ?.map((field) => {
        const parsedFieldData = field.metadata
          ? JSON.parse(field.metadata)
          : {};
        return {
          ...field,
          ...parsedFieldData,
        } as TSCustomOpportunityField;
      })
      ?.filter((field) => field.title);
  }, [masterListOfValues]);
  const [state, handlers] = useListState(sortableData);
  const opportunitySequenceDetails = opportunitySeed?.sequenceDetails ?? [];
  // isMatchingEcmType is used to reset ecmSpecific field values and remove custom fields upon ecmType change
  const isMatchingEcmType = selectedEcmType === opportunitySeed?.ecmType;
  const getFieldValue = (field: MLOVFieldType) => {
    // Case while editing an Opportunity
    if (opportunitySeed) {
      if (
        field.fieldType == 'GENERIC' ||
        field.fieldType == 'SECONDARY_GENERIC'
      ) {
        return opportunitySeed[field.apiFieldName];
      }

      if (field.fieldType == 'ECM_SPECIFIC' && isMatchingEcmType) {
        return opportunitySeed?.ecmTypeSpecificFields?.[field.apiFieldName];
      } else {
        return '0';
      }
    } else {
      // Case while creating a new Opportunity
      return '0';
    }
  };

  const getIsPrimaryField = (field) => {
    if (isMatchingEcmType && opportunitySequenceDetails.length > 0) {
      return opportunitySequenceDetails.find(
        (item) => item.id == field.apiFieldName
      )?.isPrimary;
    } else {
      return field.isPrimary;
    }
  };
  useEffect(() => {
    let newSortableData: Array<SortableDataType> = [];

    // Add ecmSpecific Fields to newSortableData
    let genericFieldsData: Array<SortableDataType> = [];
    let ecmSpecificFieldsData: Array<SortableDataType> = [];
    let secondaryGenericFields: Array<SortableDataType> = [];

    const parsedData = JSON.parse(
      mlovFieldsRawData ??
        masterListOfValues?.filter((item) => item.type === 'GLOBAL_FIELDS')[0]
          ?.value
    );
    genericFieldsData = parsedData
      ?.filter((field) => field.fieldType == 'GENERIC')
      ?.map((field: MLOVFieldType) => {
        return {
          id: field.apiFieldName,
          title: field.title,
          value: getFieldValue(field),
          unit: field.unit,
          isGenericField: true,
          isPrimary: getIsPrimaryField(field),
          sequenceNumber: field.sequenceNumber,
          helperText: field.helperText,
          formula: field.formula,
        };
      });
    ecmSpecificFieldsData = parsedData
      ?.filter((field) => field.fieldType == 'ECM_SPECIFIC')
      ?.map((field: MLOVFieldType) => {
        return {
          id: field.apiFieldName,
          title: field.title,
          value: getFieldValue(field),
          unit: field.unit,
          isEcmSpecificField: true,
          isPrimary: getIsPrimaryField(field),
          sequenceNumber: field.sequenceNumber,
          helperText: field.helperText,
          formula: field.formula,
        };
      });
    secondaryGenericFields = parsedData
      ?.filter((field) => field.fieldType == 'SECONDARY_GENERIC')
      ?.map((field: MLOVFieldType) => {
        return {
          id: field.apiFieldName,
          title: field.title,
          value: getFieldValue(field),
          unit: field.unit,
          isSecondaryGenericField: true,
          isPrimary: getIsPrimaryField(field),
          sequenceNumber: field.sequenceNumber,
          helperText: field.helperText,
          formula: field.formula,
        };
      });
    const batchFields = isBatch
      ? JSON.parse(
          masterListOfValues?.filter((item) => item.type === 'BATCH_FIELDS')[0]
            ?.value ?? '[]'
        )?.map((field: MLOVFieldType) => {
          return {
            id: field.apiFieldName,
            title: field.title,
            value: getFieldValue(field),
            unit: field.unit,
            isPrimary: getIsPrimaryField(field),
            sequenceNumber: field.sequenceNumber,
            helperText: field.helperText,
            formula: field.formula,
          };
        })
      : [];

    // Add the formatted fields to newSortableData
    if (!isBatch) {
      newSortableData = [
        ...genericFieldsData,
        ...secondaryGenericFields,
        ...ecmSpecificFieldsData,
      ];
    } else {
      newSortableData = [...batchFields];
    }

    // Add custom Fields to state
    if (opportunitySeed && isMatchingEcmType && opportunitySeed.customFields) {
      // check if customfields exist, then push to sortableData
      opportunitySeed.customFields.forEach((customField: any) => {
        // pull the complete metadata from the mlov defs
        const mlovCustomFieldEntry =
          customFields.find((field) => field.id == customField.id) ||
          ({} as any);
        newSortableData.push({
          id: customField.id,
          title: mlovCustomFieldEntry?.title,
          value: customField.value,
          unit: mlovCustomFieldEntry?.unit,
          helperText: mlovCustomFieldEntry?.helperText,
          isCustomField: true,
          newOrExistingCustomField: 'existing',
          isPrimary:
            opportunitySequenceDetails?.find(
              (item) => item.id === customField.id
            )?.isPrimary || false,
        });
      });
    }

    {
      /* Sorting the data as per sequence */
    }
    const sequenceMapping = {};
    // When opp sequence is not present, use default sequencing from ecmSpecificFields
    const sequenceArraySource =
      isMatchingEcmType && opportunitySequenceDetails.length > 0
        ? opportunitySequenceDetails
        : [
            ...genericFieldsData,
            ...ecmSpecificFieldsData,
            ...secondaryGenericFields,
          ];
    sequenceArraySource?.forEach((item) => {
      sequenceMapping[item.id] = item.sequenceNumber;
    });
    newSortableData.sort((a, b) => {
      const sequenceA = sequenceMapping[a.id];
      const sequenceB = sequenceMapping[b.id];
      return sequenceA - sequenceB;
    });
    {
      /* Sorting the data as per sequence ends */
    }

    // Add newSortableData to state
    handlers.setState(newSortableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mlovFieldsRawData, opportunitySeed, selectedEcmType]);

  const handleFieldRemove = (id) => {
    const updatedState = state?.filter((item) => item.id !== id);
    handlers.setState(updatedState);
  };

  const calculateFieldValue = (fieldId, updatedState) => {
    const field = updatedState.find((item) => item.id === fieldId);
    if (field && field.formula) {
      const { formula } = field;
      const calculatedValue = evaluateFormula(formula, updatedState);
      return calculatedValue;
    }
    return null;
  };

  const evaluateFormula = (formula, tempState) => {
    const variables = {};
    tempState.forEach((field) => {
      variables[field.id] = Number(field.value);
    });
    // Replace variable names in the formula with their corresponding values
    const replacedFormula = formula.replace(
      /(\b\w+\b)/g,
      (match) => variables[match] || 0
    );
    return eval(replacedFormula);
  };

  const handleSelectOrCreateCustomField = (
    item,
    field,
    newValue,
    selectedOption?
  ) => {
    if (!selectedOption.__isNew__) {
      const selectedField = customFields.find(
        (field) => field.title == newValue
      );
      return {
        ...item,
        ...selectedField,
        newOrExistingCustomField: 'existing',
        [field]: newValue,
      } as SortableDataType;
    } else {
      // selecting to add a new field - update to make sure it has a unique id
      return {
        ...item,
        newOrExistingCustomField: 'new',
        id: `customField-${uuidv4()}`,
        unit: '',
        helperText: '',
        [field]: newValue,
      } as SortableDataType;
    }
  };

  const handleFieldChange = (id, field, newValue, selectedOption?) => {
    const updatedState = state?.map((item) => {
      if (item.id === id) {
        if (item.isCustomField && field == 'title') {
          return handleSelectOrCreateCustomField(
            item,
            field,
            newValue,
            selectedOption
          );
        } else {
          return {
            ...item,
            [field]: newValue,
          };
        }
      } else {
        return item;
      }
    });

    // Recalculate and update the values of fields with formulas
    updatedState.forEach((item, index) => {
      if (field === 'value' && item.formula) {
        // Recalculate value using calculateFieldValue function
        const calculatedValue = calculateFieldValue(item.id, updatedState);

        // Update the state for the current item synchronously
        updatedState[index] = { ...item, value: calculatedValue };
      }
    });

    handlers.setState([...updatedState]);
  };

  const getUnusedCustomFields = () => {
    const usedIds = state?.map((item) => item.id);
    return customFields?.filter((field) => usedIds.indexOf(field.id) == -1);
  };

  useEffect(() => {
    onDataChange(state);
  }, [state, onDataChange]);

  const isAddingCustomField = state?.filter((item) => !item.id).length > 0;

  const items = state?.map((item, index) => (
    <Draggable
      key={`field_${item.id}`}
      index={index}
      draggableId={item.id || 'NEW_CUSTOM_FIELD'}
      isDragDisabled={isEditingDisabled}
    >
      {(provided, snapshot) => (
        <>
          <Table.Tr
            {...provided.draggableProps}
            ref={provided.innerRef}
            pb={'20%'}
            styles={{
              tr: {
                borderBottomWidth:
                  item.isCustomField && item.newOrExistingCustomField == 'new'
                    ? 0
                    : 1,
              },
            }}
          >
            <Table.Td style={{ width: '6%' }}>
              <Flex {...provided.dragHandleProps}>
                <UilBars />
              </Flex>
            </Table.Td>
            <Table.Td style={{ width: '40%' }}>
              {item.isCustomField && !item.id ? (
                <Flex gap={'8px'} align={'center'} justify={'space-between'}>
                  {/* only show the creatable select until they either choose a field, or add a new one */}
                  <CustomFieldCreator
                    allCustomFields={customFields}
                    unusedCustomFields={getUnusedCustomFields()}
                    onChange={(selectedOption: any) =>
                      handleFieldChange(
                        item.id,
                        'title',
                        selectedOption.value,
                        selectedOption
                      )
                    }
                    isDisabled={isEditingDisabled}
                  />
                </Flex>
              ) : (
                <Flex gap={'8px'} align={'center'} justify={'space-between'}>
                  <Text size='md' fw={'600'} c={'black'}>
                    {item.title}
                  </Text>
                  {item.helperText && (
                    <span>
                      <InfoCircle
                        color='#9D9D9F'
                        data-tooltip-id={item.id}
                        size={20}
                      />
                      {
                        <Tooltip
                          style={{ maxWidth: 300, zIndex: 3 }}
                          id={item.id}
                          place={'right'}
                        >
                          <Text>{item.helperText}</Text>
                        </Tooltip>
                      }
                    </span>
                  )}
                </Flex>
              )}
            </Table.Td>
            <Table.Td style={{ width: '30%' }}>
              {(!item.isCustomField || item.newOrExistingCustomField) && (
                <TextInput
                  type='number'
                  variant='default'
                  value={item.value}
                  onChange={(e) =>
                    handleFieldChange(item.id, 'value', e.target.value)
                  }
                  size='md'
                  disabled={isEditingDisabled || !!item.formula}
                  fw={'600'}
                  c={'black'}
                  placeholder='Value'
                />
              )}
            </Table.Td>
            <Table.Td style={{ width: '20%' }}>
              {item.isCustomField && item.newOrExistingCustomField == 'new' ? (
                <CreatableSelect
                  options={units}
                  name='unit'
                  placeholder={'Unit'}
                  onChange={(selectedOption: any) =>
                    handleFieldChange(item.id, 'unit', selectedOption.value)
                  }
                  defaultValue={
                    item.unit != ''
                      ? {
                          value: item.unit,
                          label: item.unit,
                        }
                      : undefined
                  }
                  isDisabled={isEditingDisabled}
                  menuPosition='fixed'
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      border: '1px solid #ced4da',
                      boxShadow: 'none',
                      height: '42px',
                      borderRadius: '4px',
                      ':hover': {
                        borderColor: '#162447',
                      },
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      padding: '0 0 0 4px',
                      fontSize: '14px',
                      fontWeight: '600',
                    }),
                    menu: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: '14px',
                      fontWeight: '600',
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  required
                />
              ) : (
                <Text size='md' fw={'600'} c={'black'}>
                  {item.unit}
                </Text>
              )}
            </Table.Td>
            <Table.Td style={{ width: '5%' }}>
              <Flex p={0} align={'center'} justify={'center'}>
                <Checkbox
                  radius='sm'
                  checked={item.isPrimary || false}
                  onChange={(e) =>
                    handleFieldChange(item.id, 'isPrimary', e.target.checked)
                  }
                  disabled={
                    isEditingDisabled ||
                    (numberOfSelectedPrimaryFields >= 6 && !item.isPrimary)
                  }
                />
              </Flex>
            </Table.Td>
            <Table.Td style={{ width: '5%' }}>
              {item.isCustomField && (
                <span
                  style={{ cursor: 'pointer', display: 'flex' }}
                  onClick={() => handleFieldRemove(item.id)}
                >
                  <UilTimes />
                </span>
              )}
            </Table.Td>
          </Table.Tr>
          {item.isCustomField &&
            item.newOrExistingCustomField === 'new' &&
            !snapshot.isDragging && (
              <Table.Tr>
                <Table.Td style={{ width: '6%' }}></Table.Td>
                <Table.Td colSpan={3}>
                  <Textarea
                    size='md'
                    fw={'600'}
                    c={'black'}
                    autosize
                    name='helperText'
                    placeholder={'Field Definition (optional)'}
                    disabled={isEditingDisabled}
                    value={item.helperText}
                    onChange={(e) =>
                      handleFieldChange(
                        item.id,
                        'helperText',
                        e.currentTarget.value
                      )
                    }
                  />
                </Table.Td>
              </Table.Tr>
            )}
        </>
      )}
    </Draggable>
  ));

  // ref for the dummy element to scroll to end of sortable table when adding custom fields
  const dummyElementRef: RefObject<HTMLTableRowElement> = useRef(null);

  const scrollToLastRow = () => {
    dummyElementRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const numberOfSelectedPrimaryFields = useMemo(
    () => state?.filter((item) => item.isPrimary).length,
    [state]
  );

  if (isMasterListOfValuesLoading)
    return (
      <Flex h={'100%'} direction={'column'} justify={'center'} align={'center'}>
        <Spinner />
      </Flex>
    );

  return (
    <>
      <Group justify='space-between' h={32} mb={'20px'}>
        <Text size={'16px'} fw={'600'}>
          Fields
        </Text>

        <MantineButton
          leftSection={<UilPlusCircle size={'16px'} />}
          variant='default'
          radius={'md'}
          size='compact-md'
          px={'12px'}
          onClick={() => {
            if (isAddingCustomField) {
              // already adding one; don't add two at the same time
              scrollToLastRow();
              return;
            }
            handlers.append({
              id: '',
              title: '',
              value: '0',
              unit: '',
              helperText: '',
              isCustomField: true,
              newOrExistingCustomField: null,
              isPrimary: false,
            });
            scrollToLastRow();
          }}
          disabled={isEditingDisabled}
        >
          Add field
        </MantineButton>
      </Group>

      <Flex align={'center'} justify={'flex-end'} mb={'12px'}>
        <Text
          c={'gray.7'}
        >{`Select up to 6 primary fields to show in the summary section (${numberOfSelectedPrimaryFields}/6)`}</Text>
      </Flex>
      <DragDropContext
        onDragEnd={({ destination, source }) => {
          handlers.reorder({
            from: source.index,
            to: destination?.index || 0,
          });
        }}
      >
        <Table>
          <Table.Thead>
            <Table.Tr
              py={'12px'}
              style={{
                borderSpacing: '1px',
                borderCollapse: 'separate',
                border: '0',
                boxShadow: '0 0 0 1px #e0e0e0',
                borderRadius: '8px',
              }}
            >
              <Table.Th />
              <Table.Th style={tableHeaderStyles}>Title</Table.Th>
              <Table.Th style={tableHeaderStyles}>Value</Table.Th>
              <Table.Th style={tableHeaderStyles}>Unit</Table.Th>
              <Table.Th style={tableHeaderStyles}>Primary</Table.Th>
              <Table.Th style={tableHeaderStyles}></Table.Th>
            </Table.Tr>
          </Table.Thead>
          {/* Dummy tbody to add space between thead and tbody */}
          <Table.Tbody>
            <Table.Tr>
              <Table.Td h={'8px'}></Table.Td>
            </Table.Tr>
          </Table.Tbody>
          {/* Dummy tbody ends */}
          <Droppable
            droppableId='dnd-list'
            direction='vertical'
            isDropDisabled={isEditingDisabled}
          >
            {(provided) => (
              // TODO: figure out a way to make tbody rounded corners
              <Table.Tbody
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  borderRadius: '8px',
                }}
              >
                {items}
                {provided.placeholder}
                {/* Dummy element for scrolling when new items are added to table */}
                <Table.Tr ref={dummyElementRef}>
                  <Table.Td h={'40px'}></Table.Td>
                </Table.Tr>
              </Table.Tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    </>
  );
}
