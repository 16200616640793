import axios from 'axios';
import _, { isEmpty, isNil } from 'lodash';
import { defaultHeaders, denaliApiBaseUrl } from '../../api';
import { handleAxiosError, queryStringify } from '../../api/utils';
import { TSCircuitsMetaResponse } from '../../ducks/circuit/circuitMeta';
import { MetadataParams } from './types';

export const fetchResourceMetadata = async (queryParams: MetadataParams) => {
  const { siteIds, customerId, resourceType, ...rest } = queryParams;
  const filteredQuery = _({ ...rest, site: siteIds })
    .omit('siteIds')
    .omitBy(isNil)
    .omitBy(isEmpty)
    .valueOf();

  const query = queryStringify(filteredQuery);
  const url = `${denaliApiBaseUrl()}/customers/${customerId}/metadata/${resourceType}?${decodeURIComponent(
    query
  )}`;

  return axios
    .get<TSCircuitsMetaResponse>(url, { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(handleAxiosError);
};
