import {
  UilAngleDoubleDown,
  UilAngleDoubleUp,
  UilFileAlt,
  UilPaperclip,
  UilPlusCircle,
  UilTimes,
} from '@iconscout/react-unicons';
import {
  Badge,
  Box,
  Divider,
  Flex,
  Group,
  Button as MantineButton,
  Grid as MantineGrid,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import { useScrollIntoView } from '@mantine/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import Button from '../../../../../components/Button';
import FileThumbnailPreview from '../../../../../components/FileThumbnailPreview';
import {
  CloseModalButtonStyled,
  DefaultModalStyled,
  ModalWrapperStyled,
} from '../../../../../components/GlobalModal/Components';
import Message from '../../../../../components/Message';
import ModalPopup, {
  ModalPopupTitle,
} from '../../../../../components/ModalPopup';
import Spinner from '../../../../../components/Spinner';
import { EMPTY_METRIC_STRING } from '../../../../../constants/strings';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../../../../../ducks/customers';
import {
  TSOpportunityDetailModal,
  actions as modalActions,
} from '../../../../../ducks/modal';
import { useGainsightTracking } from '../../../../../gainsight/allProjectsPostNtp';
import {
  useAssignLabels,
  useCreateBatchComment,
  useCreateCustomerTask,
  useCreateProjectComment,
  useDeleteBatchAttachment,
  useDeleteCustomerTask,
  useDeleteProjectAttachment,
  useEditProject,
  useFetchAllBatches,
  useFetchAllProjects,
  useFetchBatchAttachments,
  useFetchBatchComments,
  useFetchCustomerTasks,
  useFetchLabels,
  useFetchProjectAttachments,
  useFetchProjectComments,
  useUpdateBatchMetadata,
  useUpdateCustomerTask,
  useUpdateProjectLabels,
} from '../../../../../queries/allProjectsPostNtp';
import {
  TSAddLabelsRequestPayload,
  TSEditOpportunityLabelsPayload,
  TSOpportunityBase,
  TSOpportunityBatch,
  TSOpportunitySeed,
} from '../../../../../queries/allProjectsPostNtp/types';
import {
  getLabelOptionsByGroupForReactSelect,
  getParentBatchForSeed,
  getSeedsForBatch,
} from '../../../../../queries/allProjectsPostNtp/utils';
import {
  extractFileNameInfo,
  locationSearchToObject,
} from '../../../../../utils';
import ChildOpportunities from '../ChildOpportunities';
import CommentSection from '../CommentSection';
import DetailsModalHeader from '../DetailsModalHeader/DetailsModalHeader';
import Followers from '../Followers';
import ProjectTasksSection from '../ProjectTasksSection';
import {
  ErrorMessageWrapper,
  FilePreviewWrapper,
  FormInputWrapperStyled,
  InlineTextWrapper,
  ModalContentReStyled,
  OpportunityModalBodyStyled,
  PopupFormButtonWrapperStyled,
} from './styles';

const DetailsModal = ({
  opportunitySeedId,
  opportunityType,
}: TSOpportunityDetailModal) => {
  const isBatch = useMemo(() => opportunityType == 'batch', [opportunityType]);
  const batchId = isBatch ? opportunitySeedId : undefined;
  const { trackRemoveProjectLabel } = useGainsightTracking();
  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );

  const {
    data: tasks,
    isFetching: tasksLoading,
    isError: isTasksError,
  } = useFetchCustomerTasks(currentCustomerId);
  const { data: allProjects } = useFetchAllProjects(currentCustomerId);
  const { data: batches } = useFetchAllBatches(currentCustomerId);
  const { data: labels, isFetching: labelsLoading } =
    useFetchLabels(currentCustomerId);
  const {
    data: projectComments,
    isFetching: isProjectCommentsLoading,
    isError: isProjectCommentsError,
  } = useFetchProjectComments(
    !isBatch ? opportunitySeedId : undefined,
    currentCustomerId
  );
  const {
    data: projectAttachments,
    isFetching: isProjectAttachmentsLoading,
    isError: isProjectAttachmentsError,
  } = useFetchProjectAttachments(
    !isBatch ? opportunitySeedId : undefined,
    currentCustomerId
  );
  const {
    data: batchComments,
    isFetching: isBatchCommentsLoading,
    isError: isBatchCommentsError,
  } = useFetchBatchComments(batchId, currentCustomerId);
  const {
    data: batchAttachments,
    isFetching: isBatchAttachmentsLoading,
    isError: isBatchAttachmentsError,
  } = useFetchBatchAttachments(batchId, currentCustomerId);

  const {
    mutate: assignLabels,
    isPending: assignLabelsLoading,
    isError: assignLabelsError,
  } = useAssignLabels();

  const {
    mutate: updateProjectLabels,
    isPending: updateProjectLabelsLoading,
    isError: updateProjectLabelsError,
  } = useUpdateProjectLabels();

  const {
    mutate: updateBatchMetaData,
    isPending: updateBatchMetadataLoading,
    isError: updateBatchMetadataError,
  } = useUpdateBatchMetadata();

  const { isPending: editProjectLoading, isError: editProjectError } =
    useEditProject();
  const {
    mutate: deleteTask,
    isPending: isDeletetaskLoading,
    isError: isDeletetaskError,
  } = useDeleteCustomerTask();
  const { isPending: updateTaskLoading, isError: isUpdateTaskError } =
    useUpdateCustomerTask();
  const { isPending: createTaskLoading, isError: isCreateTaskError } =
    useCreateCustomerTask();
  const {
    mutate: deleteBatchAttachment,
    isPending: isDeletingBatchAttachment,
  } = useDeleteBatchAttachment();
  const {
    mutate: deleteProjectAttachment,
    isPending: isDeletingProjectAttachment,
    isError: isDeleteProjectAttachmentError,
  } = useDeleteProjectAttachment();

  const {
    mutate: createProjectComment,
    isPending: createCommentLoading,
    isError: createCommentError,
  } = useCreateProjectComment();
  const {
    mutate: createBatchComment,
    isPending: createBatchCommentLoading,
    isError: createBatchCommentError,
  } = useCreateBatchComment();

  const navigate = useNavigate();

  const getOpportunity = (): TSOpportunityBase => {
    const opportunity = !isBatch
      ? allProjects?.find((seed) => seed.id === opportunitySeedId)
      : batches?.find((batch) => batch.id === opportunitySeedId);
    return opportunity ?? ({} as TSOpportunityBase);
  };

  const opportunitySeed = getOpportunity();
  const opportunityAsBatch = opportunitySeed as TSOpportunityBatch;
  const opportunityAsSeed = opportunitySeed as TSOpportunitySeed;
  const parentBatch = !isBatch
    ? getParentBatchForSeed(opportunityAsSeed, batches || [])
    : null;
  const childProjects = isBatch
    ? getSeedsForBatch(opportunityAsBatch, allProjects || [])
    : null;

  const currentProjectTasks = tasks?.filter((task) => {
    if (isBatch) {
      return task.projectBatchId == opportunitySeedId;
    } else {
      return task.projectOpportunityId == opportunitySeedId;
    }
  });

  const dispatch = useDispatch();
  const [commentText, setCommentText] = useState('');
  const [showAddLabelSelector, setShowAddLabelSelector] = useState(false);
  const [showDeleteDocumentModal, setShowDeleteDocumentModal] = useState(false);
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false);

  const [taskSelectedforRemoval, setTaskSelectedforRemoval] = useState('');
  const [documentSelectedforDeletion, setDocumentSelectedforDeletion] =
    useState('');
  const [inputAddCommentValidationError, setInputAddCommentValidationError] =
    useState('');
  const [fileUploadValidationError, setFileUploadValidationError] =
    useState('');

  const [selectedLabels, setSelectedLabels] = useState<
    readonly { value: string; label: string }[]
  >([]);

  const closeModal = useCallback(() => {
    navigate(`${location?.pathname}`);
    dispatch(modalActions.hideModal());
  }, [dispatch, navigate]);

  const locationSearchObj = locationSearchToObject(location.search);

  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<
    HTMLDivElement,
    HTMLDivElement
  >();

  // separate method to update both the stored comment and the validation when the text changes
  const handleChangeCommentText = (strComment: string) => {
    setCommentText(strComment);
    setInputAddCommentValidationError('');
  };

  const handleFileAcceptedForUpload = () => {
    setFilesSelectedForUpload(acceptedFiles);
    setFileUploadValidationError('');
  };
  const handleFileRejectedForUpload = (files) => {
    files?.map((file) => {
      if (file.errors[0].code == 'file-too-large') {
        setFileUploadValidationError('File cannot be larger than 50 MB');
      } else {
        setFileUploadValidationError(file.errors[0].message);
      }
    });
  };

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable default zone click and keydown behavior
    noClick: true,
    noKeyboard: true,
    maxSize: 52428800,
    onDropRejected: handleFileRejectedForUpload,
    onDropAccepted: handleFileAcceptedForUpload,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'text/csv': ['.csv'],
      'application/msword': ['.doc', '.docx'],
      'application/pdf': ['.pdf'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
  });

  const [filesSelectedForUpload, setFilesSelectedForUpload] =
    useState(acceptedFiles);
  useEffect(() => {
    setFilesSelectedForUpload(acceptedFiles);
  }, [acceptedFiles]);

  const handleAttachFile = useCallback(() => {
    open();
  }, [open]);

  const handleDeleteDocument = () => {
    if (documentSelectedforDeletion != '') {
      isBatch
        ? deleteBatchAttachment({
            batchId: opportunitySeedId,
            attachmentId: documentSelectedforDeletion,
          })
        : deleteProjectAttachment({
            projectId: opportunitySeedId,
            attachmentId: documentSelectedforDeletion,
          });

      setDocumentSelectedforDeletion('');
      setShowDeleteDocumentModal(false);
    }
  };

  const handleAddComment = useCallback(() => {
    if (
      filesSelectedForUpload?.length == 0 &&
      (commentText == '' || !commentText)
    ) {
      setInputAddCommentValidationError('Please enter a proper comment');
      return;
    }
    const createCommentRequest = {
      opportunityId: opportunitySeedId,
      comment: commentText,
      acceptedFiles:
        filesSelectedForUpload?.length != 0
          ? filesSelectedForUpload
          : undefined,
    };
    if (isBatch) {
      createBatchComment(createCommentRequest);
    } else {
      createProjectComment(createCommentRequest);
    }
    setCommentText('');
    setFilesSelectedForUpload([]);
  }, [
    isBatch,
    commentText,
    dispatch,
    filesSelectedForUpload,
    opportunitySeedId,
  ]);

  const isAttachmentsLoading = () => {
    return isBatch
      ? isBatchAttachmentsLoading || isDeletingBatchAttachment
      : isProjectAttachmentsLoading || isDeletingProjectAttachment;
  };
  const isCommentsLoading = () => {
    return isBatch
      ? isBatchCommentsLoading || createBatchCommentLoading
      : isProjectCommentsLoading || createCommentLoading;
  };
  const isLabelsLoading = () => {
    return isBatch
      ? labelsLoading || assignLabelsLoading || updateBatchMetadataLoading
      : labelsLoading || assignLabelsLoading || updateProjectLabelsLoading;
  };
  const isTasksLoading = () => {
    return (
      tasksLoading ||
      isDeletetaskLoading ||
      updateTaskLoading ||
      createTaskLoading
    );
  };

  const handleRemoveTask = () => {
    if (taskSelectedforRemoval != '') {
      deleteTask({
        taskId: taskSelectedforRemoval,
      });
      setShowDeleteTaskModal(false);
      setTaskSelectedforRemoval('');
    }
  };

  const dynamicAttachments = isBatch
    ? batchAttachments || []
    : projectAttachments || [];
  const dynamicComments = isBatch ? batchComments || [] : projectComments || [];

  const opportunityLabels = useMemo(
    () =>
      labels?.filter((label) => opportunitySeed.labelIds.includes(label.id)),
    [labels, opportunitySeed.labelIds]
  );

  const [labelsToShow, setLabelsToShow] = useState(opportunityLabels);
  useEffect(() => {
    setLabelsToShow(opportunityLabels);
  }, [opportunityLabels]);

  const [documentsToShow, setDocumentsToShow] = useState(
    dynamicAttachments?.slice(0, 4)
  );
  useEffect(() => {
    setDocumentsToShow(dynamicAttachments?.slice(0, 4));
  }, [dynamicAttachments]);
  useEffect(() => {
    if (locationSearchObj.shouldFocusToComments) {
      scrollIntoView();
    }
  }, [locationSearchObj.shouldFocusToComments, scrollIntoView]);

  const handeLabelClose = (labelId) => {
    if (isBatch) {
      const updateLabelRequestPayload = {
        batchId: opportunitySeedId,
        title: opportunitySeed.title,
        description: opportunitySeed.description,
        labelIds: opportunitySeed.labelIds?.filter((label) => label != labelId),
        annualAvoidedCo2e: opportunitySeed.annualAvoidedCo2e,
        annualNetCostSavings: opportunitySeed.annualNetCostSavings,
        simplePaybackPeriod: opportunitySeed.simplePaybackPeriod,
        annualAvoidedEnergyCost: opportunitySeed.annualAvoidedEnergyCost,
        annualAvoidedMaintenanceCost:
          opportunitySeed.annualAvoidedMaintenanceCost,
        annualAvoidedOtherCost: opportunitySeed.annualAvoidedOtherCost,
        annualAvoidedTotalCost: opportunitySeed.annualAvoidedTotalCost,
        customFields: opportunitySeed.customFields,
        sequenceDetails: opportunitySeed.sequenceDetails,
      };
      updateBatchMetaData(updateLabelRequestPayload);
      trackRemoveProjectLabel('BATCH', opportunitySeedId, labelId);
    } else {
      const updateLabelRequestPayload = {
        opportunityId: opportunitySeedId,
        labelIds: opportunityAsSeed.labelIds?.filter(
          (label) => label != labelId
        ),
        removedLabelId: labelId,
      } as TSEditOpportunityLabelsPayload;
      updateProjectLabels(updateLabelRequestPayload);
      trackRemoveProjectLabel('PROJECT', opportunitySeedId, labelId);
    }
  };

  const labelsForSelector = useMemo(
    () => getLabelOptionsByGroupForReactSelect(labels || []),
    [labels]
  );
  const handleAddLabels = () => {
    const formattedLabels = selectedLabels?.map((selectedLabel) => {
      const foundLabel = labels.find(
        (label) => label.text === selectedLabel.label
      );

      if (foundLabel) {
        return {
          id: foundLabel.id,
          text: foundLabel.text,
        };
      } else {
        return { text: selectedLabel.label };
      }
    });

    const addLabelsRequestPayload: TSAddLabelsRequestPayload = {
      customerId: currentCustomerId,
      labels: formattedLabels,
      opportunityIds: isBatch ? undefined : [opportunitySeedId],
      batchIds: isBatch ? [opportunitySeedId] : undefined,
    };

    assignLabels(addLabelsRequestPayload);
    setSelectedLabels([]);
    setShowAddLabelSelector(false);
  };

  if (!opportunitySeed?.displayId) {
    closeModal();
    return null;
  }
  return (
    <DefaultModalStyled>
      <ModalWrapperStyled>
        <CloseModalButtonStyled onClick={closeModal}>
          <span />
          <span />
          <span />
        </CloseModalButtonStyled>
        <OpportunityModalBodyStyled>
          {showDeleteDocumentModal && (
            <ModalPopup width='45%'>
              <ModalPopupTitle>Delete Document?</ModalPopupTitle>
              <InlineTextWrapper>
                After deleting, you won’t be able to see this document.
              </InlineTextWrapper>
              <FormInputWrapperStyled>
                <PopupFormButtonWrapperStyled>
                  <Button
                    onClick={() => {
                      setShowDeleteDocumentModal(false);
                      setDocumentSelectedforDeletion('');
                    }}
                    borderRadius='small'
                  >
                    {'Cancel'}
                  </Button>
                  <Button
                    onClick={handleDeleteDocument}
                    borderRadius='small'
                    buttonType='secondary'
                  >
                    {'Remove'}
                  </Button>
                </PopupFormButtonWrapperStyled>
              </FormInputWrapperStyled>
            </ModalPopup>
          )}
          {showDeleteTaskModal && (
            <ModalPopup width='45%'>
              <ModalPopupTitle>
                {opportunityAsBatch.locked
                  ? // eslint-disable-next-line max-len
                    'This project is locked from editing. Please contact your Redaptive representative if you have questions.'
                  : 'Are you sure you want remove this task? '}
              </ModalPopupTitle>
              {!opportunityAsBatch.locked && (
                <InlineTextWrapper>
                  {`Once you delete the task you won't be able to see this task again.`}
                </InlineTextWrapper>
              )}
              <FormInputWrapperStyled>
                <PopupFormButtonWrapperStyled>
                  <Button
                    onClick={() => {
                      setTaskSelectedforRemoval('');
                      setShowDeleteTaskModal(false);
                    }}
                    borderRadius='small'
                  >
                    {opportunityAsBatch.locked ? 'Close' : 'Cancel'}
                  </Button>
                  {!opportunityAsBatch.locked && (
                    <Button
                      onClick={handleRemoveTask}
                      borderRadius='small'
                      buttonType='secondary'
                    >
                      {'Remove'}
                    </Button>
                  )}
                </PopupFormButtonWrapperStyled>
              </FormInputWrapperStyled>
            </ModalPopup>
          )}
          {!editProjectLoading && (
            <ModalContentReStyled ref={scrollableRef}>
              {/* Opportunity Details View Header Starts */}
              <Box px={'24px'}>
                <DetailsModalHeader
                  headerData={{
                    opportunitySeed: opportunityAsSeed,
                    isBatch,
                    parentBatch,
                  }}
                />
              </Box>
              {/* Opportunity Details View Header Ends */}

              <Divider
                mt='xl'
                bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
              />
              <Box px={'24px'}>
                <Stack my={'xl'} gap={'lg'}>
                  <Text c={'black'} fw={'700'}>
                    Description
                  </Text>
                  <Text style={{ whiteSpace: 'pre-wrap' }}>
                    {opportunitySeed.description != ''
                      ? opportunitySeed.description
                      : 'No description added'}
                  </Text>
                </Stack>
              </Box>

              <Divider
                bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
              />

              {/* Begin the Labels section */}
              <Box px={'24px'}>
                <Stack my={'xl'} gap={'lg'}>
                  <Group justify='space-between' h={30}>
                    <Text c={'black'} fw={'700'}>
                      Labels
                    </Text>
                    {!showAddLabelSelector && (
                      <MantineButton
                        leftSection={<UilPlusCircle size={16} />}
                        variant='default'
                        radius={'md'}
                        size='compact-md'
                        px={'12px'}
                        onClick={() => setShowAddLabelSelector(true)}
                      >
                        Add label
                      </MantineButton>
                    )}
                  </Group>

                  {showAddLabelSelector && (
                    <Flex
                      align={'center'}
                      gap={'xs'}
                      bg={'white'}
                      style={{
                        borderRadius: '8px',
                        border: '1px solid #e0e0e0',
                      }}
                      p={'xs'}
                    >
                      <CreatableSelect
                        isMulti
                        options={labelsForSelector}
                        closeMenuOnSelect={false}
                        placeholder={
                          !labelsLoading
                            ? 'Type to search or create a new label'
                            : 'Labels are still loading...'
                        }
                        onChange={(values) => setSelectedLabels(values)}
                        value={selectedLabels}
                        styles={{
                          container: (baseStyles) => ({
                            ...baseStyles,
                            flexBasis: '100%',
                            cursor: 'pointer',
                          }),
                          control: (baseStyles) => ({
                            ...baseStyles,
                            border: 0,
                            boxShadow: 'none',
                            minHeight: '36px',
                          }),
                        }}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />

                      <Flex align={'center'} gap={'sm'}>
                        <MantineButton
                          onClick={() => {
                            setShowAddLabelSelector(false);
                            setSelectedLabels([]);
                          }}
                          variant='transparent'
                          radius={'md'}
                          size={'compact-md'}
                          c={'black'}
                          fw={'400'}
                        >
                          {' '}
                          Cancel
                        </MantineButton>
                        <MantineButton
                          onClick={handleAddLabels}
                          disabled={selectedLabels?.length <= 0}
                          variant='filled'
                          radius={'md'}
                          size={'md'}
                        >
                          Add Labels
                        </MantineButton>
                      </Flex>
                    </Flex>
                  )}
                  {!isLabelsLoading() && opportunityLabels?.length != 0 && (
                    <>
                      <Flex gap={'md'} wrap={'wrap'}>
                        {labelsToShow?.map((label) => (
                          <Badge
                            key={label.id}
                            color={`#${label.color}`}
                            p={'8px 12px'}
                            fw={'500'}
                            radius={'8px'}
                            h={'32px'}
                            rightSection={
                              <Flex
                                align={'center'}
                                bg={'rgb(255,255,255,0.8)'}
                                style={{
                                  borderRadius: '100%',
                                  cursor: 'pointer',
                                }}
                                onClick={() => handeLabelClose(label.id)}
                                ml={'14px'}
                              >
                                <UilTimes size={16} color={'#6C6D6E'} />
                              </Flex>
                            }
                          >
                            <Text
                              tt={'none'}
                              size={'md'}
                              fw={'500'}
                              c={'black'}
                            >
                              {label.text}
                            </Text>
                          </Badge>
                        ))}
                      </Flex>
                      {opportunityLabels?.length > 3 && (
                        <Group
                          gap={'0px'}
                          align='center'
                          style={{ cursor: 'pointer' }}
                          w={'max-content'}
                          onClick={() =>
                            setLabelsToShow(
                              labelsToShow?.length > 3
                                ? opportunityLabels?.slice(0, 3)
                                : opportunityLabels
                            )
                          }
                        >
                          <Text c={'blue.6'}>
                            {labelsToShow?.length > 3
                              ? `Show fewer labels`
                              : `Show all ${opportunityLabels?.length} labels`}
                          </Text>
                          {labelsToShow?.length > 3 ? (
                            <UilAngleDoubleUp color='#43558E' />
                          ) : (
                            <UilAngleDoubleDown color='#43558E' />
                          )}
                        </Group>
                      )}
                    </>
                  )}
                  {!isLabelsLoading() && opportunityLabels?.length == 0 && (
                    <Text c={'gray.6'}>No labels added</Text>
                  )}

                  {isLabelsLoading() && <Spinner centered />}
                </Stack>
              </Box>
              {/* Labels Section Ends */}

              <Divider
                bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
              />

              {/* Begin the Documents section */}
              <Box px={'24px'}>
                <Stack my={'xl'} gap={'lg'}>
                  <Text c={'black'} fw={'700'}>
                    Documents
                  </Text>

                  {isAttachmentsLoading() && <Spinner centered />}

                  {isProjectAttachmentsError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>
                        {isProjectAttachmentsError}
                      </Message>
                    </ErrorMessageWrapper>
                  )}
                  {assignLabelsError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{'assignLabelsError'}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {isBatchAttachmentsError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>
                        {'isBatchAttachmentsError'}
                      </Message>
                    </ErrorMessageWrapper>
                  )}
                  {isDeleteProjectAttachmentError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>
                        {'deleteProjectAttachmentError'}
                      </Message>
                    </ErrorMessageWrapper>
                  )}
                  {editProjectError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{'editProjectError'}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {updateBatchMetadataError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>
                        {'updateBatchMetadataError'}
                      </Message>
                    </ErrorMessageWrapper>
                  )}
                  {updateProjectLabelsError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>
                        {'updateProjectLabelsError'}
                      </Message>
                    </ErrorMessageWrapper>
                  )}
                  {fileUploadValidationError &&
                    fileUploadValidationError != '' && (
                      <ErrorMessageWrapper>
                        <Message type='error'>
                          {fileUploadValidationError}
                        </Message>
                      </ErrorMessageWrapper>
                    )}
                  {isProjectCommentsError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{'isProjectCommentsError'}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {isBatchCommentsError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{'isBatchCommentsError'}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {createCommentError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{'createCommentError'}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {createBatchCommentError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>
                        {'createBatchCommentError'}
                      </Message>
                    </ErrorMessageWrapper>
                  )}
                  {isTasksError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{'Failed to fetch tasks'}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {isCreateTaskError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{'isCreateTaskError'}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {isUpdateTaskError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{'isUpdateTaskError'}</Message>
                    </ErrorMessageWrapper>
                  )}
                  {isDeletetaskError && (
                    <ErrorMessageWrapper>
                      <Message type='error'>{'deleteTaskError'}</Message>
                    </ErrorMessageWrapper>
                  )}

                  {dynamicAttachments?.length != 0 &&
                    !isAttachmentsLoading() && (
                      <>
                        <MantineGrid columns={2} gutter={'lg'}>
                          {documentsToShow?.map((attachment) => (
                            <MantineGrid.Col key={attachment.id} span={1}>
                              <FileThumbnailPreview
                                href={attachment.attachmentUrl}
                                onClose={() => {
                                  setDocumentSelectedforDeletion(attachment.id);
                                  setShowDeleteDocumentModal(true);
                                }}
                                title={decodeURIComponent(
                                  extractFileNameInfo(attachment.fileName)
                                    ?.name || EMPTY_METRIC_STRING
                                )}
                                metaText={[
                                  extractFileNameInfo(attachment.fileName)
                                    ?.extension || EMPTY_METRIC_STRING,
                                ]}
                                fileIcon={
                                  <UilFileAlt size='24' color={'#485DA0'} />
                                }
                              />
                            </MantineGrid.Col>
                          ))}
                        </MantineGrid>
                        {dynamicAttachments?.length > 4 && (
                          <Group
                            gap={'0px'}
                            align='center'
                            onClick={() =>
                              setDocumentsToShow(
                                documentsToShow?.length > 4
                                  ? dynamicAttachments?.slice(0, 4)
                                  : dynamicAttachments
                              )
                            }
                            style={{ cursor: 'pointer' }}
                            w={'max-content'}
                          >
                            <Text c={'blue.6'}>
                              {documentsToShow?.length > 4
                                ? `Show fewer documents`
                                : `Show all ${dynamicAttachments?.length} documents`}
                            </Text>
                            {documentsToShow?.length > 4 ? (
                              <UilAngleDoubleUp color='#43558E' />
                            ) : (
                              <UilAngleDoubleDown color='#43558E' />
                            )}
                          </Group>
                        )}
                      </>
                    )}
                  {!isAttachmentsLoading() &&
                    dynamicAttachments?.length == 0 && (
                      <Text c={'gray.6'}>No attached Documents</Text>
                    )}
                </Stack>
              </Box>
              {/* Documents Section Ends */}

              <Divider
                bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
              />

              {/* Begin the Tasks section */}
              <Box pr={'24px'}>
                <ProjectTasksSection
                  isTasksLoading={isTasksLoading()}
                  projectTasks={currentProjectTasks || []}
                  projectId={opportunitySeedId}
                  isBatch={isBatch}
                  currentCustomerId={currentCustomerId}
                  setShowDeleteTaskModal={setShowDeleteTaskModal}
                  setTaskSelectedforRemoval={setTaskSelectedforRemoval}
                />
              </Box>
              {/* Tasks Section Ends */}

              <Divider
                bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
              />

              {/* Begin the comments section */}
              <Box px={'24px'} ref={targetRef}>
                <CommentSection
                  comments={dynamicComments}
                  attachments={dynamicAttachments}
                  setDocumentSelectedforDeletion={
                    setDocumentSelectedforDeletion
                  }
                  setShowDeleteDocumentModal={setShowDeleteDocumentModal}
                  isCommentsLoading={isCommentsLoading()}
                  isAttachmentsLoading={isAttachmentsLoading()}
                />
              </Box>
              {/* Comments Section Ends */}

              <Divider
                bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
              />

              {/* Begin the Followers section */}
              <Box px={'24px'}>
                <Followers
                  isBatch={isBatch}
                  opportunitySeedId={opportunitySeedId}
                  opportunityAsSeed={opportunityAsSeed}
                  opportunityAsBatch={opportunityAsBatch}
                />
              </Box>

              {/* Comments Section Ends */}
              {isBatch && (
                <Divider
                  bg={'linear-gradient(0deg, #E0E0E0 0%, #E0E0E0 100%), #FFF'}
                />
              )}

              {/* Child Opportunities section */}
              {isBatch && childProjects && childProjects?.length > 0 && (
                <Box px={'24px'}>
                  <ChildOpportunities childProjects={childProjects} />
                </Box>
              )}
            </ModalContentReStyled>
          )}
          {/* Footer Section Starts */}
          <Stack
            p={'16px 24px'}
            style={{ boxShadow: '0px -1px 14px 0px rgba(0, 0, 0, 0.12)' }}
          >
            <Group>
              {filesSelectedForUpload?.length != 0 && (
                <FilePreviewWrapper>
                  {filesSelectedForUpload?.map((file) => (
                    <FileThumbnailPreview
                      key={`${file.name}-${file.size}`}
                      title={decodeURIComponent(file.name)}
                      variant='micro'
                    />
                  ))}
                </FilePreviewWrapper>
              )}
            </Group>

            <Flex
              align={'center'}
              gap={'lg'}
              bg={'white'}
              style={{ borderRadius: '8px', border: '1px solid #e0e0e0' }}
              p={'xs'}
              {...getRootProps({ className: 'dropzone' })}
            >
              <Textarea
                onChange={(e) =>
                  handleChangeCommentText(e.currentTarget.value || '')
                }
                p={'0 10px'}
                autosize
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={!!locationSearchObj.shouldFocusToComments}
                w={'100%'}
                size='md'
                variant='unstyled'
                rightSectionWidth={'140px'}
                rightSection={
                  <Flex gap={'lg'}>
                    <Group
                      onClick={handleAttachFile}
                      style={{ cursor: 'pointer' }}
                      c={'black'}
                    >
                      <input {...getInputProps()} />
                      <UilPaperclip size={20} />
                    </Group>
                    <MantineButton
                      onClick={handleAddComment}
                      variant='primary'
                      radius={'md'}
                      size='compact-md'
                    >
                      Add Comment
                    </MantineButton>
                  </Flex>
                }
                value={commentText}
                placeholder='Add a comment or ask a question...'
                error={inputAddCommentValidationError}
                withErrorStyles
                inputWrapperOrder={['label', 'description', 'error', 'input']}
              />
            </Flex>
          </Stack>
        </OpportunityModalBodyStyled>
      </ModalWrapperStyled>
    </DefaultModalStyled>
  );
};

export default DetailsModal;
