import {
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core';

interface CustomInputProps extends MantineTextInputProps {
  gainsightTagId?: string;
}

const TextInput = ({
  size = 'md',
  radius = 'sm',
  gainsightTagId,
  ...rest
}: CustomInputProps) => {
  return (
    <MantineTextInput
      data-gainsight-id={gainsightTagId}
      size={size}
      radius={radius}
      {...rest}
    />
  );
};

export default TextInput;
