import { TSModalProps } from '../components/ConfirmModal';
import { TSAddLabelsPopupProps } from '../components/OpportunityFeed/AddLabelsPopup';
import { TSCreateBatchPopupProps } from '../components/OpportunityFeed/CreateBatchPopup';
import {
  TSOpportunityEntityType,
  TSOpportunitySeed,
} from '../queries/allProjects/types';

export interface TSCreateModalParams {
  modalType?: string;
  modalProps?: any;
}

export interface TSModalState {
  modalType?: string;
  modalProps?: any;
}

interface TSState {
  entities: {
    modal: TSModalState;
  };
}

interface TSShowModal extends TSModalState {
  type: 'SHOW_MODAL';
}

export const initialState = {
  modalType: undefined,
  modalProps: undefined,
};

export const modalTypes = {
  MODAL_QUERY_BUILDER: 'MODAL_QUERY_BUILDER',
  MODAL_OPPORTUNITY_DETAIL: 'MODAL_OPPORTUNITY_DETAIL',
  MODAL_ALLPROJECTS_POST_NTP_DETAIL: 'MODAL_ALLPROJECTS_POST_NTP_DETAIL',
  MODAL_OPPORTUNITY_FILTERS: 'MODAL_OPPORTUNITY_FILTERS',
  MODAL_OPPORTUNITY_CREATE_BATCH: 'MODAL_OPPORTUNITY_CREATE_BATCH',
  MODAL_OPPORTUNITY_ADD_LABELS: 'MODAL_OPPORTUNITY_ADD_LABELS',
  MODAL_CONFIRM_NAVIGATION: 'MODAL_CONFIRM_NAVIGATION',
  MODAL_OPPORTUNITY_CREATE_OR_EDIT_OPPORTUNITY:
    'MODAL_OPPORTUNITY_CREATE_OR_EDIT_OPPORTUNITY',
  MODAL_SAVE_FILTER: 'MODAL_SAVE_FILTER',
  MODAL_DELETE_SAVED_FILTER: 'MODAL_DELETE_SAVED_FILTER',
  OPT_ENERGY_STAR: 'OPT_ENERGY_STAR',
  ENABLE_SITES_ENERGY_STAR: 'ENABLE_SITES_ENERGY_STAR',
  ONBOARDING_ENERGY_STAR: 'ONBOARDING_ENERGY_STAR',
};

export const types = {
  HIDE_MODAL: 'HIDE_MODAL',
  SHOW_MODAL: 'SHOW_MODAL',
};

function createModalAction({
  modalType = '',
  modalProps = {},
}: TSCreateModalParams) {
  if (!modalTypes[modalType]) {
    throw new Error(`Unknown modal type: ${modalType}`);
  }

  return {
    type: types.SHOW_MODAL,
    modalType,
    modalProps,
  };
}

export interface TSSiteConfigModal {
  siteId: string;
  onlyOpHours?: boolean;
}

export interface TSOpportunityDetailModal {
  opportunitySeedId: string;
  opportunityType: TSOpportunityEntityType;
}

export interface TSOpportunityCreateOrEditOpportunityModal {
  opportunitySeed?: TSOpportunitySeed;
  isEditMode?: boolean;
}

export interface TSFilterDrawerModal {
  sitesId: string[];
  isBasicConsumption: boolean;
}

const showQueryBuilderModal = (modalProps: TSFilterDrawerModal) =>
  createModalAction({
    modalType: modalTypes.MODAL_QUERY_BUILDER,
    modalProps,
  });

const showOpportunityDetailModal = (modalProps: TSOpportunityDetailModal) =>
  createModalAction({
    modalType: modalTypes.MODAL_OPPORTUNITY_DETAIL,
    modalProps,
  });
const showAllProjectsPostNtpDetailModal = (
  modalProps: TSOpportunityDetailModal
) =>
  createModalAction({
    modalType: modalTypes.MODAL_ALLPROJECTS_POST_NTP_DETAIL,
    modalProps,
  });

const showOpportunityCreateOrEditOpportunityModal = (
  modalProps?: TSOpportunityCreateOrEditOpportunityModal
) =>
  createModalAction({
    modalType: modalTypes.MODAL_OPPORTUNITY_CREATE_OR_EDIT_OPPORTUNITY,
    modalProps,
  });

const showOpportunityFiltersModal = () =>
  createModalAction({
    modalType: modalTypes.MODAL_OPPORTUNITY_FILTERS,
  });

const showOpportunityCreateBatchModal = (modalProps: TSCreateBatchPopupProps) =>
  createModalAction({
    modalType: modalTypes.MODAL_OPPORTUNITY_CREATE_BATCH,
    modalProps,
  });

const showOpportunityAddLabelsModal = (modalProps: TSAddLabelsPopupProps) =>
  createModalAction({
    modalType: modalTypes.MODAL_OPPORTUNITY_ADD_LABELS,
    modalProps,
  });

const showConfirmNavigationModal = (modalProps: TSModalProps) =>
  createModalAction({
    modalType: modalTypes.MODAL_CONFIRM_NAVIGATION,
    modalProps,
  });

const showOptEnergyStarModal = () =>
  createModalAction({
    modalType: modalTypes.OPT_ENERGY_STAR,
  });

const showEnableSitesEnergyStarModal = () =>
  createModalAction({
    modalType: modalTypes.ENABLE_SITES_ENERGY_STAR,
  });

const showOnboardingEnergyStarModal = () =>
  createModalAction({
    modalType: modalTypes.ONBOARDING_ENERGY_STAR,
  });

const hideModal = () => ({ type: types.HIDE_MODAL });

export const actions = {
  hideModal,
  showQueryBuilderModal,
  showOpportunityDetailModal,
  showAllProjectsPostNtpDetailModal,
  showOpportunityFiltersModal,
  showOpportunityCreateBatchModal,
  showOpportunityAddLabelsModal,
  showConfirmNavigationModal,
  showOpportunityCreateOrEditOpportunityModal,
  showOptEnergyStarModal,
  showEnableSitesEnergyStarModal,
  showOnboardingEnergyStarModal,
};

export default (state: TSModalState = initialState, action: TSShowModal) => {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        modalType: action.modalType || '',
        modalProps: action.modalProps,
      };
    case types.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export const selectModalEntity = (state: TSState): TSModalState =>
  state.entities.modal;
