import { UilPlusCircle, UilSave } from '@iconscout/react-unicons';
import {
  Flex,
  Group,
  Button as MantineButton,
  Select,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import * as yup from 'yup';
import { getUsername } from '../../api/utils';
import {
  actions as authActions,
  selectUserRolesAndPermissions,
} from '../../ducks/auth';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../../ducks/customers';
import {
  TSOpportunityCreateOrEditOpportunityModal,
  actions as modalActions,
} from '../../ducks/modal';
import {
  CloseModalButtonStyled,
  DefaultModalStyled,
  ModalContentStyled,
  ModalFooterStyled,
  ModalWrapperStyled,
} from '../GlobalModal/Components';
import TextInput from '../mantine/TextInput';
import { SortableDataType, SortableTable } from './SortableTable';
import {
  useCreateMLOVValue,
  useCreateProject,
  useEditProject,
  useFetchMLOV,
  useUpdateBatchMetadata,
} from '../../queries/allProjects';
import {
  TSEditBatchMetadataPayload,
  TSCreateOpportunityRequestPayload,
  TSProjectStage,
} from '../../queries/allProjects/types';
import { getProjectStageValuesForSelect } from '../../queries/allProjects/utils';
import { OpportunityModalBodyStyled } from '../OpportunityDetailModal/styles';
import { useGainsightTracking } from '../../gainsight/allProjects';

const CreateOrEditOpportunity = ({
  opportunitySeed,
  isEditMode,
}: TSOpportunityCreateOrEditOpportunityModal) => {
  const { trackEditProjectTitle } = useGainsightTracking();
  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );
  const { data: masterListOfValues } = useFetchMLOV(currentCustomerId);
  const { mutate: createValueInMLOV } = useCreateMLOVValue();
  const { mutate: createOpportunity, isPending: createProjectPending } =
    useCreateProject();
  const {
    mutate: updateBatchMetaData,
    isPending: isupdateBatchMetadataPending,
  } = useUpdateBatchMetadata();
  const { mutate: editProject, isPending: editProjectPending } =
    useEditProject();

  const validationSchema = yup.object({
    // TODO: add validation schema to all fields
  });
  const isBatch = useMemo(
    () => opportunitySeed?.entityType == 'batch',
    [opportunitySeed?.entityType]
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const username = getUsername();
  const [sortableData, setSortableData] = useState<Array<SortableDataType>>([]);

  const closeModal = useCallback(() => {
    navigate(`${location?.pathname}`);
    dispatch(modalActions.hideModal());
  }, [dispatch, navigate]);

  const userRolesAndPermissions = useSelector(selectUserRolesAndPermissions);

  const projectStageValues = getProjectStageValuesForSelect();

  const ecmTypes = masterListOfValues
    ?.filter((item) => item.type == 'ECM_TYPE')
    ?.map((item) => {
      return {
        value: item.value,
        label: item.value,
      };
    });
  const denaliAdmins = ['denali-admin'];
  const denaliUsers = ['denali-user'];
  const redaptiveRoles = [
    'account-managers',
    'admin',
    'gc-users',
    'operations-support',
    'billing',
    'billing-admin',
    'billing-invoice-approver',
    'billing-performance-reviewer',
    'hvac-assets-uploader',
    'lgt-as-built-uploader',
    'proposal-operations',
    'energy-star-admin',
  ];

  const formik = useFormik({
    initialValues: {
      title: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalcode: '',
      country: '',
      description: '',
      ecmType: { label: '', value: '' },
      projectStage: 'NEW',
      customerId: '',
      sortableData: [] as Array<SortableDataType>,
    },
    validationSchema,
    onSubmit: (values) => {
      const {
        title,
        description,
        address1,
        address2,
        city,
        state,
        country,
        ecmType,
        projectStage,
        postalcode,
        sortableData,
      } = values;

      const customSortableData: SortableDataType[] = sortableData?.filter(
        (item: SortableDataType) => item.isCustomField
      );

      if (isBatch) {
        const updateBatchMetadataPayload: TSEditBatchMetadataPayload = {
          batchId: opportunitySeed?.id || '',
          title,
          description,
          labelIds: opportunitySeed?.labelIds,
          annualAvoidedCo2e:
            sortableData.find((item) => item.id === 'annualAvoidedCo2e')
              ?.value ?? '',
          annualNetCostSavings:
            sortableData.find((item) => item.id === 'annualNetCostSavings')
              ?.value ?? '',
          simplePaybackPeriod:
            sortableData.find((item) => item.id === 'simplePaybackPeriod')
              ?.value ?? '',
          annualAvoidedEnergyCost:
            sortableData.find((item) => item.id === 'annualAvoidedEnergyCost')
              ?.value ?? '',
          annualAvoidedMaintenanceCost:
            sortableData.find(
              (item) => item.id === 'annualAvoidedMaintenanceCost'
            )?.value ?? '',
          annualAvoidedOtherCost:
            sortableData.find((item) => item.id === 'annualAvoidedOtherCost')
              ?.value ?? '',
          annualAvoidedTotalCost:
            sortableData.find((item) => item.id === 'annualAvoidedTotalCost')
              ?.value ?? '',
          customFields: customSortableData?.map(
            (item: {
              id: string;
              title: string;
              value: string;
              unit: string;
              isCustomField: boolean;
              helperText?: string;
            }) => {
              return {
                id: item.id,
                title: item.title,
                value: item.value,
                unit: item.unit,
                helperText: item.helperText,
              };
            }
          ),
          sequenceDetails: sortableData?.map((item, index) => {
            return {
              id: item.id,
              sequenceNumber: index,
              isPrimary: item.isPrimary,
            };
          }),
        };

        updateBatchMetaData(updateBatchMetadataPayload, {
          onSuccess: () => {
            if (opportunitySeed) {
              dispatch(
                modalActions.showOpportunityDetailModal({
                  opportunitySeedId: opportunitySeed.id,
                  opportunityType: opportunitySeed.entityType,
                })
              );
            }
          },
        });
        if (opportunitySeed?.id) {
          trackEditProjectTitle('BATCH', opportunitySeed.id, title); //TODO: Add editBatch gainsight action instead
        }
      } else {
        const createOrEditOpportunityRequestPayload: TSCreateOpportunityRequestPayload =
          {
            opportunityId: opportunitySeed?.id,
            title,
            description,
            annualAvoidedCo2e:
              sortableData.find((item) => item.id === 'annualAvoidedCo2e')
                ?.value ?? '',
            annualNetCostSavings:
              sortableData.find((item) => item.id === 'annualNetCostSavings')
                ?.value ?? '',
            simplePaybackPeriod:
              sortableData.find((item) => item.id === 'simplePaybackPeriod')
                ?.value ?? '',
            annualPayment:
              sortableData.find((item) => item.id === 'annualPayment')?.value ??
              '',
            contractRate:
              sortableData.find((item) => item.id === 'contractRate')?.value ??
              '',
            annualEnergySavedOrGeneratedKwh:
              sortableData.find(
                (item) => item.id === 'annualEnergySavedOrGeneratedKwh'
              )?.value ?? '',
            scheduleTermInMonths:
              sortableData.find((item) => item.id === 'scheduleTermInMonths')
                ?.value ?? '',
            annualAvoidedEnergyCost:
              sortableData.find((item) => item.id === 'annualAvoidedEnergyCost')
                ?.value ?? '',
            annualAvoidedMaintenanceCost:
              sortableData.find(
                (item) => item.id === 'annualAvoidedMaintenanceCost'
              )?.value ?? '',
            annualAvoidedOtherCost:
              sortableData.find((item) => item.id === 'annualAvoidedOtherCost')
                ?.value ?? '',
            annualAvoidedTotalCost:
              sortableData.find((item) => item.id === 'annualAvoidedTotalCost')
                ?.value ?? '',
            totalNetCost:
              sortableData.find((item) => item.id === 'totalNetCost')?.value ??
              '',
            totalContractValue:
              sortableData.find((item) => item.id === 'totalContractValue')
                ?.value ?? '',
            address1,
            address2,
            city,
            postalcode,
            country,
            customerId: currentCustomerId,
            ecmType: ecmType.value,
            projectStage: projectStage as any as TSProjectStage,
            state,
            customFields: customSortableData.map(
              (item: {
                id: string;
                title: string;
                value: string;
                unit: string;
                isCustomField: boolean;
                helperText?: string;
              }) => {
                return {
                  id: item.id,
                  title: item.title,
                  value: item.value,
                  unit: item.unit,
                  helperText: item.helperText,
                };
              }
            ),
            sequenceDetails: sortableData?.map((item, index) => {
              return {
                id: item.id,
                sequenceNumber: index,
                isPrimary: item.isPrimary,
              };
            }),
            ecmTypeSpecificFields: sortableData
              ?.filter((item) => item.isEcmSpecificField)
              .reduce((result, item) => {
                result[item.id] = item.value;
                return result;
              }, {}),
          };
        if (isEditMode) {
          editProject(createOrEditOpportunityRequestPayload, {
            onSuccess: () => {
              if (opportunitySeed) {
                dispatch(
                  modalActions.showOpportunityDetailModal({
                    opportunitySeedId: opportunitySeed.id,
                    opportunityType: opportunitySeed.entityType,
                  })
                );
              }
            },
          });
          if (opportunitySeed?.id) {
            trackEditProjectTitle('PROJECT', opportunitySeed.id, title); //TODO: Add editProject gainsight action instead
          }
        } else {
          createOpportunity(createOrEditOpportunityRequestPayload, {
            onSuccess: () => closeModal(),
          });
        }
      }

      const hasNewEcmType = !ecmTypes
        ?.map((item) => item.value)
        .includes(ecmType.value);

      if (hasNewEcmType) {
        createValueInMLOV({
          customerID: currentCustomerId,
          type: 'ECM_TYPE',
          value: ecmType.value,
        });
      }
    },
  });

  const ecmTypesLoading = false;
  useEffect(() => {
    // Populate form fields and sortable data if in edit mode
    if (isEditMode && opportunitySeed) {
      formik.setValues({
        title: opportunitySeed.title,
        address1: opportunitySeed.address1,
        address2: opportunitySeed.address2,
        city: opportunitySeed.city,
        state: opportunitySeed.state,
        postalcode: opportunitySeed.postalcode,
        country: opportunitySeed.country,
        description: opportunitySeed.description,
        ecmType: {
          label: opportunitySeed.ecmType,
          value: opportunitySeed.ecmType,
        },
        projectStage: opportunitySeed.projectStage,
        customerId: '',
        sortableData: sortableData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, opportunitySeed]);

  useEffect(() => {
    dispatch(authActions.getUserRolesAndPermissions());
  }, [currentCustomerId, dispatch]);

  useEffect(() => {
    formik.setFieldValue('sortableData', sortableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortableData]);

  const userIsDenaliAdmin = userRolesAndPermissions.groups.some((group) =>
    denaliAdmins.includes(group)
  );
  const userIsDenaliUser = userRolesAndPermissions.groups.some((group) =>
    denaliUsers.includes(group)
  );
  const userIsRedaptiveUser = userRolesAndPermissions.groups.some((group) =>
    redaptiveRoles.includes(group)
  );

  const isUserAllowedToEdit =
    userIsDenaliAdmin || (userIsDenaliUser && userIsRedaptiveUser);

  const isFieldEditable = (fieldName) => {
    if (!isEditMode) return true;
    // Allow editing for Denali Admins and Denali Users who are also Redaptive Users
    if (isUserAllowedToEdit) {
      return true;
    }
    // Always allow editing for title and description
    if (fieldName === 'title' || fieldName === 'description') {
      return true;
    }
    // Allow editing if the user created the opportunity
    const createdBy = opportunitySeed?.createdBy;
    if (createdBy && createdBy.toLowerCase() === username.toLowerCase()) {
      return true;
    }
    // Disable editing for all other cases
    return false;
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <DefaultModalStyled>
        <ModalWrapperStyled>
          <CloseModalButtonStyled onClick={closeModal}>
            <span />
            <span />
            <span />
          </CloseModalButtonStyled>

          <OpportunityModalBodyStyled>
            <ModalContentStyled>
              <Stack gap={'xl'}>
                <Text size='xl' fw={'600'}>
                  {`${isEditMode ? 'Edit' : 'Create'} ${
                    isBatch ? 'Batch' : 'Project'
                  }`}
                </Text>
                <TextInput
                  size='md'
                  label='Title'
                  placeholder='Enter Title'
                  name='title'
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && formik.errors.title}
                  disabled={!isFieldEditable('title')}
                />
                {!isBatch && (
                  <TextInput
                    size='md'
                    label='Address Line 1'
                    placeholder='Enter Address Line 1'
                    name='address1'
                    value={formik.values.address1}
                    onChange={formik.handleChange}
                    error={formik.touched.address1 && formik.errors.address1}
                    disabled={!isFieldEditable('address1')}
                    required
                  />
                )}
                {!isBatch && (
                  <TextInput
                    size='md'
                    label='Address Line 2'
                    placeholder='Enter Address Line 2'
                    name='address2'
                    value={formik.values.address2}
                    onChange={formik.handleChange}
                    error={formik.touched.address2 && formik.errors.address2}
                    disabled={!isFieldEditable('address2')}
                  />
                )}
                {!isBatch && (
                  <Group gap={'xl'} grow>
                    <TextInput
                      size='md'
                      label='City'
                      placeholder='Enter City'
                      name='city'
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      error={formik.touched.city && formik.errors.city}
                      disabled={!isFieldEditable('city')}
                      required
                    />
                    <TextInput
                      size='md'
                      label='State'
                      placeholder='Enter State'
                      name='state'
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      error={formik.touched.state && formik.errors.state}
                      disabled={!isFieldEditable('state')}
                      required
                    />
                  </Group>
                )}
                {!isBatch && (
                  <Group gap={'xl'} grow>
                    <TextInput
                      size='md'
                      type='text'
                      label='Postal Code'
                      placeholder='Enter Postal Code'
                      name='postalcode'
                      value={formik.values.postalcode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.postalcode && formik.errors.postalcode
                      }
                      disabled={!isFieldEditable('postalcode')}
                      required
                    />

                    <TextInput
                      size='md'
                      label='Country'
                      placeholder='Enter Country'
                      name='country'
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      error={formik.touched.country && formik.errors.country}
                      disabled={!isFieldEditable('country')}
                      required
                    />
                  </Group>
                )}
                <input
                  type='hidden'
                  name='sortableData'
                  value={JSON.stringify(formik.values.sortableData)}
                  disabled={!isFieldEditable('sortableData')}
                />
                <Textarea
                  size='md'
                  autosize
                  label='Description'
                  placeholder='Enter Description'
                  name='description'
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description && formik.errors.description
                  }
                  disabled={!isFieldEditable('description')}
                  maxLength={5000}
                />
                {!isBatch && (
                  <Stack gap={'0'}>
                    <Text>ECM Type</Text>
                    <CreatableSelect
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      options={ecmTypes}
                      placeholder={
                        !ecmTypesLoading
                          ? 'Type to search or create a new ECM Type'
                          : 'ECM Types are still loading...'
                      }
                      name='ecmType'
                      onChange={(selectedOption) => {
                        formik.setFieldValue('ecmType', selectedOption);
                      }}
                      defaultValue={
                        opportunitySeed
                          ? {
                              value: opportunitySeed?.ecmType,
                              label: opportunitySeed?.ecmType,
                            }
                          : undefined
                      }
                      isDisabled={!isFieldEditable('ecmType')}
                      required
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          border: '1px solid #ced4da',
                          boxShadow: 'none',
                          height: '42px',
                          borderRadius: '4px',
                          ':hover': {
                            borderColor: '#162447',
                          },
                        }),
                        valueContainer: (baseStyles) => ({
                          ...baseStyles,
                          padding: '0 0 0 14px',
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                        }),
                      }}
                    />
                  </Stack>
                )}
                {!isEditMode && (
                  <Stack gap={'0'}>
                    <Text>Project Stage</Text>
                    <Select
                      comboboxProps={{ zIndex: 100000 }}
                      size='md'
                      radius={'sm'}
                      allowDeselect={false}
                      data={projectStageValues}
                      value={formik.values.projectStage}
                      onChange={(selectedOption) => {
                        formik.setFieldValue('projectStage', selectedOption);
                      }}
                    />
                  </Stack>
                )}
                {formik.values.ecmType.value && (
                  <Stack gap={'0'}>
                    <SortableTable
                      onDataChange={setSortableData}
                      opportunitySeed={isEditMode ? opportunitySeed : undefined}
                      isEditingDisabled={!isFieldEditable('sortableData')}
                      selectedEcmType={formik.values.ecmType.value}
                    />
                  </Stack>
                )}
              </Stack>
            </ModalContentStyled>
            <ModalFooterStyled>
              <Flex gap={'16px'} align={'center'} justify={'flex-end'}>
                {isEditMode && opportunitySeed && (
                  <MantineButton
                    radius={'md'}
                    h={'40px'}
                    variant='transparent'
                    size='compact-md'
                    onClick={() => {
                      dispatch(
                        modalActions.showOpportunityDetailModal({
                          opportunitySeedId: opportunitySeed.id,
                          opportunityType: opportunitySeed.entityType,
                        })
                      );
                    }}
                  >
                    Cancel
                  </MantineButton>
                )}
                <MantineButton
                  leftSection={
                    isEditMode ? (
                      <UilSave size={16} />
                    ) : (
                      <UilPlusCircle size={16} />
                    )
                  }
                  h={'40px'}
                  variant='primary'
                  radius={'md'}
                  size='compact-md'
                  px={'12px'}
                  type='submit'
                  loading={
                    editProjectPending ||
                    createProjectPending ||
                    isupdateBatchMetadataPending
                  }
                >
                  <Text size='16px' fw={'500'}>
                    {`${isEditMode ? 'Update' : 'Create Project'} `}
                  </Text>
                </MantineButton>
              </Flex>
            </ModalFooterStyled>
          </OpportunityModalBodyStyled>
        </ModalWrapperStyled>
      </DefaultModalStyled>
    </form>
  );
};

export default CreateOrEditOpportunity;
