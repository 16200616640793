import styled from '@emotion/styled';
import {
  Combobox,
  ComboboxData,
  Input,
  MantineSize,
  Pill,
  PillsInput,
  PillsInputProps,
  getParsedComboboxData,
  isOptionsGroup,
  useCombobox,
  useMantineTheme,
} from '@mantine/core';
import { partition } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import MultiSelectOptions from './MultiSelectOptions';
import styles from './styles.module.css';

const ComboboxStyled = styled(Combobox)`
  .mantine-Pill-root {
    border-radius: 4px;
  }

  .mantine-PillsInput-input {
    display: inline-flex;
    border-radius: 4px;
    white-space: nowrap;
  }

  .mantine-PillGroup-group {
    overflow: hidden;
    flex-wrap: nowrap;
  }

  .mantine-InputPlaceholder-placeholder {
    color: black;
  }
`;

type Props = {
  data: ComboboxData;
  limit?: number;
  /* Max number of values displayed before (+[n] more) pill is shown instead */
  maxDisplayedValues?: number;
  onClear?: () => void;
  onDropdownClose?: () => void;
  /* Max width of pills displayed in input field */
  pillMaxWidth?: number;
  placeholder?: string;
  setValues?: (values: string[]) => void;
  showSelectAll?: boolean;
  size?: MantineSize;
  values?: string[];
  withPillRemoveButton?: boolean;
} & PillsInputProps;

const flattenOptions = (options) => {
  const flattened = [] as any;

  const flattenItem = (item) => {
    if (isOptionsGroup(item)) {
      item.items.forEach(flattenItem);
    } else {
      flattened.push(item);
    }
  };

  options.forEach(flattenItem);
  return flattened;
};

const filterData = (data, fnFilter) => {
  const dataToShow = [] as any;
  data.forEach((groupOrItem) => {
    if (isOptionsGroup(groupOrItem)) {
      dataToShow.push({
        ...groupOrItem,
        items: filterData(groupOrItem.items, fnFilter),
      });
    } else {
      // it's an item
      if (fnFilter(groupOrItem)) {
        dataToShow.push(groupOrItem);
      }
    }
  });
  return dataToShow;
};

const MultiSelect = ({
  data: unparsedData,
  disabled,
  error,
  limit,
  maxDisplayedValues = 3,
  onClear,
  onDropdownClose,
  pillMaxWidth = 200,
  placeholder,
  setValues: setExteriorValues,
  showSelectAll = false,
  size = 'md',
  values: exteriorValues,
  withPillRemoveButton = false,
  ...rest
}: Props) => {
  const theme = useMantineTheme();

  const [search, setSearch] = useState('');

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
      onDropdownClose?.();
    },
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex('active');
      combobox.focusSearchInput();
    },
  });

  const data = getParsedComboboxData(unparsedData); // converts any strings into items

  // makes a default onchange handler if one is not passed (maybe just require this??  useful for storybook...)
  const [interiorValues, setInteriorValues] = useState<string[]>([]);
  const values = exteriorValues ?? interiorValues;
  const setValues = setExteriorValues ?? setInteriorValues;

  const handleValueRemove = useCallback(
    (val: string) => {
      setValues(values.filter((v: string) => v !== val));
    },
    [setValues, values]
  );

  const flatData = useMemo(() => flattenOptions(data), [data]);
  const isItemSelected = useCallback(
    (item): boolean => {
      if (item.value == '*') {
        return flatData.length == values.length;
      } else return values.includes(item.value);
    },
    [flatData.length, values]
  );

  // build the options components for display, filtered by any search string
  const filteredOptions = useMemo(() => {
    const [selectedItems, notSelectedItems] = partition(data, isItemSelected);
    const selectedFirstData = selectedItems.concat(notSelectedItems);
    return filterData(
      selectedFirstData,
      (val: { label: string }) =>
        val.label &&
        val.label.toLowerCase().includes((search ?? '').toLowerCase().trim())
    );
  }, [data, isItemSelected, search]);
  const filteredOptionsFlat: Array<{ label: string; value: string }> =
    useMemo(() => {
      return flattenOptions(filteredOptions);
    }, [filteredOptions]);
  const handleValueSelect = useCallback(
    (val: string) => {
      if (val == '*') {
        setValues(
          // case: all options are selected already, hence deselect all
          values.length == flatData.length
            ? []
            : flatData.map((option) => option.value)
        );
      } else
        setValues(
          values.includes(val)
            ? values.filter((v) => v !== val)
            : [...values, val]
        );
    },
    [values, setValues, flatData]
  );

  const displayValues = useMemo(() => {
    if (maxDisplayedValues === 0) {
      return [];
    }
    const allItems = data.reduce((acc, groupOrItem) => {
      if (isOptionsGroup(groupOrItem)) {
        acc.push(...groupOrItem.items);
      } else {
        acc.push(groupOrItem);
      }
      return acc;
    }, [] as any);
    const selectedVals = allItems.filter((item) => isItemSelected(item));
    return selectedVals.slice(0, maxDisplayedValues);
  }, [data, isItemSelected, maxDisplayedValues]);

  return (
    <ComboboxStyled
      disabled={disabled}
      offset={4}
      onOptionSubmit={handleValueSelect}
      position='bottom-start'
      radius={4}
      shadow='sm'
      size={size}
      store={combobox}
      width='fit-content'
      withinPortal={false}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          className={styles.pillsInput}
          disabled={disabled}
          error={error}
          onClick={() => combobox.toggleDropdown()}
          pointer
          rightSection={
            values && values.length ? (
              <Combobox.ClearButton
                size='sm'
                onMouseDown={(event) => event.preventDefault()}
                onClear={() => {
                  onClear?.();
                  setValues([]);
                }}
                aria-label='Clear values'
              />
            ) : (
              <Combobox.Chevron
                size={size}
                c={error ? theme.colors.red[5] : undefined}
              />
            )
          }
          rightSectionPointerEvents={values.length === 0 ? 'none' : 'all'}
          size={size}
          styles={{ input: { padding: '0 8px' } }}
          {...rest}
        >
          <Pill.Group data-testid='pill-group' gap={4}>
            {displayValues.map((item) => (
              <Pill
                size={size}
                key={item.value}
                withRemoveButton={withPillRemoveButton}
                onRemove={() => handleValueRemove(item.value)}
                maw={pillMaxWidth}
                p='0 8px'
              >
                {item.label}
              </Pill>
            ))}
            {values.length > maxDisplayedValues && (
              <Pill size={size} p='0 8px'>
                +{values.length - maxDisplayedValues}
              </Pill>
            )}
            {values.length === 0 && placeholder && (
              <Input.Placeholder c='gray.6'>{placeholder}</Input.Placeholder>
            )}

            <Combobox.EventsTarget>
              <PillsInput.Field
                type='hidden'
                onKeyDown={(event) => {
                  if (event.key === 'Backspace') {
                    event.preventDefault();
                    handleValueRemove(values[values.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>
      <Combobox.Dropdown p={0} style={{ borderRadius: 4 }}>
        <Combobox.Search
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder='Search'
          styles={{
            input: { margin: '0', width: '100%', borderRadius: '4px 4px 0 0' },
          }}
        />
        {!filteredOptionsFlat.length ? (
          <Combobox.Empty p='md'>Nothing found</Combobox.Empty>
        ) : (
          <MultiSelectOptions
            options={filteredOptions}
            isItemSelected={isItemSelected}
            showSelectAll={showSelectAll && !!filteredOptionsFlat.length}
            limit={limit}
          />
        )}
      </Combobox.Dropdown>
    </ComboboxStyled>
  );
};

export default MultiSelect;
