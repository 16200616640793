import {
  UilCalender,
  UilCircle,
  UilPen,
  UilSubject,
  UilTrash,
  UilUser,
} from '@iconscout/react-unicons';
import {
  Button,
  Checkbox,
  Group,
  Stack,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import * as yup from 'yup';
import Paper from '../../../../../components/mantine/Paper';
import { EMPTY_METRIC_STRING } from '../../../../../constants/strings';
import classes from './ProjectTaskCard.module.css';

interface TaskCardProps {
  id: string;
  title: string;
  description: string;
  date: Date | null;
  assignedToEmail: string | null;
  isNew?: boolean;
  status?: string;
  sequenceNumber: number;
  handleCancelInParent?: (...args) => void;
  handleSaveInParent?: (...args) => void;
  handleCheckboxClickInParent?: (...args) => void;
  handleRemoveTaskInParent?: (...args) => void;
  assignees?: Array<{ label: string; value: string }>;
}

const ProjectTaskCard = ({
  id,
  title,
  description,
  date,
  assignedToEmail,
  isNew = false,
  handleCancelInParent,
  handleSaveInParent,
  handleCheckboxClickInParent,
  handleRemoveTaskInParent,
  assignees,
  sequenceNumber,
  status,
}: TaskCardProps) => {
  const validationSchema = yup.object({
    title: yup
      .string()
      .required('Title is required')
      .max(40, 'Task title must be no longer than 40 characters'),
    description: yup
      .string()
      .required('Description is required')
      .max(200, 'Task description must be no longer than 200 characters'),
  });
  const [isViewMode, setIsViewMode] = useState(!isNew);
  const isCompleted = status == 'COMPLETED';
  const handleTaskCancel = () => {
    setIsViewMode(true);
    handleCancelInParent && handleCancelInParent(id);
  };

  const today = moment().endOf('day');

  const initialValues = {
    id: id || '',
    title: title || '',
    description: description || '',
    assignedToEmail: assignedToEmail
      ? { label: assignedToEmail, value: assignedToEmail }
      : { label: null, value: null },
    dueDate: date ? moment(date).toDate() : null,
    sequenceNumber: sequenceNumber || 0,
    status: status || 'IN_PROGRESS',
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (handleSaveInParent) {
        handleSaveInParent({
          formData: {
            id,
            data: values,
            isNew,
          },
        });

        setIsViewMode(false);
      }
    },
  });

  useEffect(() => {
    formik.setValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, title, description, assignedToEmail, date, sequenceNumber, status]);

  const getCardIconColor = () => {
    if (!isViewMode) {
      return '#485DA0';
    } else if (isCompleted) {
      return '#BBBCBD';
    } else {
      return '#4D4D4F';
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Paper
        className={classes.cardRoot}
        radius={'md'}
        withBorder
        p={'sm'}
        styles={{
          root: {
            borderColor: isViewMode ? undefined : '#485DA0',
          },
        }}
      >
        <Stack gap={0}>
          <Group mih={42}>
            {isViewMode ? (
              <Checkbox
                className={classes.statusCheckbox}
                radius='lg'
                size='sm'
                name='status'
                checked={isCompleted}
                disabled={isCompleted}
                onChange={(event) =>
                  handleCheckboxClickInParent &&
                  handleCheckboxClickInParent({
                    statusData: {
                      taskId: id,
                      isChecked: event.currentTarget.checked,
                    },
                  })
                }
              />
            ) : (
              <UilCircle color='#485DA0' size={20} />
            )}
            {isViewMode ? (
              <Text
                fs={'14px'}
                fw={'600'}
                c={isCompleted ? '#BBBCBD' : 'blue.5'}
                lineClamp={1}
                w={'80%'}
              >
                {title}
              </Text>
            ) : (
              <TextInput
                variant='unstyled'
                placeholder='Task title...'
                w={'80%'}
                fw={'600'}
                styles={{
                  input: {
                    color: '#485DA0',
                  },
                }}
                size='md'
                name='title'
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && formik.errors.title}
              />
            )}
          </Group>
          <Group mih={isViewMode ? 36 : undefined}>
            <UilSubject color={getCardIconColor()} size={20} />
            {isViewMode ? (
              <Text size='sm' c={isCompleted ? '#BBBCBD' : '#4D4D4F'} w={'80%'}>
                {description ?? EMPTY_METRIC_STRING}
              </Text>
            ) : (
              <Textarea
                autosize
                variant='unstyled'
                placeholder='Description'
                size='sm'
                w={'80%'}
                name='description'
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.touched.description && formik.errors.description}
              />
            )}
          </Group>
          {isViewMode ? (
            <Group mih={36}>
              <Group>
                <UilCalender color={getCardIconColor()} size={20} />
                <Text size='sm' c={isCompleted ? '#BBBCBD' : '#4D4D4F'}>
                  {date
                    ? moment(date).format('DD MMM, YYYY')
                    : EMPTY_METRIC_STRING}
                </Text>
              </Group>
              <Group>
                <UilUser color={getCardIconColor()} size={20} />
                <Text size='sm' c={isCompleted ? '#BBBCBD' : '#4D4D4F'}>
                  {assignedToEmail ?? EMPTY_METRIC_STRING}
                </Text>
              </Group>
            </Group>
          ) : (
            <Stack gap={0}>
              <Group>
                <UilCalender color={getCardIconColor()} size={20} />
                <DatePickerInput
                  placeholder='Due date'
                  variant='unstyled'
                  popoverProps={{ zIndex: 9999 }}
                  minDate={today.toDate()}
                  w={'50%'}
                  name='dueDate'
                  value={formik.values.dueDate}
                  onChange={(selectedDate) => {
                    // set end of selected day as the dueDate
                    formik.setFieldValue(
                      'dueDate',
                      moment(selectedDate).endOf('day')
                    );
                  }}
                  error={
                    formik.touched.dueDate &&
                    (formik?.errors?.dueDate as string)
                  }
                />
              </Group>
              <Group wrap='nowrap'>
                <UilUser color={getCardIconColor()} size={20} />
                <CreatableSelect
                  isClearable
                  options={assignees}
                  placeholder={'Assignee'}
                  styles={{
                    container: (baseStyles) => ({
                      ...baseStyles,
                      flexBasis: '55%',
                      display: 'inline-flex',
                    }),
                    control: (baseStyles) => ({
                      ...baseStyles,
                      border: 0,
                      boxShadow: 'none',
                      minHeight: '36px',
                      width: '100%',
                      cursor: 'pointer',
                    }),
                    valueContainer: (baseStyles) => ({
                      ...baseStyles,
                      paddingLeft: 0,
                    }),
                    input: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: '12px',
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: '12px',
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      fontSize: '12px',
                    }),
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  name='assignedToEmail'
                  defaultValue={
                    assignedToEmail
                      ? {
                          value: assignedToEmail,
                          label: assignedToEmail,
                        }
                      : undefined
                  }
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      'assignedToEmail',
                      selectedOption ?? null
                    );
                  }}
                />
              </Group>
            </Stack>
          )}

          {isViewMode && !isCompleted && (
            <Group gap={'12px'} className={classes.topRightButtonsContainer}>
              <UilPen
                size={20}
                color='#4D4D4F'
                onClick={() => setIsViewMode(false)}
              />
              <UilTrash
                size={20}
                color={'#D42E2E'}
                onClick={() =>
                  handleRemoveTaskInParent && handleRemoveTaskInParent(id)
                }
              />
            </Group>
          )}
          {isViewMode && isCompleted && (
            <Button
              className={classes.markIncompleteButton}
              variant='transparent'
              c={'blue.5'}
              style={{ textDecoration: 'underline' }}
              onClick={() =>
                handleCheckboxClickInParent &&
                handleCheckboxClickInParent({
                  statusData: {
                    taskId: id,
                    isChecked: false,
                  },
                })
              }
            >
              Mark as incomplete
            </Button>
          )}

          {!isViewMode && (
            <Group gap={'12px'} className={classes.bottomRightButtonsContainer}>
              <Button
                color='#F0F0F1'
                c={'#162447'}
                variant='filled'
                radius={'8px'}
                onClick={handleTaskCancel}
              >
                Cancel
              </Button>
              <Button radius={'8px'} type='submit'>
                Save
              </Button>
            </Group>
          )}
        </Stack>
      </Paper>
    </form>
  );
};

export default ProjectTaskCard;
