import { UilCommentAlt } from '@iconscout/react-unicons';
import { Checkbox, Container, Flex, Group, Pill, Stack } from '@mantine/core';
import styled from 'styled-components';
import { gapMap } from '../../../../components/Stack';
import { EMPTY_METRIC_STRING } from '../../../../constants/strings';
import { useFilterStore } from '../../../../globalState/allProjectsPostNtp';
import { projectStageMap } from '../../../../queries/allProjectsPostNtp/types';
import { getDisplayAddress } from '../../../../queries/allProjectsPostNtp/utils';
import { capitalizeFirstLetter, truncateString } from '../../../../utils';

export const OpportunityCardTitleStyled = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 16px;
  font-weight: 600;
  overflow-x: hidden;
`;

const AddressStyled = styled.div`
  color: #6c6d6e;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 12px;
  font-weight: 400;
`;

const IdStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: 700;
  font-size: 12px;
`;

const ActionsContainer = styled.div`
  align-self: start;
  display: flex;
  gap: 8px;
  svg {
    cursor: pointer;
  }
`;

type TSProps = {
  handleSelect: (string, boolean) => void;
  handleViewDetails: (...any) => void;
  seed: any;
};

const KanbanCard = ({ handleSelect, handleViewDetails, seed }: TSProps) => {
  const { selectedSeeds } = useFilterStore();

  function onSelect(event) {
    event.stopPropagation();
    handleSelect && handleSelect(seed.id, selectedSeeds[seed.id]);
  }

  function onViewDetails(event) {
    event.stopPropagation();
    handleViewDetails(seed);
  }

  function onCommentClick(event) {
    event.stopPropagation();
    handleViewDetails(seed, true); //here the second param is to set shouldFocusToComments
  }

  // Return one of two styles, depending on if opportunity is a batch or single
  const getCardStyles = () => {
    const styles = {
      backgroundColor: '#fff',
      borderRadius: '8px',
      marginBottom: '8px',
      border: '1px solid #e0e0e0',
      cursor: 'pointer',
    };
    if (seed.entityType == 'batch') {
      return {
        ...styles,
        boxShadow: '6px 6px 0 #fff, 7px 7px 0 #e0e0e0',
        marginRight: '6px',
      };
    } else {
      return {
        ...styles,
      };
    }
  };

  const getOpportunityAddress = () => {
    const displayAddress = getDisplayAddress(seed, 'full');
    return `${
      displayAddress != ''
        ? truncateString(displayAddress, 200)
        : EMPTY_METRIC_STRING
    }`;
  };

  return (
    <Flex
      style={getCardStyles()}
      p={`${gapMap['md']}px`}
      onClick={(e) => onViewDetails(e)}
    >
      <Container p={0}>
        <Checkbox
          radius='sm'
          checked={selectedSeeds[seed.id]}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => onSelect(e)}
        />
      </Container>
      <Container p={0} pl={`${gapMap['sm']}px`} style={{ flexGrow: 1 }}>
        <Stack gap={`${gapMap['xs']}px`}>
          {/* Begin header section */}
          <Group justify={'space-between'} wrap='nowrap'>
            <OpportunityCardTitleStyled>
              {truncateString(
                `${capitalizeFirstLetter(
                  seed.title ? seed.title : seed.ecmType
                )}`,
                35
              )}
            </OpportunityCardTitleStyled>
            <ActionsContainer>
              <UilCommentAlt
                onClick={(e) => {
                  onCommentClick(e);
                }}
                size='20'
                color='#485DA0'
              />
            </ActionsContainer>
          </Group>
          {/* End header section */}
          <div>
            <AddressStyled>{getOpportunityAddress()}</AddressStyled>
            <IdStyled>ID: {seed.displayId}</IdStyled>
          </div>

          <Pill radius={'sm'} bg={'#cad4ea'} mr={'auto'} mt={'10px'}>
            {projectStageMap[seed.projectStage].label}
          </Pill>
        </Stack>
      </Container>
    </Flex>
  );
};

export default KanbanCard;
