import { Anchor, Flex, Stack, Text, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { queryStringify } from '../../../../../api/utils';
import { EMPTY_METRIC_STRING } from '../../../../../constants/strings';
import {
  TSOpportunityBatch,
  TSOpportunitySeed,
} from '../../../../../queries/allProjectsPostNtp/types';
import { getCombinedOpportunityAddress } from '../../../../../queries/allProjectsPostNtp/utils';
import theme from '../../../../../themes/redaptiveOne';
const DetailsModalHeader = ({
  headerData: { opportunitySeed, isBatch, parentBatch },
}: {
  headerData: {
    opportunitySeed: TSOpportunitySeed;
    isBatch: boolean;
    parentBatch: TSOpportunityBatch | null;
  };
}) => {
  const navigate = useNavigate();

  const getOpportunityAddress = () => {
    const address = getCombinedOpportunityAddress(opportunitySeed, 'full');
    return `${address != '' ? address : EMPTY_METRIC_STRING}`;
  };

  return (
    <Flex justify='space-between' gap='sm' align='flex-start'>
      <Stack gap='sm'>
        <Text c='grey.4' fw='500'>
          {parentBatch?.id ? (
            <>
              <Anchor
                c={'grey.8'}
                onClick={() => {
                  navigate(
                    `${location?.pathname}?${queryStringify({
                      opportunityId: parentBatch.id,
                      opportunityType: 'batch',
                      customerId: parentBatch.customerId,
                    })}`
                  );
                }}
              >
                {parentBatch.displayId}
              </Anchor>
              <span> / </span>
            </>
          ) : null}
          {opportunitySeed.displayId}
        </Text>
        <Title>
          {opportunitySeed.title != ''
            ? opportunitySeed.title
            : `${opportunitySeed.city}, ${opportunitySeed.state} - ${opportunitySeed.ecmType}`}
        </Title>
        {!isBatch && (
          <Text c={theme.colors.fontMain} fw={600}>
            {getOpportunityAddress()}
          </Text>
        )}
      </Stack>
    </Flex>
  );
};

export default DetailsModalHeader;
