interface TSProps {
  color?: string;
  size?: string;
}

const ListViewIcon = ({ color = '#162447', size = '20' }: TSProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d={`M3.09219 13.575C3.01293 13.4991 2.91948 13.4397 2.81719 13.4C2.6143 13.3167 2.38674 13.3167 
2.18385 13.4C2.08156 13.4397 1.98811 13.4991 1.90885 13.575C1.83299 13.6543 1.77351 13.7477 1.73385 
13.85C1.67004 14.0018 1.6526 14.169 1.68375 14.3307C1.71489 14.4923 1.79322 14.6411 1.90885 14.7583C1.98985 
14.8319 2.08287 14.8911 2.18385 14.9333C2.2836 14.9774 2.39146 15.0002 2.50052 15.0002C2.60958 15.0002 
2.71744 14.9774 2.81719 14.9333C2.91817 14.8911 3.01119 14.8319 3.09219 14.7583C3.20782 14.6411 3.28615 
14.4923 3.31729 14.3307C3.34844 14.169 3.331 14.0018 3.26719 13.85C3.22752 13.7477 3.16805 13.6543 3.09219 
13.575ZM5.83385 6.66667H17.5005C17.7215 6.66667 17.9335 6.57887 18.0898 6.42259C18.2461 6.26631 18.3339 
6.05435 18.3339 5.83333C18.3339 5.61232 18.2461 5.40036 18.0898 5.24408C17.9335 5.0878 17.7215 5 17.5005 
5H5.83385C5.61284 5 5.40088 5.0878 5.2446 5.24408C5.08832 5.40036 5.00052 5.61232 5.00052 5.83333C5.00052 
6.05435 5.08832 6.26631 5.2446 6.42259C5.40088 6.57887 5.61284 6.66667 5.83385 6.66667ZM3.09219 9.40833C2.975 
9.2927 2.82619 9.21437 2.66453 9.18323C2.50287 9.15208 2.33561 9.16952 2.18385 9.23333C2.08287 9.27554 
1.98985 9.33472 1.90885 9.40833C1.83299 9.48759 1.77351 9.58104 1.73385 9.68333C1.68976 9.78308 1.66699 
9.89094 1.66699 10C1.66699 10.1091 1.68976 10.2169 1.73385 10.3167C1.77605 10.4177 1.83524 10.5107 
1.90885 10.5917C1.98985 10.6653 2.08287 10.7245 2.18385 10.7667C2.2836 10.8108 2.39146 10.8335 2.50052 10.8335C2.60958 
10.8335 2.71744 10.8108 2.81719 10.7667C2.91817 10.7245 3.01119 10.6653 3.09219 10.5917C3.1658 10.5107 3.22498 
10.4177 3.26719 10.3167C3.31127 10.2169 3.33405 10.1091 3.33405 10C3.33405 9.89094 3.31127 9.78308 3.26719 
9.68333C3.22752 9.58104 3.16805 9.48759 3.09219 9.40833ZM17.5005 9.16667H5.83385C5.61284 9.16667 5.40088 
9.25446 5.2446 9.41074C5.08832 9.56703 5.00052 9.77899 5.00052 10C5.00052 10.221 5.08832 10.433 5.2446 
10.5893C5.40088 10.7455 5.61284 10.8333 5.83385 10.8333H17.5005C17.7215 10.8333 17.9335 10.7455 18.0898 
10.5893C18.2461 10.433 18.3339 10.221 18.3339 10C18.3339 9.77899 18.2461 9.56703 18.0898 9.41074C17.9335 
9.25446 17.7215 9.16667 17.5005 9.16667ZM3.09219 5.24167C3.01293 5.1658 2.91948 5.10633 2.81719 5.06667C2.66543 
5.00285 2.49817 4.98542 2.33651 5.01656C2.17485 5.04771 2.02604 5.12604 1.90885 5.24167C1.83524 5.32267 1.77605 
5.41568 1.73385 5.51667C1.68976 5.61642 1.66699 5.72427 1.66699 5.83333C1.66699 5.94239 1.68976 6.05025 1.73385 
6.15C1.77605 6.25099 1.83524 6.344 1.90885 6.425C1.98985 6.49861 2.08287 6.5578 2.18385 6.6C2.33561 6.66382 2.50287 
6.68125 2.66453 6.65011C2.82619 6.61896 2.975 6.54063 3.09219 6.425C3.1658 6.344 3.22498 6.25099 3.26719 6.15C3.31127 
6.05025 3.33405 5.94239 3.33405 5.83333C3.33405 5.72427 3.31127 5.61642 3.26719 5.51667C3.22498 5.41568 3.1658 5.32267 
3.09219 5.24167ZM17.5005 13.3333H5.83385C5.61284 13.3333 5.40088 13.4211 5.2446 13.5774C5.08832 13.7337 5.00052 13.9457 
5.00052 14.1667C5.00052 14.3877 5.08832 14.5996 5.2446 14.7559C5.40088 14.9122 5.61284 15 5.83385 15H17.5005C17.7215 15 
17.9335 14.9122 18.0898 14.7559C18.2461 14.5996 18.3339 14.3877 18.3339 14.1667C18.3339 13.9457 18.2461 13.7337 18.0898 
13.5774C17.9335 13.4211 17.7215 13.3333 17.5005 13.3333Z`}
        fill={color}
      />
    </svg>
  );
};

export default ListViewIcon;
