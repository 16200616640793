import Big from 'big.js';
import { camelCase as lodashCamelCase, startCase } from 'lodash';
import moment from 'moment';
import makeNatSort from 'natsort';
import { TSItem } from '../components/ListSelector';

/**
 * Configures natural sorting to be case-insensitive.
 */
const natSort = makeNatSort({ insensitive: true });

/**
 * Prepares string for sorting.
 *
 * - Ensures that whitespace is trimmed.
 * - Ensures spaces are sorted first.
 */
const prepareSortString = (sortString: string) =>
  sortString
    // Removes leading spaces.
    .trim()
    // Puts spaces first.
    // This makes "7 b" come before "7a"
    .replace(' ', '-');

/**
 * Sorts naturally.
 */
export const naturallySort = (a: string, b: string) =>
  natSort(prepareSortString(a), prepareSortString(b));

/**
 * Sorts with null, undefined, or an empty string at the bottom.
 */
export const naturallySortEmptyLastCaseInsensitive = (
  a: string,
  b: string,
  desc?: boolean
) => {
  const nullReplacement = desc ? '' : 'zzzzzzz';
  const getAdjustedValue = (value) => {
    const adjustedForEmpty =
      value === null || value === undefined || value === ''
        ? nullReplacement
        : value;
    return typeof adjustedForEmpty === 'string'
      ? adjustedForEmpty.toUpperCase()
      : value;
  };

  return natSort(getAdjustedValue(a), getAdjustedValue(b));
};

export const naturallySortItems = (a: TSItem, b: TSItem) =>
  naturallySortEmptyLastCaseInsensitive(a.name, b.name);

/**
 * compare two numbers
 * return 1 if a > b, 0 if a == b, -1 if a <b
 * used for sorting
 */
export const numberCompareTo = (a: number, b: number) =>
  // eslint-disable-next-line no-nested-ternary
  a > b ? 1 : a === b ? 0 : -1;

export const zIndices: {
  actionsPopup: number;
  homePageSiteSelector: number;
  mainMenuOverlay: number;
  siteConfigModal: number;
  modalPopup: number;
  datePickerDropdown: number;
} = {
  actionsPopup: 1,
  // The main menu on mobile needs to appear over the Leaflet map controls,
  // which have a z-index of 1000, and the home page site selector.
  mainMenuOverlay: 1002,
  homePageSiteSelector: 1001,
  siteConfigModal: 1003,
  modalPopup: 2000, // should be on top of everything
  datePickerDropdown: 1000,
};

export const isValueSet = (value: any) =>
  value !== null && value !== undefined && value !== '';

export const getCurrencyFormat = (
  locale: string,
  currencyCode: string = 'USD',
  precision?: number
): Intl.NumberFormat =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: isValueSet(precision) ? precision : 2,
  });

export const urlSearchParams = (): URLSearchParams =>
  new URLSearchParams(window.location.search);

/**
 * Truncates a number to a particular number of decimal places.
 *
 * Usage:
 *   - var a = 5.467;
 *   - var truncated = truncateDecimals(a, 2); // = 5.46
 * Negative digits:
 *   - var b = 4235.24;
 *   - var truncated = truncateDecimals(b, -2); // = 4200
 *
 * @see https://stackoverflow.com/questions/4912788/truncate-not-round-off-decimal-numbers-in-javascript
 *
 * @param {number} num
 *   Any number.
 * @param {number} digits
 *   The number of digits to display after the decimal.
 *
 * @return {number}
 *  The truncated number.
 */
export const truncateDecimals = (num: number, digits: number): number => {
  const numS = num.toString();
  const decPos = numS.indexOf('.');
  const substrLength = decPos === -1 ? numS.length : 1 + decPos + digits;
  const trimmedResult = numS.substring(0, substrLength);
  const finalResult = typeof trimmedResult === 'undefined' ? 0 : trimmedResult;

  return parseFloat(finalResult.toString());
};

export const formatNumberToDecimals = (
  num: number,
  digits = 3,
  locale = 'en-US'
): string =>
  num.toLocaleString(locale, {
    maximumFractionDigits: digits,
  });

export const getCurrencyFormatNoDecimals = (
  locale: string,
  currencyPreference: string
): any =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyPreference,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

export const getNumberFormatMaxFractionDigits2 = (locale: string) =>
  new Intl.NumberFormat(locale, { maximumFractionDigits: 2 });

export const getNumberFormatNoDecimals = (locale: string) =>
  new Intl.NumberFormat(locale, { maximumFractionDigits: 0 });

export const getNumberFormatMinFractionDigits2 = (locale: string) =>
  new Intl.NumberFormat(locale, { minimumFractionDigits: 2 });

export const camelCase = (text: string) => {
  text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));
  return text.substring(0, 1).toLowerCase() + text.substring(1);
};

export const getNumberWithPrecision = (
  value: string | number,
  precision: number
) => parseFloat(Big(value).toFixed(precision));

export const capitalizeFirstLetter = (string: string): string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

export const timeTo12Hours = (time) =>
  moment(time, ['HH.mm']).format('hh:mm a');

export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(' ');
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'pm') {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};

export const locationSearchToObject = (search) => {
  const tmpParams = search
    .substring(1)
    .split('&')
    .reduce(function (result, value) {
      const parts = value.split('=');
      if (parts[0])
        result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
      return result;
    }, {});
  return tmpParams;
};

export const findOpportunitiesMaxValue = (
  array,
  property,
  rounded?: boolean
) => {
  if (array.length === 0) {
    return 100000; // Return default value if the array is empty
  }

  const propertyValues = array.map((item) => item[property]);
  let maxValue = Math.max(...propertyValues);
  if (rounded) {
    maxValue = roundOpportunitiesMinMax(maxValue, 'max');
  }
  return maxValue;
};

export const findOpportunitiesMinValue = (
  array,
  property,
  rounded?: boolean
) => {
  if (array.length === 0) {
    return 0; // Return default value if the array is empty
  }
  const propertyValues = array.map((item) => item[property]);
  let minValue = Math.min(...propertyValues);
  if (rounded) {
    minValue = roundOpportunitiesMinMax(minValue, 'min');
  }
  return minValue;
};

export const currencySymbol = (currencyCode) =>
  Number()
    .toLocaleString('en', {
      style: 'currency',
      currency: currencyCode || 'USD',
    })
    .replace(/[\d., a-zA-Z]/g, '');

export const getUniqueListBy = (arr: Array<any>, key: string) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const truncateString = (string: string, numberOfChars: number) => {
  if (string?.length > numberOfChars) {
    return string.substring(0, numberOfChars) + '...';
  } else {
    return string;
  }
};

export const roundOpportunitiesMinMax = (
  val: number,
  minOrMax: 'min' | 'max'
): number => {
  const multiplier = 1000;
  let boundary;
  if (minOrMax == 'min') {
    boundary = Math.floor(val / multiplier) * multiplier;
  } else {
    boundary = Math.ceil(val / multiplier) * multiplier;
  }
  if (boundary === 0) {
    boundary = 0; // handling -0
  }
  return boundary;
};

export const isValidEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const convertToArray = (value: string | undefined) =>
  value?.split(',').filter((value) => value) || [];

export const extractFileNameInfo = (fileName) => {
  if (fileName) {
    const parts = fileName.split('.');
    const extension = parts.pop();
    const name = parts?.join('.');
    return {
      name: name,
      extension: extension,
    };
  }
};

export function swapCommasAndPeriods(input) {
  // Replace commas with a temporary token
  const stringWithPeriods = input.replace(/,/g, '#temp#');
  // Replace periods with commas and restore the commas from the temporary token
  const result = stringWithPeriods.replace(/\./g, ',').replace(/#temp#/g, '.');

  return result;
}

export function formatNumberWithCustomFormatting(input, thousandsSeparator) {
  if (typeof input === 'string') {
    input = input.replace(/,/g, '');
  }
  const rate = formatNumberToDecimals(Number(input), 2);
  return thousandsSeparator === 'period' ? swapCommasAndPeriods(rate) : rate;
}

export const pluralize = (count: number, singular: string, plural?: string) =>
  count === 1 ? singular : plural ?? `${singular}s`;

export const slugify = (text: string): string => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const titleCase = (str: string): string =>
  startCase(lodashCamelCase(str));
