import { Input, alpha } from '@mantine/core';
import moment from 'moment';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import SegmentedControl from '../../../components/mantine/SegmentedControl';
import { DATE_FORMAT_DATA_API_REQUEST } from '../../../constants';
import { Resolution, ResourceType } from '../../../ducks/types';
import {
  compatibleResolutionsByResource,
  resolutionOptions,
} from '../../../utils/getUrlSearchParams';
import { DataExplorerFilterParams } from '../types';

type Props = {
  pendingFilters: DataExplorerFilterParams;
  setPendingFilters: Dispatch<SetStateAction<DataExplorerFilterParams>>;
};

const moreThan30Days = (fromStr: string, toStr: string) => {
  const fromDate = moment(fromStr, DATE_FORMAT_DATA_API_REQUEST);
  const toDate = moment(toStr, DATE_FORMAT_DATA_API_REQUEST);

  return toDate.diff(fromDate, 'days') > 30;
};

const ResolutionSelect = ({ pendingFilters, setPendingFilters }: Props) => {
  const { pageId = 'consumption-analysis' } = useParams();

  // Available resolutions by tab, date range, resource type
  const consumptionAnalysisResolutions =
    pendingFilters.resourceType === ResourceType.ELECTRICITY &&
    moreThan30Days(pendingFilters.fromDate ?? '', pendingFilters.toDate ?? '')
      ? [Resolution.DAILY]
      : compatibleResolutionsByResource[pendingFilters.resourceType];
  const compatibleResolutionsByTab = {
    'consumption-analysis': consumptionAnalysisResolutions,
    'operating-hours': [Resolution.HOURLY],
    'peak-demand': [Resolution.FIFTEEN_MINUTES],
    'voltage-analysis': [Resolution.FIFTEEN_MINUTES],
  };
  const tabResolutions = compatibleResolutionsByTab[pageId];
  const filteredResolutionOptions = resolutionOptions
    .filter(({ id }) => tabResolutions.includes(id))
    .map(({ id, name }) => ({
      label: name,
      value: id,
      gainsightTagId: `sites-query-filter-selector-resolution-selector-${id}`,
    }));

  const handleResolutionUpdate = useCallback(
    (value: string | null) => {
      setPendingFilters((prev) => ({
        ...prev,
        resolution: (value as Resolution) ?? '',
      }));
    },
    [setPendingFilters]
  );

  return (
    <Input.Wrapper
      size='md'
      label='Resolution'
      style={{ flexDirection: 'column', display: 'flex' }}
    >
      <SegmentedControl
        activeColor='blue.7'
        activeOption={pendingFilters.resolution}
        bg='white'
        color={alpha('var(--mantine-color-blue-5)', 0.2)}
        data-gainsight-id='sites-query-filter-selector-resolution-selector'
        disabled={filteredResolutionOptions.length === 1}
        h={42}
        inactiveColor='blue.7'
        options={filteredResolutionOptions}
        size='md'
        updateOption={handleResolutionUpdate}
        style={{
          border: '1px solid #ced4da',
        }}
        styles={{
          indicator: { height: 30 },
          label: { padding: '9px 12px', height: 30 },
        }}
      />
    </Input.Wrapper>
  );
};

export default ResolutionSelect;
