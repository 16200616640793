import Highcharts, {
  AxisLabelsFormatterContextObject,
  Options,
  SeriesWaterfallOptions,
  TooltipFormatterContextObject,
} from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DraggablePoints from 'highcharts/modules/draggable-points';
import Exporting from 'highcharts/modules/exporting';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import moment from 'moment';
import { title } from 'process';
import { useRef } from 'react';
import { useFormatNumber } from '../../components/mantine/Quantity/FormatNumber';
import { Resources } from '../../components/mantine/Quantity/types';
import { useUnit } from '../../components/mantine/Quantity/Unit';
import useConvertQuantity from '../../components/mantine/Quantity/useConvertQuantity';
import { Resolution } from '../../ducks/types';
import { EnhancedGroupData } from '../../queries/resourceUsage';
import {
  formatTimestamp,
  formatTimestampForTooltip,
  getTooltipDateFormat,
  getWeekendRanges,
  getXAxisDateFormat,
  getXAxisTickIntervals,
} from '../../utils/chartHelper';
import { config as baseConfig } from '../../utils/chartsDefaultConfig';

Exporting(Highcharts);
NoDataToDisplay(Highcharts);
DraggablePoints(Highcharts);

interface TSProps extends HighchartsReact.Props {
  groups?: EnhancedGroupData[];
  fromDate?: string;
  isSingleSiteSelected: boolean;
  resolution: Resolution;
  resource: Resources;
  timezone?: string;
  toDate?: string;
  ts?: Array<string>;
}

const ConsumptionLineChart = ({
  groups = [],
  fromDate,
  isSingleSiteSelected,
  resolution,
  resource,
  timezone,
  toDate,
  ts = [],
  ...rest
}: TSProps) => {
  const chartRef = useRef(null);
  const formatNumber = useFormatNumber();
  const convertQuantity = useConvertQuantity();
  const unit = useUnit();

  const { xAxisMajorTickInterval, xAxisMinorTickInterval } =
    getXAxisTickIntervals(moment(fromDate), moment(toDate), resolution);
  const tooltipDateFormat = getTooltipDateFormat(
    resolution,
    isSingleSiteSelected
  );
  const xAxisDateFormat = getXAxisDateFormat();
  const weekendPlotBands = getWeekendRanges(ts || []);

  const seriesData = groups.map((group) => {
    return {
      name: group.groupName || '',
      data: group.usage?.map((u) => convertQuantity(u, resource)) || [],
    } as SeriesWaterfallOptions;
  });

  const options: Options = {
    ...baseConfig,
    title: {
      text: undefined,
    },
    plotOptions: {
      ...baseConfig.plotOptions,
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          enabled: seriesData[0]?.data?.length === 1,
          lineWidth: 1,
          lineColor: '#666666',
        },
        zIndex: 1,
        zoneAxis: 'x',
      },
      series: {
        ...baseConfig.plotOptions?.series,
        marker: {
          enabled: false,
        },
        events: {
          legendItemClick(this: any) {
            if (!this.visible) return true;

            const seriesIndex = this.index;
            const series = this.chart.series;

            for (let i = 0; i < series.length; i++) {
              if (series[i].index != seriesIndex) {
                series[i].visible ? series[i].hide() : series[i].show();
              }
            }
            return false;
          },
        },
      } as Highcharts.PlotSeriesOptions,
    },
    chart: {
      ...baseConfig.chart,
      type: 'area',
    },
    series: seriesData,
    tooltip: {
      ...baseConfig.tooltip,
      outside: false,
      useHTML: true,
      backgroundColor: '#fff',
      stickOnContact: true,
      followPointer: false,
      style: {
        pointerEvents: 'auto',
      },
      shared: true,
      formatter(this: TooltipFormatterContextObject) {
        return (
          '<div style="overflow-y: auto; min-height: auto; max-height: 400px; width: 300px">' +
          '<span>' +
          `${formatTimestampForTooltip(
            this.x as string,
            tooltipDateFormat,
            timezone
          )}` +
          '</span>' +
          this.points?.reduce(function (s, point) {
            return (
              s +
              '<br/>' +
              '<span style="' +
              `background-color: ${point.color};` +
              'border-radius: 2px;' +
              'display: inline-block;' +
              'height: 10px;' +
              'width: 4px;' +
              'margin-right: 4px;' +
              '"></span><span style="' +
              'font-size: 12px;' +
              '">' +
              point.series.name +
              ': ' +
              formatNumber(point.y || 0) +
              ' ' +
              unit(resource)
            );
          }, '') +
          '</div>'
        );
      },
    },
    time: {
      moment,
      timezone,
    },
    xAxis: {
      ...baseConfig.xAxis,
      maxPadding: 0,
      minPadding: 0,
      minorTickInterval: xAxisMinorTickInterval,
      minorTicks: true,
      plotBands: [...weekendPlotBands],
      tickInterval: xAxisMajorTickInterval,
      categories: ts,
      labels: {
        formatter(this: AxisLabelsFormatterContextObject) {
          return formatTimestamp(this.value as string, xAxisDateFormat);
        },
      },
    },
    yAxis: {
      ...baseConfig.yAxis,
      labels: {
        formatter(this: AxisLabelsFormatterContextObject) {
          return formatNumber(Number(this.value));
        },
      },
      title: {
        text: unit(resource),
      },
    },
    exporting: {
      ...baseConfig.exporting,
      buttons: {
        contextButton: {
          symbol: 'menuball',
          symbolStrokeWidth: 1,
          symbolFill: '#000',
          menuItems: ['downloadPNG'],
        },
      },
    },
  };

  return (
    <HighchartsReact
      id={title}
      highcharts={Highcharts}
      options={options as unknown as Options}
      ref={chartRef}
      {...rest}
    />
  );
};

export default ConsumptionLineChart;
