import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actions as modalActions } from '../../ducks/modal';
import Button from '../Button';
import ListSelector, { SelectStyles } from '../ListSelector';
import Message from '../Message';
import ModalPopup, { ModalPopupTitle } from '../ModalPopup';
import { useFetchAllBatches, useUpdateBatch } from '../../queries/allProjects';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../../ducks/customers';
import {
  TSOpportunityBase,
  TSOpportunityEntityType,
} from '../../queries/allProjects/types';
import { PopupFormButtonWrapperStyled } from '../OpportunityDetailModal/styles';

const InlineTextWrapper = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
`;

const ListSelectorStyled = styled(ListSelector)`
  ${SelectStyles} {
    margin: 0;
  }
`;
const LockedBatchErrorMessageWrapper = styled.div`
  margin: 0;
`;

interface AddToBatchProps {
  opportunitySeed: TSOpportunityBase;
  opportunitySeedId: string;
  setShowAddToBatchModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddToBatch = ({
  opportunitySeed,
  opportunitySeedId,
  setShowAddToBatchModal,
}: AddToBatchProps) => {
  const dispatch = useDispatch();
  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );
  const { data: batches } = useFetchAllBatches(currentCustomerId);
  const { mutate: updateBatch } = useUpdateBatch();

  const [searchableValue, setSearchableValue]: [string, (string) => void] =
    useState('');
  const [lockedBatchError, setLockedBatchError] = useState('');

  const batchesForListSelector = batches?.map((batch) => {
    return { name: `${batch.displayId} - ${batch.title}`, id: batch.id };
  });

  const selectedSearchableItem = useMemo(
    () => batchesForListSelector?.find((item) => item.id === searchableValue),
    [batchesForListSelector, searchableValue]
  );

  const handleFilterSearchableValueUpdate = useCallback(
    ({ value }: { value: string }) => {
      setLockedBatchError('');
      setSearchableValue(value);
    },
    []
  );
  const handleAddToBatch = () => {
    if (selectedSearchableItem?.id) {
      const selectedBatch = batches?.find(
        (batch) => batch.id == selectedSearchableItem.id
      );
      if (selectedBatch?.locked) {
        setLockedBatchError(
          'This project is locked from editing. Please contact your Redaptive representative if you have questions.'
        );
        return;
      }

      const editOpportunityPayload = {
        batchId: selectedSearchableItem.id,
        opportunityIds:
          selectedBatch?.opportunityIds.concat(opportunitySeedId) ?? [],
        addedOpportunityId: opportunitySeedId,
      };
      updateBatch(editOpportunityPayload);
      setShowAddToBatchModal(false);
      dispatch(
        modalActions.showOpportunityDetailModal({
          opportunitySeedId: selectedSearchableItem.id,
          opportunityType: TSOpportunityEntityType.BATCH,
        })
      );
    }
  };
  return (
    <ModalPopup width='45%'>
      <ModalPopupTitle>Add to Batch</ModalPopupTitle>
      <InlineTextWrapper>
        {`Choose a batch for ${opportunitySeed.ecmType} - ${opportunitySeed.displayId}`}
      </InlineTextWrapper>
      <ListSelectorStyled
        key='add-to-existing-batches-searchable-selection'
        items={batchesForListSelector}
        updateValue={handleFilterSearchableValueUpdate}
        selectedItem={selectedSearchableItem}
        notSetLabelText='Search'
        notSetItemText='-- None --'
      />
      {lockedBatchError && (
        <LockedBatchErrorMessageWrapper>
          <Message type='error'>{lockedBatchError}</Message>
        </LockedBatchErrorMessageWrapper>
      )}
      <PopupFormButtonWrapperStyled>
        <Button
          onClick={() => {
            setShowAddToBatchModal(false);
          }}
          borderRadius='small'
        >
          {'Cancel'}
        </Button>
        <Button
          onClick={handleAddToBatch}
          borderRadius='small'
          buttonType='secondary'
          disabled={!selectedSearchableItem?.id}
        >
          {'Add to Batch'}
        </Button>
      </PopupFormButtonWrapperStyled>
    </ModalPopup>
  );
};

export default AddToBatch;
