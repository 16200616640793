import {
  UilAngleDoubleDown,
  UilAngleDoubleUp,
  UilCheckCircle,
} from '@iconscout/react-unicons';
import { Flex, Group, Stack, Text } from '@mantine/core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { queryStringify } from '../../../../api/utils';
import FileThumbnailPreview from '../../../../components/FileThumbnailPreview';
import OpportunityIcon from '../../../../components/OpportunityIcon';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../../../../ducks/customers';
import { useFetchCustomerTasks } from '../../../../queries/allProjectsPostNtp';
import { TSOpportunitySeed } from '../../../../queries/allProjectsPostNtp/types';
import { getCombinedOpportunityAddress } from '../../../../queries/allProjectsPostNtp/utils';
import { truncateString } from '../../../../utils';
import { getTaskcountColor } from './OpportunityListTable';

interface ChildProjectTypes {
  childProjects: TSOpportunitySeed[];
}

const ChildProjects = ({ childProjects }: ChildProjectTypes) => {
  const navigate = useNavigate();

  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );
  const { data: tasks } = useFetchCustomerTasks(currentCustomerId);

  const [childProjectsToShow, setChildProjectsToShow] = useState(
    childProjects.length > 2 ? childProjects.slice(0, 2) : childProjects
  );

  const getChildTasks = (child) =>
    tasks?.filter((task) => task.projectOpportunityId == child.id);

  return (
    <Stack my={'xl'} gap={'lg'}>
      <Group justify='space-between' h={30}>
        <Text c={'black'} fw={'700'}>
          {`${
            childProjects?.length ? childProjects.length : 'No'
          } Child Projects`}
        </Text>
      </Group>
      {childProjects?.length != 0 && (
        <Flex direction={'column'} gap={'md'}>
          {childProjectsToShow?.map((childSeed) => (
            <FileThumbnailPreview
              onClick={() => {
                navigate(
                  `${location?.pathname}?${queryStringify({
                    opportunityId: childSeed.id,
                    opportunityType: 'seed',
                    customerId: childSeed.customerId,
                  })}`
                );
              }}
              title={
                <Group>
                  <Text
                    fw={'600'}
                    lh={'normal'}
                    lineClamp={2}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {truncateString(
                      `${childSeed.ecmType} - ${childSeed.displayId}`,
                      100
                    ).toUpperCase()}
                  </Text>
                  {getChildTasks(childSeed).length > 0 && (
                    <>
                      {`|`}
                      <Flex
                        pos={'relative'}
                        align={'center'}
                        justify={'center'}
                        style={{ cursor: 'pointer' }}
                      >
                        <UilCheckCircle size={'22px'} />
                        <Flex
                          px={'sm'}
                          bg={getTaskcountColor(getChildTasks(childSeed))}
                          style={{
                            borderRadius: '999px',
                            fontSize: '8px',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                          }}
                          align={'center'}
                          justify={'center'}
                          c='white'
                          fw={'700'}
                          h={'12px'}
                          w={'12px'}
                          p={0}
                        >
                          {getChildTasks(childSeed).length}
                        </Flex>
                      </Flex>
                    </>
                  )}
                </Group>
              }
              key={childSeed.id}
              fileIcon={
                <OpportunityIcon ecmType={childSeed.ecmType} iconSize='32' />
              }
              metaText={[
                getCombinedOpportunityAddress(childSeed).toUpperCase(),
              ]}
            />
          ))}
        </Flex>
      )}
      {childProjects.length > 2 && (
        <Group
          gap={'0px'}
          align='center'
          onClick={() =>
            setChildProjectsToShow(
              childProjectsToShow.length > 2
                ? childProjects.slice(0, 2)
                : childProjects
            )
          }
          style={{ cursor: 'pointer' }}
          w={'max-content'}
        >
          <Text c={'blue.6'}>
            {childProjectsToShow.length > 2
              ? `Show fewer projects`
              : `Show all ${childProjects.length} projects`}
          </Text>
          {childProjectsToShow.length > 2 ? (
            <UilAngleDoubleUp color='#43558E' />
          ) : (
            <UilAngleDoubleDown color='#43558E' />
          )}
        </Group>
      )}
    </Stack>
  );
};

export default ChildProjects;
