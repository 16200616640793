import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import { keyBy, orderBy } from 'lodash';
import { useSelector } from 'react-redux';

import { defaultHeaders, denaliApiBaseUrl, gcApiBaseUrl } from '../api';
import { handleAxiosError } from '../api/utils';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../ducks/customers';
import { TSSiteResponse, TSSitesResponse } from '../ducks/sites';
import sitesMockData from '../mockData/sites';
import { filterDemoSites } from '../utils/demo';
import { updateById } from '../utils/functional';
import { isVariantActive } from '../utils/variants';

interface TSSiteReqBody {
  squareFootage: number | null;
  electricUtilityRate: number | null;
}

const fetchCustomerSites = async (customerId: string) => {
  if (isVariantActive('mock')) {
    return Promise.resolve(sitesMockData);
  }
  const url = `${denaliApiBaseUrl()}/customers/${customerId}/sites?includeFields=siteShifts,meterCounts`;
  return axios
    .get<TSSitesResponse>(url, { headers: defaultHeaders() })
    .then(({ data }) => filterDemoSites(data) as TSSitesResponse)
    .catch(handleAxiosError);
};

const patchSite = async (siteId: string, data: Partial<TSSiteReqBody>) => {
  const url = `${gcApiBaseUrl()}/gc/sites/${siteId}`;
  return axios
    .patch<TSSiteResponse>(url, data, { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(handleAxiosError);
};

const customerSitesKey = (customerId: string) => ['sites', customerId];

const sharedOptions = (customerId: string) =>
  queryOptions({
    queryKey: customerSitesKey(customerId),
    queryFn: () => fetchCustomerSites(customerId),
    staleTime: 1000 * 60 * 60, // Cache for 1 hr
    enabled: !!customerId,
  });

export const useSites = () => {
  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );

  return useQuery({
    ...sharedOptions(currentCustomerId),
    select: (data) => orderBy(data, (site) => site.validName.toLowerCase()),
  });
};

export const useSiteOptions = () => {
  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );

  return useQuery({
    ...sharedOptions(currentCustomerId),
    select: (data) =>
      orderBy(data, (site) => site.validName.toLowerCase()).map((site) => ({
        value: site.id,
        label: site.validName,
      })),
  });
};

export type SitesById = Record<string, TSSiteResponse>;

export const useSitesById = () => {
  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );

  return useQuery({
    ...sharedOptions(currentCustomerId),
    select: (data) => keyBy(data, 'id'),
  });
};

export const useSite = (siteId: string) => {
  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );

  return useQuery({
    ...sharedOptions(currentCustomerId),
    enabled: !!currentCustomerId && !!siteId,
    select: (sites) => sites.find((site) => site.id === siteId),
  });
};

export const useUpdateSite = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      siteId,
      data,
    }: {
      siteId: string;
      data: Partial<TSSiteReqBody>;
    }) => patchSite(siteId, data),
    onSuccess: (newSite) => {
      queryClient.setQueryData(
        customerSitesKey(newSite.customerId ?? ''),
        (previous: TSSiteResponse[]) =>
          updateById<TSSiteResponse>(previous, newSite.id, {
            electricUtilityRate: newSite.electricUtilityRate,
            squareFootage: newSite.squareFootage,
          } as TSSiteResponse)
      );
    },
  });
};
