import styled, { css } from 'styled-components';

const SwitchButtonItemStyles = css`
  background-color: ${({ theme, color }) => color ? color : theme.colors.switchButtonColor};
  color: #fff;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme, color }) => color ? color : theme.colors.switchButtonColor};
  }
`;

export const SwitchButtonItem = styled.div<{
  disabled: boolean;
  selected: boolean;
}>`
  color: ${({ disabled }) => disabled && '#a6a6a6'};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  float: left;
  height: 100%;
  margin: auto;
  padding: 10px;
  line-height: 1.5;

  &:hover {
    background-color: ${({ theme, disabled, color }) =>
    color ? theme.colors.focused : !disabled && theme.colors.secondaryFocused};
    color: ${({ disabled }) => !disabled && '#fff'};
    border-color: transparent;
  }

  &:active {
    background-color: ${({ disabled }) => !disabled && '#fff'};
    color: ${({ disabled }) => !disabled && '#464a54'};
    border-color: ${({ disabled }) => !disabled && '#d7d7d7'};
  }

  ${({ selected }) => selected && SwitchButtonItemStyles};

  &:first-child {
    border-right: none;
    margin-right: -5px;
  }

  &:last-child {
    border-left: none;
    border-top-left-radius: ${({ selected }) => !selected && '1px'};
    border-bottom-left-radius: ${({ selected }) => !selected && '1px'};
    transition: all 0.3s ease 0s;
  }

  &:nth-child(2) {
    transition: all 0.3s ease 0s;
    margin-left: 5px;
  }
`;

export const SwitchButtonStyles = styled.div`
  font-size: 11px;
  display: inline-block;
  color: ${({ theme }) => theme.colors.fontMain};
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  text-align: center;
`;

export interface Option {
  label: React.ReactNode | string;
  value: string;
  disabled?: boolean;
  gainsightTagId?: string;
}

interface TSProps {
  current?: string;
  options: Array<Option>;
  update: (value: string) => any;
  color?: string;
}

const SwitchButton = ({ current, options, update, color, ...props }: TSProps) => {
  return (
    <SwitchButtonStyles {...props}>
      {options.map(({ value, label, disabled = false, gainsightTagId = '' }: Option) => (
        <SwitchButtonItem
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              update(value);
            }
          }}
          selected={!disabled && current === value}
          key={value}
          title={value}
          data-gainsight-id={gainsightTagId}
          color={color}
        >
          {label}
        </SwitchButtonItem>
      ))}
    </SwitchButtonStyles>
  );
};

export default SwitchButton;
