import {
  UilAngleDoubleDown,
  UilAngleDoubleUp,
  UilPlusCircle,
  UilTimes,
} from '@iconscout/react-unicons';
import {
  Badge,
  Flex,
  Group,
  Button as MantineButton,
  Stack,
  Text,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';
import Message from '../../../../components/Message';
import Spinner from '../../../../components/Spinner';
import {
  TSCustomersEntityState,
  selectCustomersEntity,
} from '../../../../ducks/customers';
import {
  useFetchUserEmails,
  useUpdateBatchFollowers,
  useUpdateProjectFollowers,
} from '../../../../queries/allProjectsPostNtp';
import {
  TSEditBatchFollowersRequestPayload,
  TSEditOpportunityFollowersRequestPayload,
} from '../../../../queries/allProjectsPostNtp/types';
import { isValidEmail } from '../../../../utils';

const ErrorMessageWrapper = styled.div`
  margin: 10px 24px;
`;

const Followers = ({
  isBatch,
  opportunitySeedId,
  opportunityAsBatch,
  opportunityAsSeed,
}: any) => {
  const { currentCustomerId }: TSCustomersEntityState = useSelector(
    selectCustomersEntity
  );

  const {
    data: userEmails,
    isFetching: userEmailsLoading,
    isError: userEmailsError,
  } = useFetchUserEmails(currentCustomerId);

  const {
    mutate: updateProjectFollowers,
    isPending: updateProjectFollowersLoading,
    isError: updateProjectFollowersError,
  } = useUpdateProjectFollowers();
  const {
    mutate: updateBatchFollowers,
    isPending: updateBatchFollowersLoading,
    isError: updateBatchFollowersError,
  } = useUpdateBatchFollowers();

  const [invalidFollowerFormatError, setInvalidFollowerFormatError] =
    useState('');
  const [selectedFollowers, setSelectedFollowers] = useState<
    readonly { value: string; label: string }[]
  >([]);

  const [showAddFollowersSelector, setShowAddFollowersSelector] =
    useState(false);

  const dynamicFollowers = isBatch
    ? opportunityAsBatch.followers
    : opportunityAsSeed.followers;

  const handleAddFollowers = () => {
    setInvalidFollowerFormatError('');
    const hasInvalidFollower = selectedFollowers.some(
      (follower) => !isValidEmail(follower.label)
    );
    if (hasInvalidFollower) {
      setInvalidFollowerFormatError(
        'Each follower must be a valid email address'
      );
      return;
    }
    const newFollowers = selectedFollowers?.map(
      (selectedFollower) => selectedFollower.label
    );

    if (isBatch) {
      const updateBatchFollowersRequestPayload: TSEditBatchFollowersRequestPayload =
        {
          batchId: opportunitySeedId,
          followers: Array.from(
            new Set([...dynamicFollowers, ...newFollowers])
          ),
        };

      updateBatchFollowers(updateBatchFollowersRequestPayload);
    } else {
      const updateProjectFollowersRequestPayload: TSEditOpportunityFollowersRequestPayload =
        {
          opportunityId: opportunitySeedId,
          followers: Array.from(
            new Set([...dynamicFollowers, ...newFollowers])
          ),
        };

      updateProjectFollowers(updateProjectFollowersRequestPayload);
    }
    setSelectedFollowers([]);
    setShowAddFollowersSelector(false);
  };

  const handeFollowerClose = (followerToRemove) => {
    const newFollowers = dynamicFollowers?.filter(
      (follower) => follower != followerToRemove
    );
    if (isBatch) {
      const updateBatchFollowersRequestPayload: TSEditBatchFollowersRequestPayload =
        {
          batchId: opportunitySeedId,
          followers: newFollowers,
        };

      updateBatchFollowers(updateBatchFollowersRequestPayload);
    } else {
      const updateProjectFollowersRequestPayload: TSEditOpportunityFollowersRequestPayload =
        {
          opportunityId: opportunitySeedId,
          followers: newFollowers,
        };

      updateProjectFollowers(updateProjectFollowersRequestPayload);
    }
  };

  const isFollowersLoading = () => {
    if (isBatch) {
      if (updateBatchFollowersLoading) return true;
      else return false;
    } else {
      if (updateProjectFollowersLoading) return true;
      else return false;
    }
  };

  const formattedUserEmails = userEmails?.map((user) => ({
    label: user.email,
    value: user.email,
  }));
  const [followersToShow, setFollowersToShow] = useState(dynamicFollowers);
  useEffect(() => {
    setFollowersToShow(dynamicFollowers);
  }, [dynamicFollowers]);
  return (
    <Stack my={'xl'} gap={'lg'}>
      {/* Error section Starts */}
      {invalidFollowerFormatError && (
        <ErrorMessageWrapper>
          <Message type='error'>{invalidFollowerFormatError}</Message>
        </ErrorMessageWrapper>
      )}
      {updateProjectFollowersError && (
        <ErrorMessageWrapper>
          <Message type='error'>{'updateProjectFollowersError'}</Message>
        </ErrorMessageWrapper>
      )}
      {updateBatchFollowersError && (
        <ErrorMessageWrapper>
          <Message type='error'>{'editBatchFollowersMetaError'}</Message>
        </ErrorMessageWrapper>
      )}
      {userEmailsError && (
        <ErrorMessageWrapper>
          <Message type='error'>{userEmailsError}</Message>
        </ErrorMessageWrapper>
      )}
      {/* Error section Ends */}

      <Group justify='space-between' h={30}>
        <Text c={'black'} fw={'700'}>
          Followers
        </Text>
        {!showAddFollowersSelector && (
          <MantineButton
            leftSection={<UilPlusCircle size={16} />}
            variant='default'
            radius={'md'}
            size='compact-md'
            px={'12px'}
            onClick={() => setShowAddFollowersSelector(true)}
          >
            Add follower
          </MantineButton>
        )}
      </Group>
      {showAddFollowersSelector && (
        <Flex
          align={'center'}
          gap={'xs'}
          bg={'white'}
          style={{ borderRadius: '8px', border: '1px solid #e0e0e0' }}
          p={'xs'}
        >
          <CreatableSelect
            isMulti
            options={formattedUserEmails?.filter(
              (email) => !dynamicFollowers.includes(email.label)
            )}
            closeMenuOnSelect={false}
            placeholder={
              !userEmailsLoading
                ? 'Type to search or create a new follower'
                : 'User emails are still loading...'
            }
            onChange={(values) => {
              setInvalidFollowerFormatError('');
              setSelectedFollowers(values);
            }}
            value={selectedFollowers}
            styles={{
              container: (baseStyles) => ({
                ...baseStyles,
                flexBasis: '100%',
                cursor: 'pointer',
              }),
              control: (baseStyles) => ({
                ...baseStyles,
                border: 0,
                boxShadow: 'none',
                minHeight: '36px',
              }),
            }}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
          <Flex align={'center'} gap={'sm'}>
            <MantineButton
              onClick={() => {
                setShowAddFollowersSelector(false);
                setSelectedFollowers([]);
              }}
              variant='transparent'
              radius={'md'}
              size={'compact-md'}
              c={'black'}
              fw={'400'}
            >
              {' '}
              Cancel
            </MantineButton>
            <MantineButton
              onClick={handleAddFollowers}
              disabled={selectedFollowers?.length <= 0}
              variant='filled'
              radius={'md'}
              size={'md'}
            >
              Add Followers
            </MantineButton>
          </Flex>
        </Flex>
      )}

      {!isFollowersLoading() && dynamicFollowers?.length != 0 && (
        <>
          <Flex wrap={'wrap'} rowGap={'sm'} columnGap={'lg'}>
            {followersToShow?.map((follower, index) => (
              <Badge
                key={index}
                color={`#fff`}
                p={'8px 12px'}
                fw={'500'}
                radius={'8px'}
                h={'32px'}
                rightSection={
                  <Flex
                    align={'center'}
                    bg={'rgb(255,255,255,0.8)'}
                    style={{
                      borderRadius: '100%',
                      cursor: 'pointer',
                    }}
                    onClick={() => handeFollowerClose(follower)}
                    ml={'14px'}
                  >
                    <UilTimes size={16} color={'#6C6D6E'} />
                  </Flex>
                }
              >
                <Text tt={'none'} size={'md'} fw={'500'} c={'black'}>
                  {follower}
                </Text>
              </Badge>
            ))}
          </Flex>
          {dynamicFollowers?.length > 3 && (
            <Group
              gap={'0px'}
              align='center'
              onClick={() =>
                setFollowersToShow(
                  followersToShow?.length > 3
                    ? dynamicFollowers.slice(0, 3)
                    : dynamicFollowers
                )
              }
              style={{ cursor: 'pointer' }}
              w={'max-content'}
            >
              <Text c={'blue.6'}>
                {followersToShow?.length > 3
                  ? `Show fewer followers`
                  : `Show all ${dynamicFollowers?.length} followers`}
              </Text>
              {followersToShow?.length > 3 ? (
                <UilAngleDoubleUp color='#43558E' />
              ) : (
                <UilAngleDoubleDown color='#43558E' />
              )}
            </Group>
          )}
        </>
      )}

      {!isFollowersLoading() && !dynamicFollowers?.length && (
        <Text c={'gray.6'}>No followers added</Text>
      )}
      {isFollowersLoading() && <Spinner centered />}
    </Stack>
  );
};

export default Followers;
