import { combineReducers } from 'redux';
import auth, {
  initialState as authInitialState,
  types as authTypes,
} from '../ducks/auth';
import { TSCircuitDataState } from '../ducks/circuit/circuitData';
import { TSCircuitsMetaEntityState } from '../ducks/circuit/circuitMeta';
import { TSCustomerCircuitCategoriesEntityState } from '../ducks/customerCircuitCategories/customerCategories';
import { TSCustomersEntityState } from '../ducks/customers';
import { TSModalState } from '../ducks/modal';
import { TSSavingsAggregationsEntityState } from '../ducks/projects/aggregations';
import { TSInvoicesEntityState } from '../ducks/projects/invoice';
import { TSSavingsPerformanceEntityState } from '../ducks/projects/performance';
import { TSSavingsEntityState } from '../ducks/projects/savings';
import { TSSingleSiteAnalysisState } from '../ducks/singleSiteAnalysis/types';
import { TSSiteShiftsEntityState } from '../ducks/siteShifts';
import { TSSitesEntityState } from '../ducks/sites';
import { TSElectricUsageEntityState } from '../ducks/usageByCustomer/electric';
import { TSGasUsageEntityState } from '../ducks/usageByCustomer/naturalGas';
import { TSWaterUsageEntityState } from '../ducks/usageByCustomer/water';
import { TSElectricMetaEntityState } from '../ducks/usageByCustomerMetaData/electricityMetaData';
import { TSNaturalGasMetaEntityState } from '../ducks/usageByCustomerMetaData/naturalGasMetaData';
import { TSWaterMetaEntityState } from '../ducks/usageByCustomerMetaData/waterMetaData';
import redaptiveTheme from '../themes/redaptiveOne';
import entities, { initialState as entitiesInitialState } from './entities';

export interface TSEntitiesState {
  customers: TSCustomersEntityState;
  sites: TSSitesEntityState;
  siteShifts: TSSiteShiftsEntityState;
  circuitData: TSCircuitDataState;
  circuitMetaData: TSCircuitsMetaEntityState;
  electricityMetaData: TSElectricMetaEntityState;
  waterMetaData: TSWaterMetaEntityState;
  naturalGasMetaData: TSNaturalGasMetaEntityState;
  modal: TSModalState;
  electricUsage: TSElectricUsageEntityState;
  waterUsage: TSWaterUsageEntityState;
  naturalGasUsage: TSGasUsageEntityState;
  savingsPerformance: TSSavingsPerformanceEntityState;
  savings: TSSavingsEntityState;
  invoices: TSInvoicesEntityState;
  savingsAggregations: TSSavingsAggregationsEntityState;
  customerCategories: TSCustomerCircuitCategoriesEntityState;
  singleSiteAnalysis: TSSingleSiteAnalysisState;
}

export interface TSState {
  state: any;
  auth: any;
  entities: TSEntitiesState;
}

export const initialEmptyState = {
  auth: authInitialState,
  entities: entitiesInitialState,
  theme: redaptiveTheme,
};

const rootReducer = () => (state, action: { type: string }) => {
  if (action.type === authTypes.INITIALIZE_STATE) {
    state = undefined;
  }

  return combineReducers({
    auth,
    entities,
  })(state, action);
};

export default rootReducer;
