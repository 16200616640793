import {
  createTheme,
  CSSVariablesResolver,
  defaultVariantColorsResolver,
  MantineProvider,
  VariantColorsResolver,
} from '@mantine/core';

import { PropsWithChildren } from 'react';
const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);

  if (input.variant === 'primary-outline') {
    return {
      background: 'var(--mantine-color-white)',
      hover: 'var(--mantine-color-blue-5)',
      color: 'var(--mantine-color-blue-5)',
      border:
        'calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-blue-5)',
    };
  }
  if (input.variant === 'secondary') {
    return {
      background: 'var(--mantine-color-green-5)',
      hover: 'var(--mantine-color-green-6)',
      color: 'var(--mantine-color-white)',
      border:
        'calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-green-5)',
    };
  }
  if (input.variant === 'danger') {
    return {
      background: 'var(--mantine-color-red-5)',
      hover: 'var(--mantine-color-red-6)',
      color: 'var(--mantine-color-white)',
      border:
        'calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-red-5)',
    };
  }

  return defaultResolvedColors;
};

const mantineTheme = createTheme({
  black: '#090f1e',
  colors: {
    blue: [
      '#edeff6', // 0
      '#c6cde2', // 1
      '#abb4d3', // 2
      '#8492bf', // 3
      '#6d7db3', // 4
      '#485da0', // 5
      '#425592', // 6
      '#334272', // 7
      '#283358', // 8
      '#1e2743', // 9
    ],
    deepBlue: [
      '#e8e9ed', // 0
      '#b7bbc6', // 1
      '#949aaa', // 2
      '#636c84', // 3
      '#45506c', // 4
      '#162447', // 5
      '#142141', // 6
      '#101a32', // 7
      '#0c1427', // 8
      '#090f1e', // 9
    ],
    green: [
      '#f5f8f2', // 0
      '#e1ebd5', // 1
      '#d2e1c1', // 2
      '#bdd3a5', // 3
      '#b1ca94', // 4
      '#9dbd79', // 5
      '#8fac6e', // 6
      '#6f8656', // 7
      '#566843', // 8
      '#424f33', // 9
    ],
    grey: [
      '#f0f0f1', // 0
      '#e0e0e0', // 1
      '#bbbcbd', // 2
      '#9d9d9f', // 3
      '#898a8c', // 4
      '#6c6d6f', // 5
      '#626365', // 6
      '#4d4d4f', // 7
      '#3b3c3d', // 8
      '#2d2e2f', // 9
    ],
    red: [
      '#f9eaea', // 0
      '#ebbfbf', // 1
      '#e29f9f', // 2
      '#d57474', // 3
      '#cd5959', // 4
      '#c02f2f', // 5
      '#af2b2b', // 6
      '#882121', // 7
      '#6a1a1a', // 8
      '#511414', // 9
    ],
    yellow: [
      '#fef6e8', // 0
      '#fbe4b9', // 1
      '#f9d797', // 2
      '#f6c567', // 3
      '#f5ba49', // 4
      '#f2a91c', // 5
      '#dc9a19', // 6
      '#ac7814', // 7
      '#855d0f', // 8
      '#66470c', // 9
    ],
  },
  primaryShade: 5,
  primaryColor: 'blue',
  radius: {
    sm: '0.25rem',
    md: '0.5rem',
    lg: '0.75rem',
  },
  defaultRadius: 'sm',
  fontSizes: {
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.25rem',
  },
  fontFamily: "'Public Sans', sans-serif",
  headings: {
    sizes: {
      h1: {
        fontSize: '1.25rem',
        fontWeight: '600',
        lineHeight: '1.55',
      },
      h2: {
        fontSize: '1rem',
        fontWeight: '600',
        lineHeight: '1.5',
      },
      h3: {
        fontSize: '0.875rem',
        fontWeight: '600',
        lineHeight: '1.2',
      },
      h4: {
        fontSize: '0.76471rem',
        fontWeight: '500',
        lineHeight: '1.54',
      },
    },
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '0.75rem',
    lg: '1rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.75rem',
  },
  other: {
    zIndex: {
      drawer: 1000,
      dropdown: 9999,
    },
    fontWeights: {
      normal: 400,
      bold: 600,
    },
  },
  variantColorResolver,
});

export const variableResolver: CSSVariablesResolver = (theme) => ({
  variables: {
    '--mantine-color-error': theme.colors.red[5],
  },
  light: {
    '--mantine-color-error': theme.colors.red[5],
  },
  dark: {
    '--mantine-color-error': theme.colors.red[5],
  },
});

export const CustomMantineProvider = ({ children }: PropsWithChildren) => (
  <MantineProvider theme={mantineTheme} cssVariablesResolver={variableResolver}>
    {children}
  </MantineProvider>
);

export default mantineTheme;
