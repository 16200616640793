import styled from 'styled-components';
import Grid from '../../../components/Grid';
import Input from '../../../components/Input';
import { HeaderWrapperStyled } from '../../../components/PageHeader';
import {
  GlobalFilterWrapper,
  ReactTableTopSectionStyles,
  ReactTableWrapperStyles,
  RedaptiveReactTableStyles,
  TableHeadStyled,
  ThStyled,
} from '../../../components/RedaptiveReactTable7';
import {
  SwitchButtonItem,
  SwitchButtonStyles,
} from '../../../components/SwitchButton';

export const HeaderActionsStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  Button {
    margin-left: 16px;
  }

  ${SwitchButtonStyles} {
    display: flex;
  }
  ${SwitchButtonItem} {
    height: 40px;
    width: 40px;
  }
`;

export const MessageWrapperStyled = styled.div`
  max-width: 100%;
  width: 540px;
  margin: 20px auto;
`;

export const FloatingButtonsWrapper = styled.div`
  position: fixed;
  bottom: 8%;
  right: 5%;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ButtonsWrapper = styled.div`
  column-gap: 16px;
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const TableSectionWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-top: 20px;
`;

export const FilterSectionWrapper = styled.div`
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 10px;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.fontMain};
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px 8px 0 0;
  flex-wrap: wrap;
  gap: 10px;
`;

export const TotalResultCountWrapper = styled.div`
  line-height: 40px;
  margin-left: 10px;
`;

export const SearchFilterInput = styled(Input)`
  flex-basis: 30%;
  > input {
    width: 100%;
  }
`;

export const NoResultsWrapper = styled.div`
  padding: 12px 20px;
`;
export const PageWrapperStyled = styled.div<{ seedsContainerHeight: string }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${Grid} {
    margin-bottom: 16px;
  }

  ${HeaderWrapperStyled} {
    border-bottom: 0;
  }

  ${RedaptiveReactTableStyles} {
    background-color: transparent;
    white-space: nowrap;
    border: none;
    border-radius: 0;

    a {
      color: #485da0;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      letter-spacing: 0.02em;
      line-height: 20px;
    }
  }
  ${TableHeadStyled} {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #f8f8f8;
  }
  ${ThStyled} {
    text-align: left;
  }

  ${ReactTableTopSectionStyles} {
    justify-content: flex-start;
    margin: 1px 0px;
    position: absolute;
    right: 0;
    top: 0px;
    width: 6%;
    min-width: 75px;
    padding: 15px 0;
    > div {
      > svg {
        display: none;
      }

      > input {
        color: #6c6d6e;
        font-size: 14px;
        font-weight: 400;
        padding: 12px 160px 12px 16px;
      }
    }
  }

  ${RedaptiveReactTableStyles} {
    height: 100%;
  }

  ${ReactTableWrapperStyles} {
    height: ${({ seedsContainerHeight }) => seedsContainerHeight};
    overflow: auto;
  }

  ${GlobalFilterWrapper} {
    margin-left: 20px;
  }
`;

export const EmptySectionToBeOverlapped = styled.div`
  width: 6%;
  min-width: 75px;
`;
