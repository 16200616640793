/* eslint-disable react/prop-types */
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Checkbox, Flex, Group, ScrollArea, Stack } from '@mantine/core';
import { useMemo } from 'react';
import styled from 'styled-components';
import { gapMap } from '../../../../components/Stack';
import Title from '../../../../components/Title';
import { useFilterStore } from '../../../../globalState/allProjectsPostNtp';
import {
  TSCustomerSettings,
  TSOpportunityBase,
  TSOpportunityEntityType,
  TSProjectColumn,
  TSTransitionOpportunityPayload,
  projectColumnMap,
  projectStageMap,
} from '../../../../queries/allProjectsPostNtp/types';
import { getFirstStageForColumn } from '../../../../queries/allProjectsPostNtp/utils';
import OpportunityCardKanban from './OpportunityCardKanban';

type TSProps = {
  opportunities: Array<TSOpportunityBase>;
  labels: any;
  handleChangeSelectedOpportunities: (...any) => any;
  handleTransitionOpportunity?: (
    entityType: TSOpportunityEntityType,
    request: TSTransitionOpportunityPayload
  ) => any;
  handleEditCustomerSettings?: (payload: TSCustomerSettings) => any;
  handleViewDetails: (string) => void;
  currencyFormat: Intl.NumberFormat;
  customerSettings: TSCustomerSettings;
  isReadOnly?: boolean;
};

const TitleWrapper = styled(Title)`
  flex-grow: 1;
`;

const KanbanTable = ({
  opportunities,
  customerSettings,
  handleChangeSelectedOpportunities,
  handleTransitionOpportunity,
  handleEditCustomerSettings,
  handleViewDetails,
  isReadOnly = false,
}: TSProps) => {
  const { selectedSeeds } = useFilterStore();
  const localSelectedSeeds = { ...selectedSeeds };

  const toggleOpportunitySelected = (id, currentValue) => {
    return stageSelectedCardsChange([id], !currentValue);
  };

  const stageSelectedCardsChange = (ids, selected) => {
    if (selected === true) {
      // If the card was selected, add it to the local list
      ids.forEach((id) => {
        localSelectedSeeds[id] = true;
      });
    } else {
      // If the card was deselected, remove it from the local list
      ids.forEach((id) => {
        if (Object.keys(localSelectedSeeds).length) {
          delete localSelectedSeeds[id];
        }
      });
    }

    // After updating the local list, call function to update selected seeds
    handleChangeSelectedOpportunities(localSelectedSeeds);
  };

  type ColumnInfo = {
    column: TSProjectColumn;
    opportunities: TSOpportunityBase[];
  };

  const onSelectColumn = (e, columnInfo: ColumnInfo) => {
    // select the seeds for this column
    if (columnInfo.opportunities.length) {
      stageSelectedCardsChange(
        columnInfo.opportunities?.map((opp) => opp.id),
        e.currentTarget.checked
      );
    }
  };

  const oppsByColumn: ColumnInfo[] = useMemo(() => {
    if (
      !opportunities ||
      !customerSettings?.projectSequence?.length ||
      !handleEditCustomerSettings
    ) {
      return [];
    }
    return [
      TSProjectColumn.CONSIDERING,
      TSProjectColumn.EVALUATING,
      TSProjectColumn.SCOPING,
      TSProjectColumn.INSTALLING,
      TSProjectColumn.PERFORMING,
      TSProjectColumn.COMPLETED,
      TSProjectColumn.ON_HOLD,
    ]?.map((column) => {
      const arrOpps = opportunities?.filter(
        (seed) => projectStageMap[seed.projectStage]?.column == column
      );
      const sortedIds = [...customerSettings.projectSequence];

      //any opps that aren't in the sort order, add them at the end:
      arrOpps.forEach((opp) => {
        if (sortedIds.indexOf(opp.id) < 0) {
          sortedIds.push(opp.id);
        }
      });

      // now put them in the right order:
      arrOpps.sort(function (a, b) {
        return sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id);
      });

      return {
        column: column,
        opportunities: arrOpps,
      };
    });
  }, [opportunities, customerSettings, handleEditCustomerSettings]);

  const getColumnInfo = (column: TSProjectColumn): ColumnInfo => {
    return oppsByColumn?.filter((columnInfo) => columnInfo.column == column)[0];
  };

  const isColumnChecked = (columnInfo: ColumnInfo) => {
    return (
      columnInfo.opportunities.length > 0 &&
      columnInfo.opportunities?.filter((opp) => !selectedSeeds[opp.id])
        .length == 0
    );
  };

  const isColumnIndeterminate = (columnInfo: ColumnInfo) => {
    return (
      columnInfo.opportunities.length > 0 &&
      !isColumnChecked(columnInfo) &&
      columnInfo.opportunities?.filter((opp) => selectedSeeds[opp.id]).length >
        0
    );
  };

  const onDragEnd = (state) => {
    if (isReadOnly) return;
    const {
      draggableId,
      source: { droppableId: srcDroppableId, index: srcIndex },
      destination: { droppableId: destDroppableId, index: destIndex },
    } = state;
    const idParts = draggableId.split('_');
    const opp = opportunities.find(
      (seed) => seed.id == idParts[2] && seed.entityType == idParts[1]
    );
    if (!opp) {
      return;
    }
    const oldProjectColumn = srcDroppableId.split('_')[1]; // format is ex "droppable_CONSIDERING"
    const newProjectColumn = destDroppableId.split('_')[1];
    const oldProjectStage = opp.projectStage;
    let newProjectStage = oldProjectStage;
    let rankAfterId = 'FIRST'; // if no id found, put it first
    // if it's in a new column, we'll need to update the projectStage:
    if (oldProjectColumn != newProjectColumn) {
      newProjectStage = getFirstStageForColumn(newProjectColumn);
      opp.projectStage = newProjectStage;
    } else if (srcIndex == destIndex) {
      return; // same column + same place = no change
    }
    if (destIndex > 0) {
      // new index depends on if it's still in the same column:
      let ixRankAfterId = destIndex - 1;
      if (oldProjectColumn == newProjectColumn && srcIndex <= destIndex) {
        // if moving down the column, add one to allow for the new space this one will leave:
        ixRankAfterId++;
      }
      // grab the id of the one it's now after
      rankAfterId =
        getColumnInfo(newProjectColumn).opportunities[ixRankAfterId].id;
    }

    //update the sort order:
    let newProjectSequence = customerSettings.projectSequence?.filter(
      (o) => o != opp.id
    );
    // find the new place to put it:
    if (rankAfterId && rankAfterId != 'FIRST') {
      newProjectSequence.splice(
        newProjectSequence.indexOf(rankAfterId) + 1,
        0,
        opp.id
      );
    } else {
      newProjectSequence = [opp.id].concat(newProjectSequence);
    }

    // send the updated info to the back end
    if (handleTransitionOpportunity) {
      handleTransitionOpportunity(opp.entityType, {
        newProjectSequence: newProjectSequence,
        opportunityId: opp.id,
        projectStage: newProjectStage,
        rankAfterOpportunityId: rankAfterId,
        oldProjectStage,
      });
    }
  };

  return (
    <ScrollArea>
      <Flex
        bg='#f6f6f6'
        gap='xs'
        justify='flex-start'
        align='flex-start'
        direction='row'
        wrap='nowrap'
      >
        {oppsByColumn?.map((columnInfo) => {
          return (
            <Stack
              key={`OpportunityColumnHeader_${columnInfo.column}`}
              p={`${gapMap['md']}px`}
              w='300px'
              gap='xs'
            >
              <Group>
                {!isReadOnly && (
                  <Checkbox
                    radius='sm'
                    disabled={columnInfo.opportunities.length == 0}
                    checked={isColumnChecked(columnInfo)}
                    indeterminate={isColumnIndeterminate(columnInfo)}
                    onChange={(e) => onSelectColumn(e, columnInfo)}
                  />
                )}
                <TitleWrapper>
                  {projectColumnMap[columnInfo.column].label} (
                  {columnInfo.opportunities.length})
                </TitleWrapper>
              </Group>
            </Stack>
          );
        })}
      </Flex>
      <DragDropContext onDragEnd={onDragEnd}>
        <Flex
          mih={200}
          bg='#eaeaea'
          gap='xs'
          justify='flex-start'
          align='stretch'
          direction='row'
          wrap='nowrap'
        >
          {// Display a column for each status rollup
          oppsByColumn?.map((columnInfo) => {
            return (
              <Droppable
                droppableId={`droppable_${columnInfo.column}`}
                type='OPPORTUNITY_CARD'
                key={`OpportunityColumn_${columnInfo.column}`}
                isDropDisabled={isReadOnly}
              >
                {(provided, snapshot) => (
                  <Stack
                    ref={provided.innerRef}
                    style={{
                      width: '320px',
                      padding: '10px',
                      alignItems: 'stretch',
                      backgroundColor: snapshot.isDraggingOver
                        ? '#cad4ea'
                        : '#eaeaea',
                    }}
                    {...provided.droppableProps}
                    p={`${gapMap['md']}px`}
                    w='300px'
                    gap='xs'
                  >
                    {columnInfo.opportunities?.map((seed, index) => {
                      return (
                        <Draggable
                          draggableId={`draggable_${seed.entityType}_${seed.id}`}
                          index={index}
                          key={`draggable_${seed.entityType}_${seed.id}`}
                          isDragDisabled={isReadOnly}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <OpportunityCardKanban
                                seed={seed}
                                handleSelect={toggleOpportunitySelected}
                                handleViewDetails={handleViewDetails}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    <div
                      style={{
                        flex: 1,
                      }}
                    ></div>
                    {provided.placeholder}
                  </Stack>
                )}
              </Droppable>
            );
          })}
        </Flex>
      </DragDropContext>
    </ScrollArea>
  );
};

export default KanbanTable;
