import { useState } from 'react';
import Message from '../Message';
import Button from '../Button';
import Popup, { PopupFooter } from '../Popup';
import Input from '../../components/Input';
import debounce from 'debounce';
import { isValidEmail } from '../../utils';
import CreatableSelect from 'react-select/creatable';
import { Select, Stack } from '@mantine/core';
import styled from 'styled-components';
import { TSProjectStage } from '../../queries/allProjects/types';
import { getProjectStageValuesForSelect } from '../../queries/allProjects/utils';

export interface TSCreateBatchPopupProps {
  preselectedFollowers: string[];
  formattedUserEmails: Array<{
    label: string;
    value: string;
  }>;
  userEmailsLoading: boolean;
  handleCreateBatch: (
    title: string,
    description: string,
    projectStage: TSProjectStage,
    selectedFollowers: string[]
  ) => void;
  isBatchNameTaken: (batchName: string) => boolean;
  handleClosePopup: () => void;
}

const TextAreaStyled = styled.textarea`
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid #c7c7c7;
  border-radius: 3px;
  padding: 8px 11px;
  font-size: 14px;
  line-height: 19px;
  color: #162447;
  &:focus,
  &:hover {
    background-color: white;
    outline: none;
    border: 1px solid #c7c7c7;
  }

  &:focus {
    border: 1px solid #dadfec;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  input {
    width: 100%;
  }
`;

const FormInputLabelStyled = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  color: #767676;
`;

const CreateBatchPopup = ({
  preselectedFollowers,
  formattedUserEmails,
  userEmailsLoading,
  isBatchNameTaken,
  handleCreateBatch,
  handleClosePopup,
}: TSCreateBatchPopupProps) => {
  const [batchTitle, setBatchTitle] = useState('');
  const [projectStage, setProjectStage] = useState(TSProjectStage.NEW);
  const [inputEnterBatchTitleError, setInputEnterBatchTitleError] =
    useState('');
  const [batchDescription, setBatchDescription] = useState('');
  const [selectedFollowers, setSelectedFollowers] = useState<
    readonly { value: string; label: string }[]
  >([]);
  const [invalidFollowerFormatError, setInvalidFollowerFormatError] =
    useState('');

  const handleChangeBatchTitle = (event) => {
    setBatchTitle(event.target.value || '');
    setInputEnterBatchTitleError('');
  };

  const handleChangeBatchDescription = (event) => {
    setBatchDescription(event.target.value || '');
  };

  const handleChangeProjectStage = (newStage) => {
    setProjectStage(newStage);
  };

  const handleOnCreate = () => {
    // do any validation here, if valid call the create method passed from the parent
    if (selectedFollowers.length > 0) {
      if (selectedFollowers.some((follower) => !isValidEmail(follower.label))) {
        setInvalidFollowerFormatError(
          'Each follower must be a valid email address'
        );
        return;
      }
    }
    if (batchTitle == '') {
      setInputEnterBatchTitleError('Please enter batch name');
      return;
    }

    if (isBatchNameTaken(batchTitle)) {
      setInputEnterBatchTitleError('A batch with the same name already exists');
      return;
    }

    // if we made it to here, ok to save the batch
    handleCreateBatch(
      batchTitle,
      batchDescription,
      projectStage,
      selectedFollowers?.map((selectedFollower) => selectedFollower.label)
    );
  };

  const projectStageValues = getProjectStageValuesForSelect();

  return (
    <Popup
      width={'800px'}
      title='Create Batch'
      handleOnClose={handleClosePopup}
    >
      <Stack gap='lg'>
        <FormGroup>
          <FormInputLabelStyled>
            Enter a name for this batch
          </FormInputLabelStyled>
          <Input
            error={inputEnterBatchTitleError}
            onChange={debounce(handleChangeBatchTitle, 200)}
          />
        </FormGroup>
        <FormGroup>
          <FormInputLabelStyled>
            Enter a description for this batch
          </FormInputLabelStyled>
          <TextAreaStyled
            onChange={debounce(handleChangeBatchDescription, 200)}
          />
        </FormGroup>
        <FormGroup>
          <FormInputLabelStyled>Enter the Project Stage</FormInputLabelStyled>
          <Select
            comboboxProps={{ zIndex: 100000 }}
            size='md'
            radius={'sm'}
            allowDeselect={false}
            data={projectStageValues}
            value={projectStage}
            onChange={(selectedOption) => {
              handleChangeProjectStage(selectedOption);
            }}
          />
        </FormGroup>
        <FormGroup>
          {invalidFollowerFormatError && (
            <Message type='error'>{invalidFollowerFormatError}</Message>
          )}
          <FormInputLabelStyled>
            Select followers for this batch
          </FormInputLabelStyled>
          <CreatableSelect
            isMulti
            options={formattedUserEmails}
            closeMenuOnSelect={false}
            placeholder={
              !userEmailsLoading
                ? 'Type to search or create a new follower'
                : 'User emails are still loading...'
            }
            onChange={(values) => {
              setInvalidFollowerFormatError('');
              setSelectedFollowers(values);
            }}
            defaultValue={formattedUserEmails?.filter(
              (email) => preselectedFollowers?.includes(email.label)
            )}
          />
        </FormGroup>
      </Stack>

      <PopupFooter>
        <Button onClick={handleClosePopup} borderRadius='small'>
          {'Cancel'}
        </Button>
        <Button
          onClick={handleOnCreate}
          borderRadius='small'
          buttonType='secondary'
        >
          {'Create Batch'}
        </Button>
      </PopupFooter>
    </Popup>
  );
};

export default CreateBatchPopup;
