/* eslint-disable react/prop-types */
import { UilCheckCircle } from '@iconscout/react-unicons';
import { Badge, Checkbox, Flex, Group, Text } from '@mantine/core';
import moment from 'moment';
import { useMemo, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { useTheme } from 'styled-components';
import CollapsedIcon from '../../../../components/Icons/CollapsedIcon';
import ExpandedIcon from '../../../../components/Icons/ExpandedIcon';
import RedaptiveReactTable7 from '../../../../components/RedaptiveReactTable7';
import { EMPTY_METRIC_STRING } from '../../../../constants/strings';
import { useFilterStore } from '../../../../globalState/allProjectsPostNtp';
import {
  TSOpportunityBase,
  TSOpportunitySeed,
  projectStageMap,
} from '../../../../queries/allProjectsPostNtp/types';
import { getTasksBySelectedFilter } from '../../../../queries/allProjectsPostNtp/utils';
import { capitalizeFirstLetter, truncateString } from '../../../../utils';

type TSProps = {
  tableData: any;
  labels: any;
  handleChangeSelectedOpportunities: (...any) => any;
  handleViewDetails: (string) => void;
  currencyFormat: Intl.NumberFormat;
};

type TSSeedCell = {
  column: {
    Header: string;
  };
  row: {
    depth: number;
    isExpanded: boolean;
    original: TSOpportunitySeed;
  };
};

const today = moment();
const endOfThisWeek = moment().endOf('week');

export const getTaskcountColor = (tasks) => {
  return tasks.some((task) => moment(task.dueDate).isBefore(today))
    ? 'red.4'
    : 'blue.5';
};

const ListTable = ({
  tableData,
  labels,
  handleChangeSelectedOpportunities,
  handleViewDetails,
  currencyFormat,
}: TSProps) => {
  const { filters, selectedSeeds } = useFilterStore();
  const theme = useTheme();
  const tableRef = useRef<any>();

  // Row Clicking Behaviour
  const clickTimeout = useRef<any>(undefined);

  const handleRowClick = (row, event) => {
    event.stopPropagation();
    clearTimeout(clickTimeout.current);
    if (event.target.type == 'checkbox') {
      return; // will be handled by the checkbox
    }
    if (event.detail == 1) {
      // don't want to re-call on the .click() below
      clickTimeout.current = setTimeout(() => {
        if (event.target.closest('tr')?.querySelector('input'))
          event.target.closest('tr')?.querySelector('input').click();
      }, 300);
    }
  };

  const handleDoubleClick = (row: { original: TSOpportunityBase }) => {
    clearTimeout(clickTimeout.current);
    handleViewDetails(row.original);
  };

  const getOverdueIncompleteTasks = (tasks) =>
    tasks?.filter(
      (task) =>
        task.dueDate &&
        task.status !== 'COMPLETED' &&
        moment(task.dueDate).isBefore(today, 'day')
    );

  const getDueTodayIncompleteTasks = (tasks) =>
    tasks?.filter(
      (task) =>
        task.dueDate &&
        task.status !== 'COMPLETED' &&
        moment(task.dueDate).isSame(today, 'day')
    );
  const getDueThisWeekIncompleteTasks = (tasks) =>
    tasks?.filter(
      (task) =>
        task.dueDate &&
        task.status !== 'COMPLETED' &&
        moment(task.dueDate).isBefore(endOfThisWeek, 'day') &&
        moment(task.dueDate).isAfter(today, 'day')
    );

  const columns = useMemo(
    () => [
      {
        accessor: 'expander',
        Cell: ({ row }) => {
          return row.canExpand ? (
            <span
              onClick={(clickEvent) => {
                clickEvent.stopPropagation();
                row.toggleRowExpanded();
              }}
              style={{ cursor: 'pointer' }}
              title='Toggle Row Expanded'
            >
              {row.isExpanded ? (
                <ExpandedIcon color={theme.colors.primary} />
              ) : (
                <CollapsedIcon color={theme.colors.primary} />
              )}
            </span>
          ) : null;
        },
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              <ExpandedIcon color={theme.colors.primary} />
            ) : (
              <CollapsedIcon color={theme.colors.primary} />
            )}
          </span>
        ),
        minWidth: 20,
        maxWidth: 20,
        disableSortBy: true,
      },
      {
        accessor: 'select',
        Cell: ({ row }) => {
          const { id, tasks } = row.original;
          return (
            <Group wrap='nowrap'>
              <Checkbox radius='sm' {...row.getToggleRowSelectedProps()} />
              {filters.selectedTaskFilter.isActive && (
                <Flex
                  pos={'relative'}
                  align={'center'}
                  justify={'center'}
                  style={{ cursor: 'pointer' }}
                  data-tooltip-id={id}
                >
                  <UilCheckCircle />
                  <Flex
                    px={'sm'}
                    bg={getTaskcountColor(tasks)}
                    style={{
                      borderRadius: '999px',
                      fontSize: '12px',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                    }}
                    align={'center'}
                    justify={'center'}
                    c='white'
                    fw={'700'}
                    h={'16px'}
                    w={'16px'}
                  >
                    {
                      getTasksBySelectedFilter(
                        tasks,
                        filters.selectedTaskFilter.value
                      ).length
                    }
                  </Flex>
                  <Tooltip id={id} style={{ zIndex: 999999 }}>
                    {getOverdueIncompleteTasks(tasks).length > 0 ? (
                      <Text>
                        {`${
                          getOverdueIncompleteTasks(tasks).length
                        } task/s overdue`}
                      </Text>
                    ) : null}
                    {getDueTodayIncompleteTasks(tasks).length > 0 ? (
                      <Text>
                        {`${
                          getDueTodayIncompleteTasks(tasks).length
                        } task/s due today`}
                      </Text>
                    ) : null}
                    {getDueThisWeekIncompleteTasks(tasks).length > 0 ? (
                      <Text>
                        {`${
                          getDueThisWeekIncompleteTasks(tasks).length
                        } task/s due this
                      week`}
                      </Text>
                    ) : null}
                  </Tooltip>
                </Flex>
              )}
            </Group>
          );
        },
        Header: ({ getToggleAllRowsSelectedProps }) => {
          return <Checkbox radius='sm' {...getToggleAllRowsSelectedProps()} />;
        },
        minWidth: 20,
        disableSortBy: true,
      },
      {
        accessor: 'displayId',
        Cell: ({
          row: {
            original: { displayId },
          },
        }) => {
          return displayId ?? EMPTY_METRIC_STRING;
        },
        Header: 'ID',
        minWidth: 50,
      },
      {
        accessor: 'title',
        Cell: ({
          row: {
            original: { title },
          },
        }) => {
          return truncateString(title, 50) ?? EMPTY_METRIC_STRING;
        },
        Header: 'Title',
        minWidth: 50,
      },
      {
        accessor: 'displayAddress',
        Cell: ({
          row: {
            original: { displayAddress },
          },
        }) => {
          return `${
            displayAddress != ''
              ? truncateString(displayAddress, 50)
              : EMPTY_METRIC_STRING
          }`;
        },
        Header: 'Site Address',
        minWidth: 50,
      },
      {
        accessor: 'ecmType',
        Cell: ({
          row: {
            original: { ecmType },
          },
        }: TSSeedCell) =>
          ecmType ? capitalizeFirstLetter(ecmType) : EMPTY_METRIC_STRING,
        Header: 'ECM Type',
        minWidth: 50,
      },
      {
        accessor: 'projectStage',
        Cell: ({
          row: {
            original: { projectStage },
          },
        }: TSSeedCell) =>
          projectStage
            ? projectStageMap[projectStage].label
            : EMPTY_METRIC_STRING,
        Header: 'Project Stage',
        minWidth: 50,
      },
      {
        accessor: 'labels',
        Cell: ({
          row: {
            original: { labelIds },
          },
        }: TSSeedCell) => {
          const rowLabels = labels?.filter((label) =>
            labelIds.includes(label.id)
          );
          return rowLabels.length ? (
            <Flex gap={'5px'} w={'max-content'}>
              {rowLabels?.map((label) => (
                <Badge
                  key={label.id}
                  color={`#${label.color}`}
                  p={'8px 12px'}
                  fw={'500'}
                  radius={'8px'}
                  h={'32px'}
                >
                  <Text tt={'none'} size={'md'} fw={'500'} c={'black'}>
                    {label.text}
                  </Text>
                </Badge>
              ))}
            </Flex>
          ) : (
            'No labels'
          );
        },
        Header: 'Labels',
      },
    ],
    [
      theme.colors.primary,
      filters.selectedTaskFilter.isActive,
      filters.selectedTaskFilter.value,
      currencyFormat,
      labels,
    ]
  );

  return (
    <RedaptiveReactTable7
      gainsightTagBaseId='all-projects-post-ntp-table'
      showTableScrollArrows
      tableInstanceRef={tableRef}
      selectedRows={selectedSeeds as any}
      onSelectedRowsChange={handleChangeSelectedOpportunities}
      columns={columns}
      data={tableData}
      globalFilterable={false}
      getRowId={(row) => row.id}
      onRowClick={handleRowClick}
      onRowDoubleClick={handleDoubleClick}
      autoResetExpanded={false}
      autoResetSortBy={false}
      getCellProps={(cell: TSSeedCell) => {
        const {
          column: { Header },
          row: {
            depth,
            isExpanded,
            original: { entityType },
          },
        } = cell;

        const cellBackground = () => {
          if (entityType === 'batch') {
            return isExpanded ? '#c4cbe1' : '#fff';
          }
          return depth > 0 ? '#f0f2f7' : '#fff';
        };

        return {
          style: {
            color:
              Header === 'ID' ? theme.colors.primary : theme.colors.fontMain,
            background: cellBackground(),
            fontWeight:
              entityType == 'batch' &&
              (Header === 'ID' ||
                Header === 'Title' ||
                Header === 'Site Address')
                ? 600
                : 300,
          },
        };
      }}
    />
  );
};

export default ListTable;
