interface TSProps {
  color?: string;
  size?: string;
}

const CardIcon = ({ color = '#162447', size = '20' }: TSProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      d='M8.6 10.7H3.7C3.51435 10.7 3.3363 10.7737 3.20503 10.905C3.07375 11.0363 3 11.2143 3 11.4V16.3C3 16.4857 3.07375 16.6637 3.20503 16.795C3.3363 16.9263 3.51435 17 3.7 17H8.6C8.78565 17 8.9637 16.9263 9.09497 16.795C9.22625 16.6637 9.3 16.4857 9.3 16.3V11.4C9.3 11.2143 9.22625 11.0363 9.09497 10.905C8.9637 10.7737 8.78565 10.7 8.6 10.7ZM7.9 15.6H4.4V12.1H7.9V15.6ZM16.3 3H11.4C11.2143 3 11.0363 3.07375 10.905 3.20503C10.7737 3.3363 10.7 3.51435 10.7 3.7V8.6C10.7 8.78565 10.7737 8.9637 10.905 9.09497C11.0363 9.22625 11.2143 9.3 11.4 9.3H16.3C16.4857 9.3 16.6637 9.22625 16.795 9.09497C16.9263 8.9637 17 8.78565 17 8.6V3.7C17 3.51435 16.9263 3.3363 16.795 3.20503C16.6637 3.07375 16.4857 3 16.3 3ZM15.6 7.9H12.1V4.4H15.6V7.9ZM16.3 10.7H11.4C11.2143 10.7 11.0363 10.7737 10.905 10.905C10.7737 11.0363 10.7 11.2143 10.7 11.4V16.3C10.7 16.4857 10.7737 16.6637 10.905 16.795C11.0363 16.9263 11.2143 17 11.4 17H16.3C16.4857 17 16.6637 16.9263 16.795 16.795C16.9263 16.6637 17 16.4857 17 16.3V11.4C17 11.2143 16.9263 11.0363 16.795 10.905C16.6637 10.7737 16.4857 10.7 16.3 10.7ZM15.6 15.6H12.1V12.1H15.6V15.6ZM8.6 3H3.7C3.51435 3 3.3363 3.07375 3.20503 3.20503C3.07375 3.3363 3 3.51435 3 3.7V8.6C3 8.78565 3.07375 8.9637 3.20503 9.09497C3.3363 9.22625 3.51435 9.3 3.7 9.3H8.6C8.78565 9.3 8.9637 9.22625 9.09497 9.09497C9.22625 8.9637 9.3 8.78565 9.3 8.6V3.7C9.3 3.51435 9.22625 3.3363 9.09497 3.20503C8.9637 3.07375 8.78565 3 8.6 3ZM7.9 7.9H4.4V4.4H7.9V7.9Z'
      fill={color}
    />
  </svg>
);

export default CardIcon;
