import { UilAngleDoubleDown, UilAngleDoubleUp } from '@iconscout/react-unicons';
import {
  Flex,
  Grid,
  Group,
  Pill,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/Button';
import MultiSelect from '../../../../components/mantine/MultiSelect';
import Select from '../../../../components/mantine/Select';
import { useGainsightTracking } from '../../../../gainsight/allProjectsPostNtp';
import { useFilterStore } from '../../../../globalState/allProjectsPostNtp';
import {
  TSLabels,
  TSOpportunitySeed,
} from '../../../../queries/allProjectsPostNtp/types';
import {
  getDefaultFiltersWithOpportunityLimits,
  getLabelOptionsByGroupForMantine,
  getProjectStageValuesForSelect,
} from '../../../../queries/allProjectsPostNtp/utils';

const SelectStyled = styled(Select)`
  .mantine-Select-input::placeholder {
    color: black;
  }
`;

interface FilterSectionTypes {
  opportunitySeeds: TSOpportunitySeed[];
  labels: TSLabels;
  setSeedsContainerHeight: React.Dispatch<React.SetStateAction<string>>;
}

const FiltersSection = ({
  opportunitySeeds,
  labels,
  setSeedsContainerHeight,
}: FilterSectionTypes) => {
  const { filters, setFilters, resetFilters } = useFilterStore();
  const { trackUpdateProjectFilters } = useGainsightTracking();

  // this is the default filter state, used to set the ranges
  const defaultFilterValues = useMemo(
    () => getDefaultFiltersWithOpportunityLimits(opportunitySeeds),
    [opportunitySeeds]
  );
  // keep a local copy of the filters while they're being updated, will send all the changes to the state on "apply"
  const [localFilter, setLocalFilter] = useState(filters);

  useEffect(() => {
    setLocalFilter(filters);
  }, [filters]);

  const getUniqueItems = (array) => {
    const uniqueNames = [...new Set(array?.map((item) => item.value))];
    return uniqueNames?.map((value) =>
      array.find((item) => item.value === value)
    );
  };

  const handleMultiSelectFilterValueUpdate = useCallback(
    (selectedIds: string[], filterFieldName: string) => {
      const newLocalFilter = {
        ...localFilter,
      };
      newLocalFilter[filterFieldName] = {
        values: selectedIds,
        isActive: selectedIds.length > 0,
      };
      setLocalFilter(newLocalFilter);
    },
    [localFilter]
  );

  const handleSingleSelectFilterValueUpdate = useCallback(
    (selectedFilter: string, filterFieldName: string) => {
      const newLocalFilter = {
        ...localFilter,
      };
      newLocalFilter[filterFieldName] = {
        value: selectedFilter,
        isActive: !!selectedFilter,
      };
      setLocalFilter(newLocalFilter);
    },
    [localFilter]
  );

  {
    /* Addresses dropdown Section */
  }
  const addresses = useMemo(() => {
    const valsWithDuplicates = opportunitySeeds?.map((seed) => {
      const { address1, city, state, postalcode, country } = seed;
      const addressParts = [address1, city, state, postalcode, country];
      const addressCombined = addressParts
        ?.filter((part) => part !== undefined && part !== '')
        .join(', ');
      return {
        label: addressCombined,
        value: seed.address1,
      };
    });
    return getUniqueItems(valsWithDuplicates);
  }, [opportunitySeeds]);

  const handleAddressFilterValueUpdate = (newValues: string[]) => {
    handleMultiSelectFilterValueUpdate(newValues, 'selectedAddresses');
  };

  {
    /* End Addresses dropdown Section */
  }

  {
    /* Ecm Types dropdown Section */
  }
  const ecmTypes = useMemo(() => {
    const valsWithDuplicates = opportunitySeeds?.map((seed) => {
      return { label: seed.ecmType, value: seed.ecmType };
    });
    return getUniqueItems(valsWithDuplicates);
  }, [opportunitySeeds]);

  const handleEcmTypeFilterValueUpdate = (newValues: string[]) => {
    handleMultiSelectFilterValueUpdate(newValues, 'selectedEcmTypes');
  };

  {
    /* End Ecm Types dropdown Section */
  }

  {
    /* Project Stage dropdown Section */
  }
  const projectStages = getProjectStageValuesForSelect();

  const handleProjectStageFilterValueUpdate = (newValues: string[]) => {
    handleMultiSelectFilterValueUpdate(newValues, 'selectedProjectStages');
  };

  {
    /* End Project Stage dropdown Section */
  }

  {
    /* Labels dropdown Section */
  }
  const labelsForSelector = useMemo(() => {
    return getLabelOptionsByGroupForMantine(labels);
  }, [labels]);

  const taskFilters = ['Incomplete', 'Overdue', 'Due today', 'Due this week'];

  const handleLabelsFilterValueUpdate = (newValues: string[]) => {
    handleMultiSelectFilterValueUpdate(newValues, 'selectedLabels');
  };

  const handleTasksFilterValueUpdate = (newValue: string) => {
    handleSingleSelectFilterValueUpdate(newValue, 'selectedTaskFilter');
  };

  {
    /* End Labels dropdown Section */
  }

  {
    /* Action Button  Section */
  }

  const handleClearAll = () => {
    setLocalFilter(defaultFilterValues);
    resetFilters(opportunitySeeds);
  };

  const handleApply = () => {
    setFilters({
      ...localFilter,
    });
    trackUpdateProjectFilters({ ...localFilter });
  };
  {
    /* End Action Button  Section */
  }

  const [filtersExpanded, { toggle }] = useDisclosure(false);

  const toggleFilters = () => {
    if (filtersExpanded) {
      setSeedsContainerHeight('calc(100vh - 380px)');
    } else {
      setSeedsContainerHeight('calc(100vh - 450px)');
    }
    toggle();
  };

  const getActiveFiltersCount = () => {
    let count = 0;
    for (const key in filters) {
      if (filters.hasOwnProperty.call(filters, key)) {
        if (filters[key].isActive === true) {
          count++;
        }
      }
    }
    return count;
  };
  const filtersActive = !!getActiveFiltersCount();
  const showMoreFiltersCount = getActiveFiltersCount() - 4 <= 0;

  // Create a function for rendering the ListSelector component
  const renderMultiListSelector = (
    title,
    key,
    items,
    onChange,
    selectedItems
  ) => {
    return (
      <Stack style={{ gap: '4px' }}>
        <Text size={'sm'} lineClamp={1}>
          {title}
        </Text>
        <MultiSelect
          key={key}
          radius={'md'}
          size={'sm'}
          data={items}
          maxDisplayedValues={0}
          setValues={onChange}
          values={selectedItems}
          placeholder='All'
          showSelectAll={true}
        />
      </Stack>
    );
  };
  // Create a function for rendering the ListSelector component
  const renderSingleListSelector = (
    title,
    key,
    items,
    onChange,
    selectedItem
  ) => {
    return (
      <Stack style={{ gap: '4px' }}>
        <Text size={'sm'} lineClamp={1}>
          {title}
        </Text>
        <SelectStyled
          key={key}
          size='sm'
          radius={'md'}
          allowDeselect={true}
          data={items}
          value={selectedItem}
          onChange={onChange}
          placeholder='All'
          styles={{
            option: {
              fontSize: '14px',
            },
          }}
        />
      </Stack>
    );
  };

  const actionButtons = (
    <Flex justify={'flex-end'} align={'center'} h={'100%'} gap={'lg'}>
      {filtersActive && (
        <Button
          onClick={handleClearAll}
          borderRadius='small'
          data-gainsight-id='opportunities-filter-clear'
          buttonType='transparent'
        >
          {'Clear'}
        </Button>
      )}
      <Button
        onClick={handleApply}
        borderRadius='xSmall'
        buttonType='secondaryTransparent'
        outlined={true}
      >
        {'Apply Filters'}
      </Button>
    </Flex>
  );

  const collapsibleLink = (
    <Grid.Col span={5} py={'xs'}>
      <Group
        onClick={toggleFilters}
        align={'center'}
        gap={'xs'}
        style={{ cursor: 'pointer' }}
        w={'max-content'}
      >
        {!filtersExpanded && filtersActive && showMoreFiltersCount && (
          <Pill bg={'blue'} radius={'lg'} c={'white'} size='xs' fw={'500'}>
            {getActiveFiltersCount() - 4}
          </Pill>
        )}
        <Text c='blue' fw={'500'}>{`${
          filtersExpanded ? 'Show fewer filters' : 'Show more filters'
        }`}</Text>
        {filtersExpanded ? (
          <UilAngleDoubleUp color='#5268AE' />
        ) : (
          <UilAngleDoubleDown color='#5268AE' />
        )}
      </Group>
    </Grid.Col>
  );

  // Reusable Filter components
  const addressSelector = renderMultiListSelector(
    'Site Address',
    'site-address-selection',
    addresses,
    handleAddressFilterValueUpdate,
    localFilter.selectedAddresses.values
  );

  const ecmTypeSelector = renderMultiListSelector(
    'ECM Type',
    'ecm-type-selection',
    ecmTypes,
    handleEcmTypeFilterValueUpdate,
    localFilter.selectedEcmTypes.values
  );

  const projectStageSelector = renderMultiListSelector(
    'Project Stage',
    'project-stage-selection',
    projectStages,
    handleProjectStageFilterValueUpdate,
    localFilter.selectedProjectStages.values
  );

  const labelsSelector = renderMultiListSelector(
    'Labels',
    'labels-type-selection',
    labelsForSelector,
    handleLabelsFilterValueUpdate,
    localFilter.selectedLabels.values
  );

  const tasksSelector = renderSingleListSelector(
    'Project Tasks',
    'project-tasks-type-selection',
    taskFilters,
    handleTasksFilterValueUpdate,
    localFilter.selectedTaskFilter.value
  );
  const renderFilters = () => {
    if (filtersExpanded) {
      return (
        <>
          {addressSelector}
          {ecmTypeSelector}
          {projectStageSelector}
          {labelsSelector}
          {tasksSelector}
        </>
      );
    }

    if (!filtersExpanded) {
      if (!filtersActive) {
        return (
          <>
            {addressSelector}
            {ecmTypeSelector}
            {projectStageSelector}
            {labelsSelector}
          </>
        );
      } else {
        return (
          <>
            {Object.keys(filters).reduce((activeFilters, filterName) => {
              const filter = filters[filterName];
              if (filter.isActive && activeFilters.length < 4) {
                activeFilters.push(
                  <Fragment key={filterName}>
                    {filterName === 'selectedAddresses' && addressSelector}
                    {filterName === 'selectedEcmTypes' && ecmTypeSelector}
                    {filterName === 'selectedProjectStages' &&
                      projectStageSelector}
                    {filterName === 'selectedLabels' && labelsSelector}
                    {filterName === 'selectedTaskFilter' && tasksSelector}
                  </Fragment>
                );
              }
              return activeFilters;
            }, [] as React.ReactNode[])}
          </>
        );
      }
    }
  };

  return (
    <Grid columns={5} gutter={'md'} py={'12px'} style={{ overflow: 'inherit' }}>
      <Grid.Col span={4} py={'xs'}>
        <SimpleGrid cols={4}>{renderFilters()}</SimpleGrid>
      </Grid.Col>
      <Grid.Col span={1} py={'xs'} style={{ alignSelf: 'flex-end' }}>
        <SimpleGrid cols={1}>{actionButtons}</SimpleGrid>
      </Grid.Col>
      {collapsibleLink}
    </Grid>
  );
};

export default FiltersSection;
