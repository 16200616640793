export interface TSRejectProjectPayload {
  opportunityId;
  reason: string;
}
export interface TSOpportunityComment {
  id: string;
  comment: string;
  commentAddedBy: string;
  createdBy: string;
  created: string;
  modified: string;
}
export interface TSCreateCustomerTaskPayload {
  title: string;
  description: string;
  assignedToEmail: string;
  dueDate: Date | null | string;
  projectOpportunityId?: string;
  projectBatchId?: string;
  customerId?: string;
  sequenceNumber: number;
  status: string;
}
export type TSUpdateCustomerTaskPayload = Omit<
  TSCreateCustomerTaskPayload,
  'opportunityId' | 'batchId'
> & { id: string };

export interface TSUpdateCustomerTaskStatusPayload {
  taskId: string;
  status: 'IN_PROGRESS' | 'COMPLETED';
  customerId: string;
}
export interface TSReorderCustomerTaskPayload {
  taskId: string;
  sequenceNumber: number;
}

export interface TSDeleteCustomerTaskPayload {
  taskId: string;
}
export interface TSOpportunityTaskResponse {
  id: string;
  title: string;
  description: string;
  assignedToEmail: string | null;
  dueDate: Date | null;
  projectOpportunityId?: string;
  projectBatchId?: string;
  customerId?: string;
  sequenceNumber: number;
  created?: string;
  createdBy?: string;
  modified?: string;
  modifiedBy?: string;
  status?: 'IN_PROGRESS' | 'COMPLETED';
  isNew?: boolean;
}

export interface TSFile {
  name: string;
  path: string;
  size: number;
  type: string;
}
export interface TSOpportunityAttachment {
  recordId: string;
  id: string;
  attachmentType: string;
  attachmentUrl: string;
  fileName: string;
  commentId: string;
  created: string;
  createdBy: string;
  opportunityBatchId?: string;
}

export interface TSCreateCommentPayload {
  opportunityId: string;
  comment: string;
  acceptedFiles?: any;
}

interface TSLabel {
  id: string;
  text: string;
  color: string;
  title: string; // the group title
}
export interface TSMasterListOfValuesRequest {
  customerID: string;
  type?:
    | 'string'
    | 'ECM_TYPE'
    | 'UNIT'
    | 'CUSTOM_FIELD_TITLE'
    | 'SOLAR_ECM_SPECIFIC_FIELDS'
    | 'LIGHTING_ECM_SPECIFIC_FIELDS'
    | 'LIGHTING_CONTROL_ECM_SPECIFIC_FIELDS'
    | 'HVAC_CONTROL_ECM_SPECIFIC_FIELDS'
    | 'BMSAAS_CONTROL_ECM_SPECIFIC_FIELDS'
    | 'GLOBAL_FIELDS'
    | 'BATCH_FIELDS';
  value?: string;
}
export type TSMasterListOfValuesResponse = Array<
  TSMasterListOfValuesRequest & {
    id: string;
    value: string;
    sequenceNumber?: number;
    customerId?: string;
    metadata?: string;
  }
>;

export type TSLabels = Array<TSLabel>;
export type TSUserEmail = {
  email: string;
};

export type TSOpportunityBaseResponse = {
  id: string;
  title: string;
  displayId: string;
  siteId: string;
  siteName: string;
  customerId: string;
  description: string;
  projectStage: TSProjectStage;
  bookmark: boolean;
  hide: boolean;
  hideReason: string;
  ecmType: string;
  annualAvoidedCo2e: number;
  simplePaybackPeriod: number;
  annualPayment: number;
  contractRate: number;
  annualEnergySavedOrGeneratedKwh: number;
  scheduleTermInMonths: number;
  annualNetCostSavings: number;
  totalNetCost: number;
  totalContractValue: number;
  annualAvoidedEnergyCost: number;
  annualAvoidedMaintenanceCost: number;
  annualAvoidedOtherCost: number;
  annualAvoidedTotalCost: number;
  created?: string;
  modified?: string;
  createdBy?: string;
  modifiedBy?: string;
  labelIds: Array<string>;
  followers: Array<string>;
  ecmTypeSpecificFields?: { [key: string]: string };
  sequenceDetails?: Array<{
    id: string;
    sequenceNumber: number;
    isPrimary?: boolean;
  }>;
  customFields?: Array<{
    id: string;
    title: string;
    value: string;
    unit: string;
    isCustomField: boolean;
    isPrimary: boolean;
    helperText?: string;
  }>;
};

export type TSOpportunitySeedResponse = TSOpportunityBaseResponse & {
  approvalStatus?: string;
  siteId: string;
  siteName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalcode: string;
  country: string;
};

export type TSOpportunitySeedsResponse = TSOpportunitySeedResponse[];

export type TSOpportunityBatchResponse = TSOpportunityBaseResponse & {
  opportunityIds: string[];
  locked: boolean;
};

export type TSOpportunityBatchesResponse = TSOpportunityBatchResponse[];

// this is the local/enhanced version
export interface TSOpportunityBase extends TSOpportunityBaseResponse {
  entityType: TSOpportunityEntityType;
  tasks?: Array<TSOpportunityTaskResponse>;
}

export type TSOpportunitySeed = TSOpportunityBase & TSOpportunitySeedResponse;

export type TSOpportunityBatch = TSOpportunityBase &
  TSOpportunityBatchResponse & {
    subRows?: TSOpportunitySeed[];
  };

export interface TSCreateBatchPayload {
  title: string;
  description: string;
  projectStage: TSProjectStage;
  customerId: string;
  opportunityIds: string[];
  followers: string[];
}
export interface TSCreateOpportunityRequestPayload {
  opportunityId?: string;
  title: string;
  description: string;
  annualAvoidedCo2e: string;
  simplePaybackPeriod: string;
  contractRate: string;
  annualEnergySavedOrGeneratedKwh: string;
  scheduleTermInMonths: string;
  annualAvoidedEnergyCost: string;
  annualAvoidedMaintenanceCost: string;
  annualAvoidedOtherCost: string;
  annualAvoidedTotalCost: string;
  annualNetCostSavings: string;
  totalNetCost: string;
  annualPayment: string;
  totalContractValue: string;
  customerId: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalcode: string;
  country: string;
  ecmType: string;
  projectStage: TSProjectStage;
  customFields: Array<{
    id: string;
    title: string;
    value: string;
    unit: string;
  }>;
  sequenceDetails: Array<{
    id: string;
    sequenceNumber: number;
    isPrimary?: boolean;
  }>;
  ecmTypeSpecificFields: { [key: string]: string };
}
export interface TSEditBatchPayload {
  opportunityIds: Array<string>;
  batchId: string;
  addedOpportunityId?: string;
  removedOpportunityId?: string;
}

export interface TSEditBatchMetadataPayload {
  batchId: string;
  title: string;
  description: string;
  annualAvoidedCo2e: string | number;
  simplePaybackPeriod: string | number;
  annualAvoidedEnergyCost: string | number;
  annualAvoidedMaintenanceCost: string | number;
  annualAvoidedOtherCost: string | number;
  annualAvoidedTotalCost: string | number;
  annualNetCostSavings: string | number;
  labelIds?: Array<string>;
  customFields?: Array<{
    id: string;
    title: string;
    value: string;
    unit: string;
  }>;
  sequenceDetails?: Array<{
    id: string;
    sequenceNumber: number;
    isPrimary?: boolean;
  }>;
  removedLabelId?: string;
}

export interface TSEditOpportunityPayload {
  opportunityId: string;
  description: string;
  title: string;
}

export interface TSTransitionOpportunityPayload {
  opportunityId: string;
  projectStage: TSProjectStage;
  rankAfterOpportunityId: string;
  newProjectSequence: string[];
  oldProjectStage: TSProjectStage;
}
export interface TSEditOpportunityLabelsPayload {
  opportunityId: string;
  labelIds: Array<string>;
  removedLabelId: string; // used for gainsight
}

export interface TSAddLabelsRequestPayload {
  customerId: string;
  labels: { id?: string; text: string }[];
  opportunityIds?: Array<string>;
  batchIds?: Array<string>;
}

export interface TSEditOpportunityFollowersRequestPayload {
  opportunityId: string;
  followers: Array<string>;
}
export interface TSEditBatchFollowersRequestPayload {
  batchId: string;
  followers: Array<string>;
}

export interface TSEditCustomerSettingsRequestPayload {
  customerSettings: TSCustomerSettings;
  customerId: string;
}

export interface TSFiltersState {
  selectedAddresses: { values: string[]; isActive: boolean };
  selectedEcmTypes: { values: string[]; isActive: boolean };
  selectedProjectStages: { values: string[]; isActive: boolean };
  selectedLabels: { values: string[]; isActive: boolean };
  costSavings: { values: Array<number>; isActive: boolean };
  co2Savings: { values: Array<number>; isActive: boolean };
  simplePaybackPeriod: { values: Array<number>; isActive: boolean };
  selectedTaskFilter: { value: string; isActive: boolean };
  bookmarkedOnly: boolean;
  selectedOnly: boolean;
  searchKey: string;
}

export interface TSSeedsById {
  [id: string]: TSOpportunitySeed;
}

export interface TSCustomerSettings {
  columns: any; // Will add type as part of TEAL-722
  projectSequence: string[];
}

export interface TSOpportunitySeedsEntityState {
  selectedSeeds: any; // this is a special format used by the table, looks like: {id1: true, id2: true}
  filters: TSFiltersState;
}

export enum TSOpportunityEntityType {
  SEED = 'seed',
  BATCH = 'batch',
}

export enum TSProjectColumn {
  CONSIDERING = 'CONSIDERING',
  EVALUATING = 'EVALUATING',
  SCOPING = 'SCOPING',
  INSTALLING = 'INSTALLING',
  PERFORMING = 'PERFORMING',
  COMPLETED = 'COMPLETED',
  ON_HOLD = 'ONHOLD', // don't add the "_" unless you want to fix the parsing in the d&d
}

export enum TSProjectStage {
  NEW = 'NEW',
  DISCOVERY = 'DISCOVERY',
  DEVELOPING_INDICATIVE_PROPOSAL = 'DEVELOPING_INDICATIVE_PROPOSAL',
  INDICATIVE_PROPOSAL_SHARED = 'INDICATIVE_PROPOSAL_SHARED',
  AWAITING_AUDIT = 'AWAITING_AUDIT',
  DEVELOPING_EXECUTABLE_PROPOSAL = 'DEVELOPING_EXECUTABLE_PROPOSAL',
  EXECUTABLE_PROPOSAL_SHARED = 'EXECUTABLE_PROPOSAL_SHARED',
  CONTRACTED = 'CONTRACTED',
  INSTALLING = 'INSTALLING',
  PERFORMANCE_MONITORING_BILLING = 'PERFORMANCE_MONITORING_BILLING',
  BOUGHT_OUT = 'BOUGHT_OUT',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  ON_HOLD = 'ON_HOLD',
}

export const projectStageMap: Record<
  TSProjectStage,
  { label: string; column: TSProjectColumn }
> = {
  [TSProjectStage.NEW]: { label: 'New', column: TSProjectColumn.CONSIDERING },
  [TSProjectStage.DISCOVERY]: {
    label: 'Discovery',
    column: TSProjectColumn.CONSIDERING,
  },
  [TSProjectStage.DEVELOPING_INDICATIVE_PROPOSAL]: {
    label: 'Developing Indicative Proposal',
    column: TSProjectColumn.EVALUATING,
  },
  [TSProjectStage.INDICATIVE_PROPOSAL_SHARED]: {
    label: 'Indicative Proposal Shared',
    column: TSProjectColumn.EVALUATING,
  },
  [TSProjectStage.AWAITING_AUDIT]: {
    label: 'Awaiting Audit',
    column: TSProjectColumn.SCOPING,
  },
  [TSProjectStage.DEVELOPING_EXECUTABLE_PROPOSAL]: {
    label: 'Developing Executable Proposal',
    column: TSProjectColumn.SCOPING,
  },
  [TSProjectStage.EXECUTABLE_PROPOSAL_SHARED]: {
    label: 'Executable Proposal Shared',
    column: TSProjectColumn.SCOPING,
  },
  [TSProjectStage.CONTRACTED]: {
    label: 'Contracted',
    column: TSProjectColumn.INSTALLING,
  },
  [TSProjectStage.INSTALLING]: {
    label: 'Installing',
    column: TSProjectColumn.INSTALLING,
  },
  [TSProjectStage.PERFORMANCE_MONITORING_BILLING]: {
    label: 'Performance Monitoring & Billing',
    column: TSProjectColumn.PERFORMING,
  },
  [TSProjectStage.BOUGHT_OUT]: {
    label: 'Bought Out',
    column: TSProjectColumn.COMPLETED,
  },
  [TSProjectStage.COMPLETED]: {
    label: 'Completed',
    column: TSProjectColumn.COMPLETED,
  },
  [TSProjectStage.CANCELED]: {
    label: 'Canceled',
    column: TSProjectColumn.COMPLETED,
  },
  [TSProjectStage.ON_HOLD]: {
    label: 'On Hold',
    column: TSProjectColumn.ON_HOLD,
  },
};

// There's probably a better way to store this info that doesn't repeat like this..
export const projectColumnMap: Record<
  TSProjectColumn,
  { label: string; stages: TSProjectStage[] }
> = {
  [TSProjectColumn.CONSIDERING]: {
    label: 'Considering',
    stages: [TSProjectStage.NEW, TSProjectStage.DISCOVERY],
  },
  [TSProjectColumn.EVALUATING]: {
    label: 'Evaluating',
    stages: [
      TSProjectStage.DEVELOPING_INDICATIVE_PROPOSAL,
      TSProjectStage.INDICATIVE_PROPOSAL_SHARED,
    ],
  },
  [TSProjectColumn.SCOPING]: {
    label: 'Scoping',
    stages: [
      TSProjectStage.AWAITING_AUDIT,
      TSProjectStage.DEVELOPING_EXECUTABLE_PROPOSAL,
      TSProjectStage.EXECUTABLE_PROPOSAL_SHARED,
    ],
  },
  [TSProjectColumn.INSTALLING]: {
    label: 'Installing',
    stages: [TSProjectStage.CONTRACTED, TSProjectStage.INSTALLING],
  },
  [TSProjectColumn.PERFORMING]: {
    label: 'Performing',
    stages: [TSProjectStage.PERFORMANCE_MONITORING_BILLING],
  },
  [TSProjectColumn.COMPLETED]: {
    label: 'Completed',
    stages: [
      TSProjectStage.BOUGHT_OUT,
      TSProjectStage.COMPLETED,
      TSProjectStage.CANCELED,
    ],
  },
  [TSProjectColumn.ON_HOLD]: {
    label: 'On Hold',
    stages: [TSProjectStage.ON_HOLD],
  },
};

export type TSCustomOpportunityField = {
  id: string;
  title: string;
  helperText: string;
  unit: string;
};
