import styled from 'styled-components';
import {
  ModalBodyStyled,
  ModalContentStyled,
  ModalTitleWithSubTitleStyled,
} from '../GlobalModal/Components';
import Grid from '../Grid';

export const OpportunityModalBodyStyled = styled(ModalBodyStyled)`
  box-shadow: 1px 0px 5px 1px #999;
  background: linear-gradient(
      0deg,
      rgba(224, 224, 224, 0.32) 0%,
      rgba(224, 224, 224, 0.32) 100%
    ),
    #fff;
  width: 45%;
`;

export const ErrorMessageWrapper = styled.div`
  margin: 10px 24px;
`;

export const FormInputWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  input {
    width: 100%;
  }
`;

export const InlineFormWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 6px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
`;

export const PopupFormButtonWrapperStyled = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  flex-shrink: 0;
  margin-top: 16px;
`;

export const InlineTextWrapper = styled.div`
  margin-bottom: 15px;
  font-size: 14px;
`;

export const FilePreviewWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
`;

export const ModalContentReStyled = styled(ModalContentStyled)`
  padding: 24px 0;
  ${ModalTitleWithSubTitleStyled} {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const GridStyled = styled(Grid)`
  margin: 20px 0;
`;
